import { useUser } from "@/providers/useUser";
import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FreePlanCard from "./FreeplanCard";
import { ProPlanCard } from "./ProPlanCard";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "@/AppRoute";
import ProPlanAccordion from "./ProPlanAccordion";
import { FreePlanAccordion } from "./FreePlanAccordion";

const dataPricing = [
  {
    country: "MX",
    currency: "MXN",
    price: "$599",
  },
  {
    country: "US",
    currency: "USD",
    price: "$39,9",
  },
  {
    country: "CO",
    currency: "COP",
    price: "$99,900",
  },
];

type PricingTableProps = {
  showFreePlanButton?: boolean;
  onFreePlanClick?: () => void;
};

const PricingTable: React.FC<PricingTableProps> = ({
  showFreePlanButton = false,
  onFreePlanClick = () => {},
}) => {
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { isUserRegistered, geolocation } = useUser();
  const { country } = geolocation;
  const price =
    dataPricing.find((item) => item.country === country)?.price || "$39,9";
  const currency =
    dataPricing.find((item) => item.country === country)?.currency || "USD";
  const navigate = useNavigate();
  const getProButton = async () => {
    if (showFreePlanButton) {
      try {
        await onFreePlanClick?.();
        navigate(AppRoute.UpsellAndCross);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error en onFreePlanClick:", error);
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  return (
    <Flex flexDirection={"column"} alignItems={"center"} gap={6}>
      <Flex flexDir={"column"}>
        <Text
          fontFamily={"Poppins"}
          fontWeight={600}
          fontSize={{ base: "18px", lg: "20" }}
          textAlign={showFreePlanButton ? "center" : "left"}
          mt={isMobile && showFreePlanButton ? "24px" : "0px"}
        >
          {showFreePlanButton
            ? "¡Únete hoy al Plan Pro!"
            : "¡Únete hoy a smartBeemo!"}
        </Text>
        {showFreePlanButton && (
          <Text as={"p"} fontSize={isMobile ? "12px" : "14px"}>
            Accesa el mejor contenido y funcionalidades que tenemos para tí
          </Text>
        )}
      </Flex>
      <Flex
        gap={6}
        minW={showFreePlanButton && isMobile ? "100%" : "auto"}
        flexDirection={isMobile ? "column" : "row"}
        alignItems={isMobile ? "center" : "flex-start"}
        maxH={
          showFreePlanButton && isMobile
            ? "80vh"
            : showFreePlanButton
            ? "87.5vh"
            : "unset"
        }
      >
        {showFreePlanButton && isMobile ? (
          <FreePlanAccordion
            showFreePlanButton={showFreePlanButton}
            onFreePlanClick={onFreePlanClick}
            t={t}
          />
        ) : (
          <FreePlanCard
            isMobile={isMobile ?? false}
            showFreePlanButton={showFreePlanButton}
            onFreePlanClick={onFreePlanClick}
          />
        )}
        {showFreePlanButton && isMobile ? (
          <ProPlanAccordion
            price={price}
            currency={currency}
            isUserRegistered={isUserRegistered ?? false}
            isMobile={isMobile}
            showFreePlanButton={showFreePlanButton}
            getProButton={getProButton}
          />
        ) : (
          <ProPlanCard
            isMobile={isMobile ?? false}
            price={price}
            currency={currency}
            isUserRegistered={isUserRegistered ?? false}
            showFreePlanButton={showFreePlanButton}
            onFreePlanClick={onFreePlanClick}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default PricingTable;
