import { Button } from "@/components/Button";
import { useUser } from "@/providers/useUser";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { GetPaginatedLiveSessionsDocument } from "@/pages/Admin/Academy/liveSessions/graphql/getPaginatedSessions.generated";
import { noCacheHeaders } from "@/utils/headers";
import { LiveSession } from "@/schemaTypes";
import { useQuery } from "@apollo/client";

interface PreSettingsDevicesProps {
  setviewRemimder: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RemimberLiveSession = ({
  setviewRemimder,
}: PreSettingsDevicesProps) => {
  const { user } = useUser();
  const { liveSessionId } = useParams();

  const { data } = useQuery(GetPaginatedLiveSessionsDocument, {
    variables: {
      limit: 1,
      offset: 0,
      search: liveSessionId,
    },
    context: {
      headers: noCacheHeaders,
    },
  });

  const session = data?.getPaginatedLiveSessions
    ?.liveSessions?.[0] as LiveSession;

  const isMentorOrAdmin = useMemo((): boolean => {
    if (!user?.id || !session) return false;

    const isMainMentor =
      session.mainMentors?.some((mentor) => mentor?.id === user.id) ?? false;
    const isSecondaryMentor =
      session.secondaryMentors?.some((mentor) => mentor?.id === user.id) ??
      false;

    return isMainMentor || isSecondaryMentor;
  }, [user?.id, session?.mainMentors, session?.secondaryMentors]);

  const navigateLiveSession = () => {
    if (isMentorOrAdmin) {
      setviewRemimder(false);
    }
  };
  return (
    <Flex
      h={{ base: "100vh", lg: "100%" }}
      w={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Flex
        h={{ base: "80%", lg: "70%" }}
        w={{ base: "100%", lg: "564px" }}
        borderRadius={"8px"}
        backgroundColor={"white"}
        justifyContent={{ base: "space-between", lg: "flex-start" }}
        padding={{ base: 5, lg: 10 }}
        alignItems={"center"}
        flexDirection={"column"}
        gap={10}
      >
        <Heading as={"h5"}>{t("Last minute reminder.")}</Heading>
        <Flex
          w={"100%"}
          justifyContent={"center"}
          gap={10}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Box w={"90%"} display={"flex"} flexDirection={"column"} gap={6}>
            <Box>
              <Box display={"flex"} flexDirection={"column"} gap={3}>
                <Flex alignItems={"center"} gap={2}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.13208 7.35922C3.13208 5.75055 4.47149 4.5 6.0566 4.5H17.9434C19.5285 4.5 20.8679 5.75056 20.8679 7.35922V16.0471C21.5399 16.3906 22 17.0897 22 17.8962V19.0283C22 20.1746 21.0708 21.1038 19.9245 21.1038H4.07547C2.92922 21.1038 2 20.1746 2 19.0283V17.8962C2 17.0897 2.46006 16.3906 3.13208 16.0471V7.35922ZM5.01887 15.8208H18.9811V7.35922C18.9811 6.85172 18.5466 6.38679 17.9434 6.38679H6.0566C5.45342 6.38679 5.01887 6.85172 5.01887 7.35922V15.8208ZM4.07547 17.7075C3.97127 17.7075 3.88679 17.792 3.88679 17.8962V19.0283C3.88679 19.1325 3.97127 19.217 4.07547 19.217H19.9245C20.0287 19.217 20.1132 19.1325 20.1132 19.0283V17.8962C20.1132 17.792 20.0287 17.7075 19.9245 17.7075H4.07547Z"
                      fill="#444448"
                    />
                  </svg>
                  <Text fontSize={"14px"} fontWeight={"semibold"}>
                    {t("Free up space")}
                  </Text>
                </Flex>
                <Box w={"100%"}>
                  <Text fontSize={"14px"} color={"secondary.100"}>
                    {t("Close all applications, browser tabs, etc.")}
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box display={"flex"} flexDirection={"column"} gap={3}>
                <Flex alignItems={"center"} gap={2}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5V21.4362C22 22.0237 21.5237 22.5 20.9362 22.5H18.383C17.0904 22.5 16.0426 21.4522 16.0426 20.1596V17.6064C16.0426 16.3138 17.0904 15.266 18.383 15.266H19.8723V12.5C19.8723 8.15223 16.3478 4.62766 12 4.62766C7.65223 4.62766 4.12766 8.15223 4.12766 12.5V15.266H5.61702C6.9096 15.266 7.95745 16.3138 7.95745 17.6064V20.1596C7.95745 21.4522 6.9096 22.5 5.61702 22.5H3.06383C2.47629 22.5 2 22.0237 2 21.4362V12.5ZM4.12766 17.3936V20.3723H5.61702C5.73453 20.3723 5.82979 20.2771 5.82979 20.1596V17.6064C5.82979 17.4889 5.73453 17.3936 5.61702 17.3936H4.12766ZM19.8723 17.3936H18.383C18.2655 17.3936 18.1702 17.4889 18.1702 17.6064V20.1596C18.1702 20.2771 18.2655 20.3723 18.383 20.3723H19.8723V17.3936Z"
                      fill="#444448"
                    />
                  </svg>
                  <Text fontSize={"14px"} fontWeight={"semibold"}>
                    {t("Use headphones")}
                  </Text>
                </Flex>
                <Box w={"100%"}>
                  <Text fontSize={"14px"} color={"secondary.100"}>
                    {t(
                      "Headphones prevent delays between the speakers and the microphone."
                    )}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box w={"90%"} display={"flex"} flexDirection={"column"} gap={6}>
            <Box>
              <Box display={"flex"} flexDirection={"column"} gap={3}>
                <Flex alignItems={"center"} gap={2}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M10.0693 3.31984L3.1393 8.86989C2.3593 9.48989 1.85929 10.7999 2.02929 11.7799L3.35927 19.7399C3.59927 21.1599 4.95928 22.31 6.39928 22.31H17.5993C19.0293 22.31 20.3993 21.1499 20.6393 19.7399L21.9693 11.7799C22.1293 10.7999 21.6293 9.48989 20.8593 8.86989L13.9293 3.32985C12.8593 2.46985 11.1293 2.46984 10.0693 3.31984Z"
                      stroke="#444448"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.88086 14.8802C11.1509 13.6702 12.8508 13.6702 14.1208 14.8802"
                      stroke="#444448"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.24 12.7592C15.7 12.2292 15.1 11.8192 14.48 11.5192C12.89 10.7592 11.11 10.7592 9.51001 11.5192C8.89001 11.8192 8.3 12.2292 7.75 12.7592"
                      stroke="#444448"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.9955 17.5H12.0045"
                      stroke="#444448"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <Text fontSize={"14px"} fontWeight={"semibold"}>
                    {t("Internet connection")}
                  </Text>
                </Flex>
                <Box w={"100%"}>
                  <Text fontSize={"14px"} color={"secondary.100"}>
                    {t("Avoid overloading your home internet signal.")}
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box display={"flex"} flexDirection={"column"} gap={3}>
                <Flex alignItems={"center"} gap={2}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M19.0264 1.35213C19.3858 1.4719 19.6466 1.78442 19.7002 2.15939L20.0184 4.38677L22.2457 4.70497C22.6207 4.75854 22.9332 5.01936 23.053 5.37869C23.1728 5.73803 23.0793 6.1342 22.8114 6.40203L18.993 10.2204C18.7252 10.4882 18.329 10.5818 17.9697 10.462L16.0089 9.80836L12.2028 13.6144C11.8123 14.0049 11.1791 14.0049 10.7886 13.6144C10.3981 13.2239 10.3981 12.5907 10.7886 12.2002L14.5957 8.39309L13.9431 6.43541C13.8234 6.07608 13.9169 5.67991 14.1847 5.41208L18.0031 1.5937C18.2709 1.32587 18.6671 1.23235 19.0264 1.35213ZM16.5309 7.8742L18.0159 8.36917L19.983 6.40203L18.993 6.26061C18.5531 6.19775 18.2074 5.85206 18.1445 5.41208L18.0031 4.42213L16.036 6.38928L16.5309 7.8742ZM11.4957 4.50081C6.74604 4.50081 2.89569 8.35116 2.89569 13.1008C2.89569 17.8505 6.74604 21.7008 11.4957 21.7008C16.2453 21.7008 20.0957 17.8505 20.0957 13.1008C20.0957 12.5485 20.5434 12.1008 21.0957 12.1008C21.648 12.1008 22.0957 12.5485 22.0957 13.1008C22.0957 18.955 17.3499 23.7008 11.4957 23.7008C5.64147 23.7008 0.895691 18.955 0.895691 13.1008C0.895691 7.24659 5.64147 2.50081 11.4957 2.50081C12.048 2.50081 12.4957 2.94852 12.4957 3.50081C12.4957 4.05309 12.048 4.50081 11.4957 4.50081ZM11.4957 9.30081C9.39701 9.30081 7.69569 11.0021 7.69569 13.1008C7.69569 15.1995 9.39701 16.9008 11.4957 16.9008C13.5944 16.9008 15.2957 15.1995 15.2957 13.1008C15.2957 12.5485 15.7434 12.1008 16.2957 12.1008C16.848 12.1008 17.2957 12.5485 17.2957 13.1008C17.2957 16.3041 14.6989 18.9008 11.4957 18.9008C8.29244 18.9008 5.69569 16.3041 5.69569 13.1008C5.69569 9.89756 8.29244 7.30081 11.4957 7.30081C12.048 7.30081 12.4957 7.74852 12.4957 8.30081C12.4957 8.85309 12.048 9.30081 11.4957 9.30081Z"
                      fill="#444448"
                    />
                  </svg>
                  <Text fontSize={"14px"} fontWeight={"semibold"}>
                    {t("Avoid distractions")}
                  </Text>
                </Flex>
                <Box w={"100%"}>
                  <Text fontSize={"14px"} color={"secondary.100"}>
                    {t("Stay focused and avoid live distractions.")}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
        <Button
          w={"60%"}
          h={"50px"}
          onClick={navigateLiveSession}
          variant="primary"
          title={t("Continue")}
        />
      </Flex>
    </Flex>
  );
};
