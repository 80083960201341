import { useEffect, useState } from "react";
import {
  Editable,
  EditableInput,
  EditablePreview,
  FormControl,
  FormHelperText,
  FormLabel,
  Switch,
  Text,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { GetJwtAuthLiveSessionQuery } from "../../graphql/getJwtAuthLiveSession.generated";
import { useMutation } from "@apollo/client";
import { UpdateExternalLinkDocument } from "../../graphql/updateExternalLink.generated";

interface ClassMigrationProps {
  session: GetJwtAuthLiveSessionQuery;
}

export const ClassMigration = ({ session }: ClassMigrationProps) => {
  const toast = useToast();
  const [sessionData, setSessionData] = useState(session);
  const [link, setLink] = useState(
    sessionData.getJwtAuthLiveSession.session.externalLink ?? "https://"
  );
  const [isValid, setIsValid] = useState(true);
  const [isMessageActive, setIsMessageActive] = useState(
    sessionData.getJwtAuthLiveSession.session.isExternalLinkActive
  );
  useEffect(() => {
    if (session) {
      setSessionData(session);
      setIsMessageActive(
        session.getJwtAuthLiveSession.session.isExternalLinkActive
      );
      setLink(session.getJwtAuthLiveSession.session.externalLink as string);
    }
  }, [session]);

  const [updateLiveSession, { loading: isUpdateLoading }] = useMutation(
    UpdateExternalLinkDocument,
    {
      onCompleted: () => {
        toast({
          title: "Sesión en vivo actualizada",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: "Error al actualizar la sesión en vivo",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const validateURL = (url: string) => {
    try {
      const parsedURL = new URL(url);

      return (
        parsedURL.hostname.includes("zoom.us") ||
        parsedURL.hostname.includes("meet.google.com")
      );
    } catch {
      return false;
    }
  };

  const handleURLChange = (value: string) => {
    if (validateURL(value)) {
      setIsValid(true);
      setLink(value);
    } else {
      setIsValid(false);
    }
  };

  const handleToggle = async () => {
    if (!isValid || !validateURL(link)) {
      toast({
        title: "URL inválida",
        description:
          "Por favor, ingresa un enlace válido de Zoom o Google Meet.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const newState = !isMessageActive;
    setIsMessageActive(newState);

    try {
      await updateLiveSession({
        variables: {
          liveSessionId: sessionData.getJwtAuthLiveSession.session.id ?? "",
          isExternalLinkActive: newState,
          externalLink: link,
        },
      });
    } catch (error) {
      setIsMessageActive(!newState);
    }
  };

  return (
    <Flex
      p={4}
      textColor={"white"}
      flexDirection="column"
      gap={3}
      borderRadius="8px"
      width="100%"
    >
      <FormControl isInvalid={!isValid}>
        <FormLabel textColor="white">Enlace de redireccionamiento</FormLabel>
        <Text
          fontSize={"12px"}
          fontStyle={"italic"}
          textColor={"gray.400"}
          pb={1}
        >
          Ingresa un enlace de Zoom o Google Meet
        </Text>
        <Editable
          fontSize={"14px"}
          value={link}
          onChange={(value) => setLink(value)}
          onSubmit={handleURLChange}
          isDisabled={isMessageActive || isUpdateLoading}
        >
          <EditablePreview
            px={2}
            py={1}
            border="1px solid"
            borderColor="gray.500"
            borderRadius="6px"
            w="full"
            minHeight="34px"
            alignItems="center"
          />
          <EditableInput
            px={2}
            py={1}
            border="1px solid"
            height="34px"
            minHeight="34px"
            borderColor="gray.500"
            borderRadius="6px"
            backgroundColor="white"
            color="black"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            disabled={isMessageActive || isUpdateLoading}
            cursor={isMessageActive || isUpdateLoading ? "not-allowed" : "text"}
          />
        </Editable>
        {!isValid && (
          <FormHelperText color="red.400" fontSize={"12px"}>
            Error: Debe ser un enlace válido de Zoom o Google Meet.
          </FormHelperText>
        )}
      </FormControl>

      <Flex alignItems="center" gap={3}>
        <Switch
          isChecked={isMessageActive}
          onChange={handleToggle}
          colorScheme="green"
          size={"sm"}
          isDisabled={isUpdateLoading}
        />
        <Text fontSize={"14px"}>
          {isMessageActive
            ? "Activado (Desactivar para editar)"
            : "Desactivado"}
        </Text>
      </Flex>

      <Text fontSize={"12px"} fontStyle={"italic"} textColor={"gray.400"}>
        Esta herramienta se usa para redireccionar a los usuarios a una sesión
        externa de Zoom o Meet. Solo se usa en caso de emergencia por problemas
        de la transmisión.
      </Text>
    </Flex>
  );
};
