import mainLogo from "/img/logo_smartbeemo.svg";
import beemo from "/img/beemo.svg";
import {
  Accordion,
  Box,
  calc,
  Divider,
  Flex,
  Img,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Ico } from "@/assets/icons";
import IconButton from "@/components/Button/Icon";
import { AppRoute, PublicRoute } from "@/AppRoute";
import AcademyTabs from "./academyTabs";
import NavigationTooltipButton from "./navigationTooltipButton";
import NavigationPopoverButton from "./navigationPopoverButton";
import { useNavigate } from "react-router-dom";
// import ExpertsTabs from "./expertsTabs";
import ProductsTabs from "./productsTabs";
import { HighTicketNavigationWidget } from "./HighTicket";
import { useUser } from "@/providers/useUser";
import { Button } from "@/components/Button";
import { Footer } from "./footer";
//import { UpgradeToPro } from "@/components/Button/UpgradeToPro";

const NewSideMenu = ({ show = false }) => {
  const { user } = useUser();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const navigate = useNavigate();
  const [isMenuExpanded, setIsMenuExpanded] = useState(true);
  const [expandedItems, setExpandedItems] = useState<number[]>([]);

  const menuExpandIcon = isMenuExpanded ? (
    <Ico.ChevronLeft fill="#1E1E23" />
  ) : (
    <Ico.ChevronRight fill="#1E1E23" />
  );

  const handleSetMenuExpandedDelayed = () => {
    if (!isMobile) {
      setTimeout(() => {
        setIsMenuExpanded((val) => !val);
      }, 300);
    }
  };

  const handleAccordionChange = (expandedIndex: number[]) => {
    setExpandedItems(expandedIndex);
  };

  useEffect(() => {
    if (!isMobile) {
      setIsMenuExpanded(true);
      setExpandedItems([]);
    }
  }, [isMobile]);

  return show ? (
    <Flex position={"relative"}>
      <Flex
        flexDirection={"column"}
        justifyContent={"space-between"}
        w={isMenuExpanded ? (isMobile ? "314px" : "248px") : "64px"}
        h={isMobile ? "calc(100vh - 48px)" : "100vh"}
        px={isMobile ? "8px" : "16px"}
        pb={isMobile ? "0" : "24px"}
        pt={"24px"}
        bg={"#fff"}
        borderRight={"1px solid"}
        borderColor={"neutral.100"}
        flexDir={"column"}
        alignItems={isMenuExpanded ? "flex-start" : "center"}
        transition={"all 0.5s ease"}
        position={isMobile ? "fixed" : "sticky"}
        top={isMobile ? "var(--header-height)" : 0}
        zIndex={1000}
        mt={isMobile ? "-1px" : "calc(-1 * var(--header-height))"}
        overflowY={"scroll"}
        overflowX={"visible"}
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none", // IE and Edge
          "scrollbar-width": "none", // Firefox
        }}
      >
        <Flex
          w={"100%"}
          h={isMobile ? "100%" : calc("100% - 220px").toString()}
          flexDirection={"column"}
          gap={6}
        >
          <Box
            cursor={"pointer"}
            onClick={() => navigate(AppRoute.Base)}
            display={isMobile ? "none" : "auto"}
          >
            <Img
              src={beemo}
              alt="beemo"
              minH={"32px"}
              maxH={"32px"}
              mb={"48px"}
              display={isMenuExpanded ? "none" : "block"}
            />
            <Img
              src={mainLogo}
              alt="logo"
              minH={"32px"}
              maxH={"32px"}
              mb={"48px"}
              display={isMenuExpanded ? "block" : "none"}
            />
          </Box>

          <Accordion
            as={Flex}
            w={"100%"}
            gap={"4px"}
            flexDir={"column"}
            allowMultiple={isMobile}
            allowToggle={!isMobile}
            index={isMobile ? [0, 1, 2, 3, 4] : expandedItems}
            onChange={handleAccordionChange}
          >
            <NavigationPopoverButton
              title="Academia"
              baseRoutes={[AppRoute.Learn, AppRoute.Base]}
              isMenuExpanded={isMenuExpanded}
              icon={<Ico.MonitorRecorder />}
              CustomPopoverContent={
                <AcademyTabs showPaddingLeft={false} darkHover />
              }
              AccordionContent={<AcademyTabs />}
              onClick={() => navigate(AppRoute.Learn)}
            />

            {/* <NavigationTooltipButton
              label="Comunidad"
              icon={<Ico.CommunicationText2 />}
              route={AppRoute.Forum}
              isMenuExpanded={isMenuExpanded}
            /> */}

            <NavigationTooltipButton
              label="Mentores"
              icon={<Ico.People />}
              route={AppRoute.Consulting}
              isMenuExpanded={isMenuExpanded}
            />

            {/* <NavigationTooltipButton
          label="Agencias"
          icon={<Ico.People />}
          route={AppRoute.ServicesMarketplace}
          isMenuExpanded={isMenuExpanded}
        /> */}

            <NavigationPopoverButton
              baseRoutes={[AppRoute.Products, AppRoute.ChinaAgent]}
              title="Productos"
              isMenuExpanded={isMenuExpanded}
              icon={<Ico.BoxMagnifyingGlass />}
              CustomPopoverContent={
                <ProductsTabs showPaddingLeft={false} darkHover />
              }
              AccordionContent={<ProductsTabs />}
              onClick={() => navigate(AppRoute.Products)}
            />

            <NavigationTooltipButton
              label={"Beneficios"}
              route={AppRoute.Solutions}
              isMenuExpanded={isMenuExpanded}
              icon={<Ico.Gift />}
            />
          </Accordion>
          {/* DEV NOTE: this elementis not in the design. */}
          {/* {isMobile && !isPro && <UpgradeToPro />} */}
          {!user && isMobile && (
            <Flex w={"max-content"} gap={4} flexDir={"column"} width={"100%"}>
              <Button
                size="small"
                fontSize="14px"
                variant="primary"
                p={"0.4rem 2rem"}
                onClick={() => navigate(PublicRoute.SignUp)}
              >
                Regístrate gratis
              </Button>
              <Button
                fontSize="14px"
                variant="link"
                border={"none"}
                p={"0.4rem 2rem"}
                onClick={() => navigate(PublicRoute.Login)}
              >
                Inicia Sesión
              </Button>
            </Flex>
          )}
          <HighTicketNavigationWidget isMenuExpanded={isMenuExpanded} />
        </Flex>
        <Flex flexDir={"column"} gap={6}>
          <Divider w="100%" color={"neutral.200"} />
          <Footer isMenuExpanded={isMenuExpanded} />
        </Flex>
      </Flex>
      {!isMobile && (
        <IconButton
          as={Flex}
          cursor={"pointer"}
          icon={menuExpandIcon}
          onClick={handleSetMenuExpandedDelayed}
          ariaLabel="Expandir"
          variant="primary"
          bg={"#fff"}
          borderRadius={"50%"}
          border={"3px solid"}
          borderColor={"neutral.50"}
          boxShadow="-1px -1px 10px 0px rgba(100, 100, 100, 0.16)"
          position={"sticky"}
          zIndex={1000}
          top={"var(--header-height)"}
          left={"0"}
          ml={"-20px"}
          _hover={{ bg: "auto" }}
          _active={{
            bg: "neutral.100",
            boxShadow: "-1px -1px 10px 0px rgba(100, 100, 100, 0.16)",
          }}
        />
      )}
    </Flex>
  ) : null;
};

export default NewSideMenu;
