import { BadgeCardFragment } from "@/components/Badges/graphql/badgeCardFragment.generated";
import { Button } from "@/components/Button";
import { EditButton } from "@/components/EditButton";
import useGA4Event, {
  GA4EventAction,
  GA4EventCategory,
  GA4EventLabel,
} from "@/hooks/useGA4Event";
import { useUser } from "@/providers/useUser";
import {
  BootcampBannerEntry,
  Category,
  EntityName,
  UserRole,
} from "@/schemaTypes";
import DateTimeInterface from "@/utils/dateTimeFormat/dateTimeInteface";
import DateTimeMoment from "@/utils/dateTimeFormat/dateTimeMoment";
import { TestIds } from "@/utils/testIds";
import { FlexProps } from "@chakra-ui/layout";
import {
  Badge,
  Box,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "@/AppRoute";

enum BannerNames {
  CTA,
  MYBOOTCAMPS,
  COMPLETED,
}

interface BootcampCardProps {
  bootcampEntry: BootcampBannerEntry;
  isNew?: boolean;
  isJoinnable?: boolean;
  targetBanner?: BannerNames;
  badges?: Array<BadgeCardFragment>;
  isCta?: boolean;
  onClick?: (bootcamp: BootcampBannerEntry) => void;
  onEditClick?: (bootcamp: BootcampBannerEntry, e: React.MouseEvent) => void;
  blockEnrroll?: (bootcamp: BootcampBannerEntry, e: React.MouseEvent) => void;
}

export const BootcampCard: React.FC<
  BootcampCardProps & Omit<FlexProps, "onClick">
> = ({
  bootcampEntry,
  targetBanner,
  isJoinnable,
  isCta,
  onEditClick,
  blockEnrroll,
}) => {
  const dateTime: DateTimeInterface = new DateTimeMoment();
  const [startDateFormatted, setStartDateFormatted] = useState<string>("");
  const [startTime, setstartTime] = useState<string>("");
  const { hasRoles, isEditModeEnabled, geolocation } = useUser();
  const { t } = useTranslation();
  const { sendEvent } = useGA4Event();
  const navigate = useNavigate();

  const breakpoint =
    useBreakpointValue({
      base: "base",
      md: "md",
      lg: "lg",
      xl: "xl",
    }) || "";

  const startDateMoment = moment(bootcampEntry?.release?.startDate);
  const beemoBootcamp =
    "https://storage.googleapis.com/lms-stg-media/beemo-bootcamp.svg";

  const beemoMastermind =
    "https://media.smartbeemo.com/mastermind-card-logo.svg";

  const canEdit =
    isEditModeEnabled && hasRoles([UserRole.Admin, UserRole.Editor]);

  const handleCardClick = () => {
    const bootcampUrl = `${AppRoute.Bootcamps}/${bootcampEntry.parent?.id}/${bootcampEntry.release?.id}`;
    navigate(bootcampUrl);
  };

  const handleClick = (e: React.MouseEvent, completed?: boolean) => {
    const params = {
      bootcampDate: startDateMoment.toISOString(),
      bootcampTipo: bootcampEntry.parent?.bootcampType,
    };

    if (completed) {
      sendEvent(
        {
          action: `${GA4EventAction.BootcampSeePreviousSessions}`,
          label: GA4EventLabel.BootcampSeePreviousSessions,
          category: GA4EventCategory.Bootcamps,
        },
        params
      );
    } else if (!isJoinnable) {
      sendEvent(
        {
          action: `${GA4EventAction.BootcampSeeAgenda}`,
          label: GA4EventLabel.BootcampSeeAgenda,
          category: GA4EventCategory.Bootcamps,
        },
        params
      );
    } else if (isJoinnable) {
      sendEvent(
        {
          action: `${GA4EventAction.BootcampRegistration}`,
          label: GA4EventLabel.BootcampRegistration,
          category: GA4EventCategory.Bootcamps,
        },
        params
      );
    }
    handleCardClick();
  };

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onEditClick) {
      onEditClick(bootcampEntry, e);
    }
  };

  const handleBlockEnrroll = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (blockEnrroll) {
      blockEnrroll(bootcampEntry, e);
    }
  };

  const getstartTime = () => {
    const formattedStartTime = dateTime.fortmatOnlyTime(
      startDateMoment,
      geolocation.location
    );
    setstartTime(formattedStartTime);
  };

  const getStartDateFormatted = () => {
    const localTime = dateTime.convertLocalTime(
      startDateMoment,
      geolocation.location
    );

    const formattedStartDate = dateTime.fortmatDate(localTime, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    setStartDateFormatted(formattedStartDate);
  };

  const checkCategory = (category: Category) => {
    if (
      category.name.toLowerCase() === "sin marca" ||
      category.name.toLowerCase() === "sin subcategoría" ||
      category.name.toLowerCase() === "sin nivel"
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    getstartTime();
    getStartDateFormatted();
  }, [geolocation]);

  const isGeneral =
    (!bootcampEntry?.parent?.brand ||
      bootcampEntry?.parent?.brand[0]?.name.toLowerCase() === "sin marca") &&
    (!bootcampEntry?.parent?.subCategory ||
      bootcampEntry?.parent?.subCategory[0]?.name.toLowerCase() ===
        "sin subcategoría") &&
    (!bootcampEntry?.parent?.level ||
      bootcampEntry?.parent?.level[0]?.name.toLowerCase() === "sin nivel");
  return (
    <Box
      bgColor={"white"}
      minW={"300px"}
      maxW={"300px"}
      boxSizing="border-box"
      borderRadius="8px"
      userSelect={"none"}
      onClick={handleClick}
      cursor={"pointer"}
    >
      <Flex
        flexDir={"column"}
        align={"flex-start"}
        border={"1px solid"}
        borderColor={"neutral.100"}
        borderRadius={"6px"}
        overflow={"hidden"}
        bg={
          bootcampEntry.parent?.bootcampType === EntityName.MastermindBootcamp
            ? "linear-gradient(180deg, #1E1E23 0%, #444448 50%, #69696C 100%);"
            : "white"
        }
        w={"100%"}
        p={"12px"}
        maxH={"189px"}
        minH={"189px"}
        borderLeft={"12px solid"}
        borderLeftColor={
          bootcampEntry.parent?.bootcampType === EntityName.MastermindBootcamp
            ? "secondary.50"
            : isGeneral
            ? "#80B826"
            : bootcampEntry.parent?.brand?.[0]?.color ?? "#80B826"
        }
      >
        <Image
          src={
            bootcampEntry.parent?.bootcampType === EntityName.MastermindBootcamp
              ? beemoMastermind
              : beemoBootcamp
          }
          width={
            bootcampEntry.parent?.bootcampType === EntityName.MastermindBootcamp
              ? "125px"
              : "auto"
          }
          mb={"8px"}
        />

        <Text
          variant={"bold"}
          minH={"77px !important"}
          mb={"12px"}
          color={
            bootcampEntry.parent?.bootcampType === EntityName.MastermindBootcamp
              ? "white"
              : undefined
          }
        >
          {bootcampEntry.release?.title}
        </Text>

        <Text
          textTransform={"lowercase"}
          variant={"caption"}
          color={
            bootcampEntry.parent?.bootcampType === EntityName.MastermindBootcamp
              ? "white"
              : undefined
          }
        >
          {startDateFormatted} | {startTime}
        </Text>

        <Text
          variant={"caption"}
          fontWeight={400}
          pt={"4px"}
          color={
            bootcampEntry.parent?.bootcampType === EntityName.MastermindBootcamp
              ? "white"
              : undefined
          }
        >
          {`Con: ${bootcampEntry.release?.mentors?.[0]?.name ?? ""} 
              ${bootcampEntry.release?.mentors?.[0]?.lastName ?? ""}`}
        </Text>
      </Flex>

      <Flex gap={2} mt={2}>
        {isGeneral && (
          <Badge
            px={"8px"}
            py={"2px"}
            bg={"neutral.50"}
            textTransform={"capitalize"}
            color={"neutral.500"}
            borderRadius={"6px"}
          >
            <Text variant={"feedback"}>General</Text>
          </Badge>
        )}

        {!isGeneral &&
          [
            bootcampEntry?.parent?.brand,
            bootcampEntry?.parent?.subCategory,
            bootcampEntry?.parent?.level,
          ].map(
            (category) =>
              category &&
              category.length < 2 &&
              category[0] &&
              checkCategory(category[0]) && (
                <Badge
                  key={category[0]?.id}
                  px={"8px"}
                  py={"2px"}
                  bg={"neutral.50"}
                  textTransform={"capitalize"}
                  color={"neutral.500"}
                  borderRadius={"6px"}
                >
                  <Text variant={"feedback"}>{category[0]?.name}</Text>
                </Badge>
              )
          )}
      </Flex>
      <Flex mt={"16px"} justifyContent={"space-between"}>
        <Button
          p={"0"}
          fontSize={"14px"}
          lineHeight={1}
          data-cy={TestIds.BootcampCard + "-" + bootcampEntry.release?.title}
          isDisabled={!bootcampEntry.release?.signUpLink ? true : false}
          variant={"link"}
          title={
            !isJoinnable && !isCta
              ? t("SeeAgenda")
              : targetBanner === BannerNames.COMPLETED
              ? t("SeeSessions")
              : bootcampEntry.release?.signUpLink && !isJoinnable && isCta
              ? t("Ver detalles")
              : t("Soon")
          }
          width={"fit-content"}
          display={breakpoint === "base" ? "flex" : "inline-flex"}
          justifyContent={"center"}
          onClick={() => {}}
        />
        <Text
          display={targetBanner !== BannerNames.MYBOOTCAMPS ? "flex" : "none"}
          fontSize={"12px"}
        >
          {t("Left slots")}
          <Text
            as="span"
            ml={"2px"}
            fontSize={"12px"}
            color={
              bootcampEntry.totalAttendees === bootcampEntry.release?.slots
                ? "error.300"
                : "secondary.300"
            }
          >
            {(bootcampEntry.release?.slots ?? 0) -
              (bootcampEntry.totalAttendees ?? 0)}{" "}
            {t("Slots")}
          </Text>
        </Text>
        {onEditClick && canEdit && <EditButton onClick={handleEditClick} />}

        {bootcampEntry.parent?.bootcampType !== EntityName.MastermindBootcamp &&
          hasRoles([UserRole.PremiumSubscriber]) &&
          targetBanner === BannerNames.MYBOOTCAMPS && (
            <Button
              p={0}
              fontSize={"14px"}
              variant={"text"}
              title={t("Cancel")}
              width={"fit-content"}
              justifyContent={"center"}
              onClick={handleBlockEnrroll}
              lineHeight={1}
            />
          )}
      </Flex>
    </Box>
  );
};
