import { useContext } from "react";
import { learnContext } from "../providers/learnProvider";
import { Box, Flex, Heading, Text, useBreakpointValue } from "@chakra-ui/react";
import { useUniqueCategories } from "./hooks";
import { Ico } from "@/assets/icons";

type IconCategoryKey =
  | "Amazon"
  | "Dropshipping"
  | "Mercado Libre"
  | "Full Commerce";

type IconCategory = {
  name: string;
  icon: JSX.Element;
  cleanIcon: JSX.Element;
};

const SubCategoryFilter = () => {
  const isMobile = useBreakpointValue({ base: true, xl: false });
  const iconSize = isMobile ? "32px" : "40px";
  const showIcon = false;
  const { selectedBrand, setSelectedSubCategory, selectedSubCategory } =
    useContext(learnContext);

  const uniqueCategories = useUniqueCategories() || [];

  const shouldRenderSubCategories = uniqueCategories.length > 1;

  const iconCategories: Record<IconCategoryKey, IconCategory> = {
    Amazon: {
      name: "Amazon",
      icon: <Ico.Amazon fontSize={iconSize} />,
      cleanIcon: <Ico.CleanAmazon fontSize={iconSize} />,
    },
    Dropshipping: {
      name: "Dropshipping",
      icon: <Ico.Dropshipping fontSize={iconSize} />,
      cleanIcon: <Ico.CleanDropshipping fontSize={iconSize} />,
    },
    "Mercado Libre": {
      name: "Mercado Libre",
      icon: <Ico.Meli fontSize={iconSize} />,
      cleanIcon: <Ico.CleanMeli fontSize={iconSize} />,
    },
    "Full Commerce": {
      name: "Full Commerce",
      icon: <Ico.FullComerce fontSize={iconSize} />,
      cleanIcon: <Ico.CleanFullComerce fontSize={iconSize} />,
    },
  };

  const handleSelectSubCategories = (category: string) => {
    setSelectedSubCategory(category);
  };

  const selectedIcon =
    selectedBrand?.name && selectedBrand.name in iconCategories
      ? iconCategories[selectedBrand.name as IconCategoryKey].icon
      : null;

  const notSelectedSubCategory =
    !selectedSubCategory || "" === selectedSubCategory;

  const defaultSubCategory = (
    <Flex
      key={"todos"}
      border="1px solid"
      borderColor="neutral.100"
      py={2}
      px={3}
      borderRadius="8px"
      maxH={"32px"}
      maxW={"fit-content"}
      textAlign="initial"
      onClick={() => handleSelectSubCategories("")}
      bg={notSelectedSubCategory ? "secondary.400" : undefined}
      alignItems={"center"}
      gap={2}
      cursor={"pointer"}
      _hover={{
        bg: notSelectedSubCategory ? "secondary.400" : "neutral.100",
      }}
    >
      {showIcon && <Ico.Amazon />}
      <Text
        variant={"bold"}
        fontSize={"14px"}
        w={"fit-content"}
        color={notSelectedSubCategory ? "white" : undefined}
      >
        {"Todos"}
      </Text>
    </Flex>
  );

  return (
    <Box w={"100%"}>
      <Flex direction="row" gap={"12px"} alignItems={"flex-end"}>
        {selectedIcon}
        <Heading as={"h4"}>
          {`${selectedBrand?.description} ${selectedBrand?.name}`}
        </Heading>
      </Flex>

      {shouldRenderSubCategories ? (
        <Box>
          <Text variant={"caption"} mt={4} mb={3}>
            Filtra por modelo de negocio:
          </Text>
          <Flex gap={3} flexDir={"row"} flexWrap={"wrap"}>
            {defaultSubCategory}
            {uniqueCategories?.map((category) => (
              <Flex
                key={category}
                border="1px solid"
                borderColor="neutral.100"
                py={2}
                px={3}
                borderRadius="8px"
                maxH={"32px"}
                maxW={"fit-content"}
                textAlign="initial"
                onClick={() => handleSelectSubCategories(category ?? "")}
                bg={
                  category === selectedSubCategory ? "secondary.400" : undefined
                }
                alignItems={"center"}
                gap={2}
                cursor={"pointer"}
                _hover={{
                  bg:
                    category === selectedSubCategory
                      ? "secondary.400"
                      : "neutral.100",
                }}
              >
                {showIcon && <Ico.Amazon />}
                <Text
                  variant={"bold"}
                  fontSize={"14px"}
                  w={"fit-content"}
                  color={category === selectedSubCategory ? "white" : undefined}
                >
                  {category}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Box>
      ) : null}
    </Box>
  );
};

export default SubCategoryFilter;
