import * as Types from '../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateExternalLinkMutationVariables = Types.Exact<{
  liveSessionId: Types.Scalars['ID'];
  isExternalLinkActive: Types.Scalars['Boolean'];
  externalLink?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateExternalLinkMutation = { __typename?: 'Mutation', updateExternalLink: { __typename?: 'LiveSession', id: string, externalLink?: string | null, isExternalLinkActive: boolean } };


export const UpdateExternalLinkDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateExternalLink"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"liveSessionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"isExternalLinkActive"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"externalLink"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateExternalLink"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"liveSessionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"liveSessionId"}}},{"kind":"Argument","name":{"kind":"Name","value":"isExternalLinkActive"},"value":{"kind":"Variable","name":{"kind":"Name","value":"isExternalLinkActive"}}},{"kind":"Argument","name":{"kind":"Name","value":"externalLink"},"value":{"kind":"Variable","name":{"kind":"Name","value":"externalLink"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"externalLink"}},{"kind":"Field","name":{"kind":"Name","value":"isExternalLinkActive"}}]}}]}}]} as unknown as DocumentNode<UpdateExternalLinkMutation, UpdateExternalLinkMutationVariables>;