/* eslint-disable no-console */
import { Box, Button, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import { ConnectionStatus, ReconnectionState } from "./connection";
import { VideoClient } from "@zoom/videosdk";

interface NetworkInformation {
  readonly effectiveType?: string;
  readonly downlink?: number;
  readonly rtt?: number;
  readonly saveData?: boolean;
  readonly type?: string;
  onchange?: EventListener;
  addEventListener(
    type: string,
    callback: EventListenerOrEventListenerObject | null,
    options?: boolean | AddEventListenerOptions
  ): void;
  removeEventListener(
    type: string,
    callback: EventListenerOrEventListenerObject | null,
    options?: boolean | EventListenerOptions
  ): void;
}

interface NavigatorWithConnection extends Navigator {
  connection?: NetworkInformation;
  mozConnection?: NetworkInformation;
  webkitConnection?: NetworkInformation;
}

interface ConnectionStatusOverlayProps {
  state: ReconnectionState;
  zmClient?: typeof VideoClient;
}

export const ConnectionStatusOverlay: React.FC<
  ConnectionStatusOverlayProps
> = ({ state, zmClient }) => {
  const [networkQuality, setNetworkQuality] = useState({
    uplink: 0,
    downlink: 0,
  });
  const [connection, setConnection] = useState<string>("");
  const [showReloadButton, setShowReloadButton] = useState(false);
  const [isReloading, setIsReloading] = useState(false);
  const speedCheckInterval = useRef<NodeJS.Timeout>();
  const showButtonTimeoutRef = useRef<NodeJS.Timeout>();

  const getStatusMessage = (): string => {
    try {
      switch (state.status) {
        case ConnectionStatus.CONNECTED:
          return "";
        case ConnectionStatus.DISCONNECTED:
          return "Se perdió la conexión a Internet. Por favor, verifica tu red.";
        case ConnectionStatus.RECONNECTING:
          return !zmClient
            ? "Iniciando sesión, espera un momento..."
            : "Estableciendo conexión con la sesión...";
        case ConnectionStatus.ZOOM_ERROR:
          if (typeof state.lastError === "string" && state.lastError.trim()) {
            return `Error de conexión: ${state.lastError}`;
          }
          return "No se pudo conectar con la sesión. Por favor, recarga la página.";
        default:
          return "Error de conexión desconocido";
      }
    } catch (error) {
      console.error("Error al obtener mensaje de estado:", error);
      return "Error al mostrar el estado de conexión";
    }
  };

  useEffect(() => {
    const resetButtonTimeout = () => {
      if (showButtonTimeoutRef.current) {
        clearTimeout(showButtonTimeoutRef.current);
        showButtonTimeoutRef.current = undefined;
      }
    };

    resetButtonTimeout();
    setShowReloadButton(false); // Resetear el estado del botón

    if (state.status === ConnectionStatus.RECONNECTING) {
      console.log(
        "Configurando temporizador para mostrar botón de recarga en estado RECONNECTING"
      );
      showButtonTimeoutRef.current = setTimeout(() => {
        console.log("Mostrando botón de recarga después del timeout");
        setShowReloadButton(true);
      }, 4000);
    } else if (
      state.status === ConnectionStatus.ZOOM_ERROR ||
      state.status === ConnectionStatus.DISCONNECTED
    ) {
      console.log(
        "Mostrando botón de recarga inmediatamente debido al estado:",
        state.status
      );
      setShowReloadButton(true);
    }

    return resetButtonTimeout;
  }, [state.status]);

  useEffect(() => {
    const updateConnectionInfo = () => {
      if (navigator.onLine) {
        const nav = navigator as NavigatorWithConnection;
        const conn =
          nav.connection || nav.mozConnection || nav.webkitConnection;

        if (conn) {
          const effectiveType = conn.effectiveType || "";
          const downlink = conn.downlink ? `${conn.downlink} Mbps` : "";
          setConnection(`${effectiveType.toUpperCase()} ${downlink}`.trim());
        } else {
          setConnection("Conectado");
        }
      } else {
        setConnection("Sin conexión");
      }
    };

    updateConnectionInfo();
    window.addEventListener("online", updateConnectionInfo);
    window.addEventListener("offline", updateConnectionInfo);

    const nav = navigator as NavigatorWithConnection;
    const conn = nav.connection || nav.mozConnection || nav.webkitConnection;

    if (conn) {
      conn.addEventListener("change", updateConnectionInfo as EventListener);
    }

    speedCheckInterval.current = setInterval(updateConnectionInfo, 1000);

    return () => {
      window.removeEventListener("online", updateConnectionInfo);
      window.removeEventListener("offline", updateConnectionInfo);
      if (conn) {
        conn.removeEventListener(
          "change",
          updateConnectionInfo as EventListener
        );
      }
      if (speedCheckInterval.current) {
        clearInterval(speedCheckInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!zmClient) return;

    const handleNetworkQuality = (payload: {
      uplink: number;
      downlink: number;
    }) => {
      if (navigator.onLine) {
        setNetworkQuality(payload);
      }
    };

    const mediaStream = zmClient.getMediaStream();
    if (mediaStream) {
      mediaStream.subscribeVideoStatisticData();
      mediaStream.subscribeAudioStatisticData();
      zmClient.on("network-quality-change", handleNetworkQuality);
    }

    return () => {
      if (zmClient) {
        zmClient.off("network-quality-change", handleNetworkQuality);
      }
    };
  }, [zmClient]);

  const handleReload = () => {
    if (isReloading) return;
    setIsReloading(true);

    try {
      if (zmClient?.getSessionInfo()?.isInMeeting) {
        console.log("Limpiando sesión antes de recargar");
        zmClient.leave();
      }
    } catch (error) {
      console.warn("Error al limpiar la sesión:", error);
    }

    console.log("Recargando página después de un breve delay");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  if (state.status === ConnectionStatus.CONNECTED) {
    return null;
  }

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      bg="secondary.300"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={4}
      zIndex={9999}
    >
      <Spinner size="xl" color="white" />
      <Box textAlign="center" maxW="600px" px={4}>
        <Text color="white" fontSize="xl" fontWeight="medium">
          {getStatusMessage()}
        </Text>
        {state.attemptCount > 0 && (
          <Text color="white" fontSize="sm" mt={2}>
            Intento {state.attemptCount} de 3
          </Text>
        )}
      </Box>
      {showReloadButton && (
        <Button
          colorScheme="blue"
          onClick={handleReload}
          mt={4}
          isDisabled={isReloading}
          _disabled={{
            opacity: 0.6,
            cursor: "not-allowed",
          }}
        >
          {isReloading ? "Recargando..." : "Recargar página"}
        </Button>
      )}
      <Box textAlign="center">
        <Text color="white" fontSize="sm">
          {connection && `Estado de red: ${connection}`}
        </Text>
        {navigator.onLine && (
          <Text color="white" fontSize="sm" mt={1}>
            Calidad Zoom - Subida: {networkQuality.uplink}/5, Bajada:{" "}
            {networkQuality.downlink}/5
          </Text>
        )}
      </Box>
    </Box>
  );
};
