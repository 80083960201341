import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { Ico } from "@/assets/icons";
import { colors } from "@/components/Theme/colors";
import { ProgramAccordion } from "./ProgramAccordion";
import { GetAllDataProgramByIdQuery } from "@/pages/academy/Programs/graphql/getAllProgramDataById.generated";
import { GetCohortByIdQuery } from "@/pages/Cohorts/graphql/getCohortById.generated";
import { ProgramData, Session } from "../ProgressDashboard/types";

interface ProgramResumeProps {
  programData?: GetAllDataProgramByIdQuery;
  programType: string;
  cohortData?: GetCohortByIdQuery;
}
export const ProgramContent: React.FC<ProgramResumeProps> = ({
  programData,
  programType,
  cohortData,
}) => {
  let globalIndex = 0;

  return (
    <Flex
      px={{ base: 4, xl: 0 }}
      flexDirection={"column"}
      maxW={"944px"}
      w={"full"}
    >
      <Text fontWeight={"bold"}>Contenido del Programa:</Text>
      <Flex py={2} gap={"24px"}>
        <Flex gap={1} alignItems={"center"}>
          <Ico.LaptopPlay fontSize={"16px"} color={colors.primary[200]} />
          <Text variant={"caption"}>
            {cohortData?.cohort?.cant_courses_program}
          </Text>
        </Flex>
        <Flex gap={1} alignItems={"center"}>
          <Ico.BookOpen fontSize={"16px"} color={colors.primary[200]} />
          <Text variant={"caption"}>
            {cohortData?.cohort?.cant_lessons_program}
          </Text>
        </Flex>
        <Flex gap={1} alignItems={"center"}>
          <Ico.Clock2 fontSize={"16px"} color={colors.primary[200]} />
          <Text variant={"caption"}>
            {cohortData?.cohort?.cant_hours_program}
          </Text>
        </Flex>
      </Flex>
      <Flex flexDir={"column"} pt={"24px"} gap={"24px"}>
        {programData?.getAllProgramDataById.map(
          (programContent: ProgramData) => {
            return programContent.sessions.map((session: Session) => {
              globalIndex++;
              return (
                <ProgramAccordion
                  index={globalIndex}
                  key={globalIndex}
                  sessionData={session}
                  programType={programType}
                />
              );
            });
          }
        )}
      </Flex>
    </Flex>
  );
};
