import { FilterOptions } from "./filterTypes";
import { useContext, useEffect, useRef, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { learnContext } from "@/pages/academy/Learn/providers/learnProvider";

type AcademicFilterProps = {
  isColumn?: boolean;
  filterOptions: string[] | null;
  handleFilterChange: (filter: string) => void;
};

const AcademicFilter = ({
  isColumn,
  filterOptions,
  handleFilterChange,
}: AcademicFilterProps) => {
  const { selectedSubCategory } = useContext(learnContext);
  const path = useLocation().pathname;
  const [selected, setSelected] = useState<string>(FilterOptions[0].label);
  const filterRef = useRef<HTMLDivElement | null>(null);
  const isDragging = useRef<boolean>(false);
  const startX = useRef<number>(0);
  const initialScrollLeft = useRef<number>(0);

  const handlePressFilter = (filter: string) => {
    setSelected(filter);
    handleFilterChange(filter);
  };

  useEffect(() => {
    if (filterOptions && filterOptions.length > 0) {
      setSelected(filterOptions[0]);
      handleFilterChange(filterOptions[0]);
    }
  }, [path]);

  useEffect(() => {
    setSelected("Todos");
    handleFilterChange("Todos");
  }, [selectedSubCategory]);

  const onMouseMove = (e: React.MouseEvent) => {
    if (!isDragging.current || !filterRef.current) return;
    e.preventDefault();
    const x = e.pageX - filterRef.current.offsetLeft;
    const walk = (x - startX.current) * 2;
    filterRef.current.scrollLeft = initialScrollLeft.current - walk;
  };

  const onMouseDown = (e: React.MouseEvent) => {
    if (filterRef.current) {
      isDragging.current = true;
      startX.current = e.pageX - filterRef.current.offsetLeft;
      initialScrollLeft.current = filterRef.current.scrollLeft;
    }
  };

  const onMouseLeave = () => {
    isDragging.current = false;
  };

  const onMouseUp = () => {
    isDragging.current = false;
  };

  return (
    filterOptions &&
    filterOptions.length > 2 && (
      <Box
        cursor={"grab"}
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseLeave}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        ref={filterRef}
        px={{
          base: "16px",
          md: isColumn ? "0px" : "16px",
          lg: "0",
        }}
        mb={"16px"}
        overflow="auto"
        display="flex"
        gap={"16px"}
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        {filterOptions.map((filter) => (
          <Flex
            key={filter}
            border="1px solid"
            borderColor="neutral.100"
            py={2}
            px={3}
            borderRadius="8px"
            maxH={"32px"}
            maxW={"fit-content"}
            textAlign="initial"
            onClick={() => handlePressFilter(filter)}
            bg={selected === filter ? "secondary.400" : undefined}
            alignItems={"center"}
            gap={2}
            cursor={"pointer"}
            _hover={{
              bg: selected === filter ? "secondary.400" : "neutral.100",
            }}
          >
            <Text
              variant={"bold"}
              fontSize={"14px"}
              w={"fit-content"}
              color={selected === filter ? "white" : undefined}
            >
              {filter}
            </Text>
          </Flex>
        ))}
      </Box>
    )
  );
};

export default AcademicFilter;
