import { Button, Text, VStack } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoute } from "@/AppRoute";
import { Trans } from "react-i18next";
import { colors } from "@/components/Theme/colors";

interface InformationMenuItem {
  label: string;
  route: AppRoute | string;
  newTab: boolean;
}

interface InformationMenuProps {
  isMenuExpanded: boolean;
}

const MenuData: InformationMenuItem[] = [
  {
    label: "About Us",
    route: "https://smartbeemo.com/",
    newTab: true,
  },
  {
    label: "frequent questions",
    route: AppRoute.FrequentQuestions,
    newTab: false,
  },
  { label: "Contact us", route: AppRoute.ContactUs, newTab: false },
  {
    label: "Terms and Conditions",
    route: AppRoute.TermsAndConditions,
    newTab: false,
  },
  {
    label: "privacyPolicyTranslations.header.Privacy Policy",
    route: AppRoute.PrivacyPolicies,
    newTab: false,
  },
];

export const InformationMenu = ({ isMenuExpanded }: InformationMenuProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <VStack spacing={0} align="stretch">
      {MenuData.map((item) => {
        const isActive = pathname === item.route;
        return (
          <Button
            hidden={!isMenuExpanded}
            key={item.label}
            w="100%"
            py="6px"
            pl={2}
            pr={10}
            bg="transparent"
            borderRadius="6px"
            justifyContent="flex-start"
            onClick={() => {
              if (item.newTab) {
                window.open(item.route, "_blank");
              } else {
                navigate(item.route);
              }
            }}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
          >
            {isMenuExpanded && (
              <Text
                fontSize="sm"
                flex={1}
                textAlign="start"
                _hover={{ color: `${colors.secondary[100]}` }}
                color={
                  isActive
                    ? `${colors.secondary[400]}`
                    : `${colors.secondary[300]}`
                }
              >
                <Trans>{item.label}</Trans>
              </Text>
            )}
          </Button>
        );
      })}
    </VStack>
  );
};
