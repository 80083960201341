import { useNavigate } from "react-router-dom";
import FilterItem from "./filterItem";
import { mainBrands } from "./data";
import { Flex } from "@chakra-ui/react";
import { AppRoute } from "@/AppRoute";
import { useContext } from "react";
import { learnContext } from "../providers/learnProvider";

const BrandFilter = () => {
  const { setSelectedBrand } = useContext(learnContext);
  const navigate = useNavigate();

  return (
    <Flex
      gap={{ base: "16px", xl: "18px" }}
      flexDir="row"
      justifyContent={{ base: "space-between", xl: "normal" }}
      w={"100%"}
      flexWrap={{ base: "wrap", xl: "nowrap" }}
    >
      {mainBrands.map((brand) => (
        <FilterItem
          key={brand.name}
          brand={brand}
          handleClick={() => {
            setSelectedBrand(brand);
            navigate(`${AppRoute.Learn}${brand.route}`);
          }}
        />
      ))}
    </Flex>
  );
};

export default BrandFilter;
