import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { VideoPlayer } from "./VideoPlayer";

interface VideoContainerProps extends BoxProps {
  url: string;
}

export const VideoContainer: React.FC<VideoContainerProps> = ({
  url,
  ...boxProps
}) => {
  return (
    <Box
      height="fit-content"
      aspectRatio={16 / 9}
      overflow="hidden"
      {...boxProps}
    >
      <VideoPlayer url={url} />
    </Box>
  );
};
