/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AdminRoute, AppRoute, PublicRoute } from "./AppRoute";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { useScrollToTop } from "./hooks/useScrolltoTop";
import { BootcampsDetails } from "./pages/academy/Bootcamps/BootcampDetail/BootcampsDetailsPage";
import { LiveSessionsAgoraPage } from "./pages/academy/LiveClasses/Agora/LiveSessionsAgoraPage";
import { SettingDevicesPage } from "./pages/academy/LiveClasses/Agora/SettingDevicesPage";
import PageStreaming from "./pages/academy/LiveSessionZoom/Streaming/PageStreaming";
import MarketplacePage from "./pages/Admin/Marketplace";
import { CreateBadgeForm } from "./pages/Badges/CreateBadgeFormPage";
import CreatePasswordPage from "./pages/CreatePassword";
import { UserRole } from "./schemaTypes";
import Learn from "./pages/academy/Learn";
import { OnboardingProvider } from "./pages/academy/Learn/providers/onboardingProvider";
import ServicesMarketplacePage from "./pages/ServicesMarketplace/index";
import ServicesMarketplaceDetailPage from "./pages/ServicesMarketplace/detail";
import { CommitmentProvider } from "./pages/OnBoarding/providers/commitmentProvider";
import { LearnProvider } from "./pages/academy/Learn/providers/learnProvider";
import { ProgramLandingPage } from "./pages/academy/Programs/ProgramLandingPage";
import { BigSpinner } from "./components/BigSpinner";
import Home from "./pages/Home";
import SignUpPage from "./pages/SignUpPage";
import { useReferral } from "./hooks/useReferral";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import { FrequentQuestions } from "./pages/FrequentQuestions";
import { ContactUs } from "./pages/ContactUs";
import { AboutUs } from "./pages/AboutUs";
import { PrivacyPolicies } from "./pages/PrivacyPolicies";
import OnBoardingConffetiModal from "./components/ModalOnboarding/OnBoardingConffetiModal";
import { HighTicketAdminprovider } from "./pages/Admin/HighTicket/providers/HighTicketAdminProvider";
import { MentorshipModalProvider } from "./pages/Admin/HighTicket/providers/MentorshipModalProvider";

const EditSpecializationPage = lazy(
  () => import("./pages/academy/Specializations/EditSpecializationPage")
);

const AcademyAdmin = lazy(() => import("./pages/Admin/Academy"));

const MyCertificatesAndBadgesPage = lazy(
  () => import("./pages/MyCertificatesAndBadges/MyCertificatesAndBadgesPage")
);
const ChinaAgentHomePage = lazy(() => import("./pages/products/ChinaAgent"));
const ChinaAgentRegisterPage = lazy(
  () => import("./pages/products/ChinaAgent/Register")
);
const ConnectWithRocketfyPage = lazy(
  () => import("./pages/Rocketfy/ConnectWithRocketfyPage")
);
const ProductsListPage = lazy(() => import("./pages/products/Selection"));
const HotProductsHomePage = lazy(
  () => import("./pages/products/ProductsHomePage")
);

const HotProductsSelection = lazy(() => import("./pages/products/Calendar"));
const ProductDetailPage = lazy(() => import("./pages/products/Detail"));

const AdministrationPanel = lazy(() => import("./pages/Admin"));

const ValidateEmailPage = lazy(() => import("./pages/ValidateEmailPage"));
const EnrollPage = lazy(() => import("./pages/Enroll/EnrollPage"));
const CertificatePage = lazy(
  () => import("./pages/Certificates/CertificatePage")
);
const CourseListPage = lazy(
  () => import("./pages/academy/Courses/CourseListPage")
);
const FreeCoursesListPage = lazy(
  () => import("./pages/academy/Courses/FreeCoursesListPage")
);
const CourseDashboard = lazy(
  () => import("./pages/academy/Courses/CourseDashboardPage")
);
const CourseDashboardRedirect = lazy(
  () => import("./pages/academy/Courses/CourseDashboardRedirectPage")
);
const UnauthorizedPage = lazy(() => import("./pages/UnauthorizedPage"));
const ModuleDetailPage = lazy(() => import("./pages/Modules/ModuleDetailPage"));
const SessionsByeMentorPage = lazy(
  () => import("./pages/Mentors/SessionsByMentorPage")
);

const ExecutionReportPage = lazy(() => import("./pages/Changelog/Changelog"));

const UserListPage = lazy(() => import("./pages/Users/UserListPage"));
const GroupGeneratorPage = lazy(
  () => import("./pages/GroupGenerator/GroupGeneratorPage")
);
const EditUserPage = lazy(() => import("./pages/Users/EditUserPage"));
const MyProgressPage = lazy(() => import("./pages/MyProgress/MyProgressPage"));
const GenerateCalendarEventsPage = lazy(
  () => import("./pages/CalendarEvents/GenerateCalendarEventsPage")
);
const UserProfilePage = lazy(() => import("./pages/Users/UserProfilePage"));
const EditModulePage = lazy(() => import("./pages/Modules/EditModulePage"));
const EditCohortPage = lazy(() => import("./pages/Cohorts/EditCohortPage"));
const EditSessionPage = lazy(() => import("./pages/Sessions/EditSessionPage"));
const EditCoursePage = lazy(
  () => import("./pages/academy/Courses/EditCoursePage")
);
const EditCalendarEventPage = lazy(
  () => import("./pages/CalendarEvents/EditCalendarEventPage")
);
const InteractiveContentPage = lazy(
  () => import("./pages/Sessions/InteractiveContentPage")
);
const InteractiveContentPagePro = lazy(
  () => import("./pages/Sessions/InteractiveContentPagePro")
);
const EditInteractiveContentPage = lazy(
  () => import("./pages/Sessions/EditInteractiveContentPage")
);
const FormListPage = lazy(() => import("./pages/Forms/FormListPage"));

const EditFormPage = lazy(() => import("./pages/Forms/EditFormPage"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));
const CohortMetricsPage = lazy(
  () => import("./pages/Cohorts/CohortMetricsPage")
);
const BadgesPage = lazy(() => import("./pages/Badges/BadgesListPage"));
const ReportsPage = lazy(() => import("./pages/Reports/ReportsPage"));
const CoinsAllocationPage = lazy(
  () => import("./pages/Gamification/CoinsAllocationPage")
);
const CohortNotificationsPage = lazy(
  () => import("./pages/Cohorts/CohortNotificationsPage")
);
const ProgramsPage = lazy(
  () => import("./pages/academy/Programs/ProgramsPage")
);
const ProgramDetailPage = lazy(
  () => import("./pages/academy/Programs/ProgramDetailPage")
);
const CommunityAdminPage = lazy(
  () => import("./pages/community/CommunityPage")
);
const CommunityEventPage = lazy(
  () => import("./pages/community/CommunityEventPage")
);
const MasterClassPage = lazy(
  () => import("./pages/academy/MasterClasses/MasterClassPage")
);
const OnboardingSchedulePage = lazy(() => import("./pages/OnboardingSchedule"));

const SolutionsPage = lazy(() => import("./pages/Solutions/SolutionsPage"));
const SolutionDetailsPage = lazy(
  () => import("./pages/Solutions/SolutionDetailsPage")
);

const MyOrdersPage = lazy(() => import("./pages/MyOrders/MyOrdersPage"));

const MyOrdersProgressPage = lazy(
  () => import("./pages/MyOrders/myOrdersProgressPage")
);

const MyProfileGamification = lazy(
  () => import("./pages/MyProfileGamification/MyProfileGamificationPage")
);

// const Learn = lazy(() => import("./pages/Learn"));

const SubscriptionExpiredPage = lazy(
  () => import("./pages/SubscriptionExpired/SubscriptionExpiredPage")
);

const BootcampsBasicPage = lazy(
  () => import("./pages/academy/Bootcamps/BootcampsBasicPage")
);
const BootcampsAdvancedPage = lazy(
  () => import("./pages/academy/Bootcamps/BootcampsAdvancedPage")
);
const EditProgramPage = lazy(
  () => import("./pages/academy/Programs/EditProgramPage")
);

const ConductCodePage = lazy(() => import("./pages/ConductCodePage"));
const ServiceTermsPage = lazy(() => import("./pages/ServiceTermsPage"));
const InviteUsersPage = lazy(() => import("./pages/Cohorts/CohortInvitesPage"));

const OrganizationsListPage = lazy(
  () => import("./pages/Organizations/OrganizationsListPage")
);
const OrganizationEditPage = lazy(
  () => import("./pages/Organizations/OrganizationEditPage")
);
const ProgramsRedirectPage = lazy(
  () => import("./pages/academy/Programs/ProgramsRedirectPage")
);
const CLILoginPage = lazy(() => import("./pages/CLILoginPage"));
const EditTeamPage = lazy(() => import("./pages/Teams/EditTeamPage"));
const CohortTeamsPage = lazy(() => import("./pages/Teams/CohortTeamsPage"));
const OSSAttributionPage = lazy(
  () => import("./pages/OSSAttributions/OSSAttributionsPage")
);
/* const UnboxingPage = lazy(() => import("./pages/Unboxing/UnboxingPage")); */

const ConsultingPage = lazy(() => import("@/pages/Consulting/ConsultingPage"));
const ConsultantDetailsPage = lazy(
  () => import("@/pages/Consulting/ConsultantDetailsPage")
);

const SpecializationsPage = lazy(
  () => import("./pages/academy/Specializations/SpecializationsPage")
);
const SpecializationsRedirectPage = lazy(
  () => import("./pages/academy/Specializations/SpecializationsRedirectPage")
);
const ProfilingPage = lazy(() => import("./pages/OnBoarding"));

const BootcampsAgenda = lazy(
  () => import("./pages/academy/Bootcamps/BootcampsAgenda")
);

const BootcampsFrequentQuestions = lazy(
  () => import("./pages/academy/Bootcamps/BootcampsFrequentQuestions")
);

const SolutionsPanel = lazy(() => import("./pages/Admin/Solutions"));

const ProductsPanel = lazy(() => import("./pages/Admin/Product"));

const ConsultantsPage = lazy(() => import("./pages/Admin/Consultants"));

const CategoriesPage = lazy(() => import("./pages/Admin/Categories"));

const CommunityAdminPanel = lazy(() => import("./pages/Admin/Community"));

const CreateBootcampsPage = lazy(
  () => import("./pages/academy/Bootcamps/CreateBootcampsPage")
);

const HighTicketMentorshipDetailPage = lazy(
  () => import("./pages/HighTicketMentorship/detail")
);

const HighTicketAdminPage = lazy(
  () => import("./pages/Admin/HighTicket/index")
);

const LiveSessionsDetailPage = lazy(
  () => import("@/pages/academy/LiveClasses/LiveSessionsDetailsPage")
);

const UpsellAndCrossLanding = lazy(
  () => import("./pages/UpsellLanding/UpsellLandingPage")
);
const TermsAndConditionsPage = lazy(() => import("./pages/TermsConditionPage"));
const ForumPage = lazy(() => import("./pages/Forum/ForumHomePage"));
export const Router: React.FC = () => {
  const location = useLocation();
  useScrollToTop();
  useReferral();

  useEffect(() => {
    if (window.ga !== undefined) {
      ga("send", "pageview", location.pathname);
    }
  }, [location]);

  return (
    <>
      <OnBoardingConffetiModal />
      <Routes>
        <Route
          path={AppRoute.MyProgress}
          element={
            <ProtectedRoute showUserMenus={false}>
              <MyProgressPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={AppRoute.Certificates}
          element={
            <ProtectedRoute showUserMenus={false}>
              <MyCertificatesAndBadgesPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Certificate}/:certificateId`}
          element={<CertificatePage />}
        />

        <Route
          path={AppRoute.Base}
          element={
            <LearnProvider>
              <OnboardingProvider>
                <Learn />
              </OnboardingProvider>
            </LearnProvider>
          }
        />

        <Route path={PublicRoute.ConductCode} element={<ConductCodePage />} />

        <Route path={PublicRoute.ServiceTerms} element={<ServiceTermsPage />} />

        <Route
          path={PublicRoute.OSSAttributions}
          element={<OSSAttributionPage />}
        />

        <Route path={PublicRoute.CLILogin} element={<CLILoginPage />} />

        <Route
          path={`${PublicRoute.ValidateEmail}/:token`}
          element={<ValidateEmailPage />}
        />

        <Route
          path={AppRoute.Learn}
          element={
            <LearnProvider>
              <OnboardingProvider>
                <Learn />
              </OnboardingProvider>
            </LearnProvider>
          }
        >
          <Route
            path={":subroute"}
            element={
              <LearnProvider>
                <OnboardingProvider>
                  <Learn />
                </OnboardingProvider>
              </LearnProvider>
            }
          />
        </Route>

        <Route
          path={AppRoute.MisPedidos}
          element={
            <ProtectedRoute showUserMenus={true}>
              <MyOrdersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.MyProfileGamification}
          element={
            <ProtectedRoute showUserMenus={true}>
              <MyProfileGamification />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.MyOrdersProgress}
          element={
            <ProtectedRoute showUserMenus={true}>
              <MyOrdersProgressPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.Consulting}
          element={
            <ProtectedRoute showUserMenus={false}>
              <ConsultingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.ExecutionReport}
          element={
            <ProtectedRoute>
              <ExecutionReportPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Consulting}/:consultantId`}
          element={
            <ProtectedRoute showUserMenus={false}>
              <ConsultantDetailsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.OnlinePrograms}/:programId${AppRoute.Courses}/:phaseId${AppRoute.Cohorts}/:cohortId`}
          element={
            <ProtectedRoute>
              <ProgramLandingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.OnlinePrograms}/:courseId${AppRoute.Cohorts}/:cohortId`}
          element={
            <ProtectedRoute>
              <ProgramLandingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.Specializations}
          element={
            <ProtectedRoute
              allowedRoles={[
                UserRole.Pro,
                UserRole.BasicSubscriber,
                UserRole.PremiumSubscriber,
              ]}
            >
              <SpecializationsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Specializations}/:programId${AppRoute.Courses}/:phaseId${AppRoute.Cohorts}/:cohortId${AppRoute.Dashboard}`}
          element={
            <ProtectedRoute>
              <CourseDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Specializations}/:programId`}
          element={
            <ProtectedRoute>
              <SpecializationsRedirectPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.Profiling}
          element={
            <ProtectedRoute showUserMenus={false}>
              <CommitmentProvider>
                <ProfilingPage />
              </CommitmentProvider>
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.LiveSessions}/:liveSessionId`}
          element={<LiveSessionsDetailPage />}
        />

        <Route
          path={`${AppRoute.LiveSessionAgoraInternal}/new`}
          element={
            <ProtectedRoute>
              <LiveSessionsAgoraPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.LiveSessionStreaming}/:liveSessionId`}
          element={
            <ProtectedRoute>
              <PageStreaming />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.LiveSessionBroadcast}/:liveSessionId`}
          element={
            <ProtectedRoute>
              <PageStreaming />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.SettingDevices}/:liveSessionId`}
          element={
            <ProtectedRoute>
              <SettingDevicesPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${PublicRoute.Enroll}/:courseId${AppRoute.Cohorts}/:cohortId`}
          element={<EnrollPage />}
        />

        <Route
          path={AppRoute.Courses}
          element={
            <ProtectedRoute
              allowedRoles={[
                UserRole.Pro,
                UserRole.BasicSubscriber,
                UserRole.PremiumSubscriber,
              ]}
            >
              <CourseListPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.AdvancedCourse}
          element={
            <ProtectedRoute
              allowedRoles={[
                UserRole.Pro,
                UserRole.BasicSubscriber,
                UserRole.PremiumSubscriber,
              ]}
            >
              <CourseListPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${PublicRoute.CreatePassword}/:token`}
          element={<CreatePasswordPage />}
        />

        <Route
          path={`${AppRoute.AdvancedCourse}/:courseId${AppRoute.Modules}/:moduleId${AppRoute.Sessions}/:sessionId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditSessionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Courses}${AppRoute.Free}`}
          element={
            <ProtectedRoute>
              <FreeCoursesListPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={AppRoute.Users}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <UserListPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.HighTicketMentorship}`}
          element={<BigSpinner></BigSpinner>}
        ></Route>
        <Route
          path={`${AppRoute.HighTicketMentorship}/:mentorshipId`}
          element={
            <ProtectedRoute>
              <HighTicketMentorshipDetailPage></HighTicketMentorshipDetailPage>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path={AppRoute.Forms}
          element={
            <ProtectedRoute>
              <FormListPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Forms}/:formId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditFormPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Forms}/:formId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditFormPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Programs}${AppRoute.Courses}/:courseId${AppRoute.Forms}/:formId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditFormPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Specializations}${AppRoute.Courses}/:courseId${AppRoute.Forms}/:formId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditFormPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.AdvancedCourse}/:courseId${AppRoute.Forms}/:formId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditFormPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Modules}/:moduleId${AppRoute.Sessions}/:sessionId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditSessionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.AdvancedCourse}/:courseId${AppRoute.Modules}/:moduleId${AppRoute.Sessions}/:sessionId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditSessionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Programs}${AppRoute.Courses}/:courseId${AppRoute.Modules}/:moduleId${AppRoute.Sessions}/:sessionId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditSessionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Specializations}${AppRoute.Courses}/:courseId${AppRoute.Modules}/:moduleId${AppRoute.Sessions}/:sessionId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditSessionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Programs}${AppRoute.Courses}/:courseId${AppRoute.Sessions}/:sessionId${AppRoute.InteractiveContent}/:interactiveContentId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditInteractiveContentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Specializations}${AppRoute.Courses}/:courseId${AppRoute.Sessions}/:sessionId${AppRoute.InteractiveContent}/:interactiveContentId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditInteractiveContentPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Sessions}/:sessionId${AppRoute.InteractiveContent}/:interactiveContentId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditInteractiveContentPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.AdvancedCourse}/:courseId${AppRoute.Sessions}/:sessionId${AppRoute.InteractiveContent}/:interactiveContentId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditInteractiveContentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Sessions}/:sessionId${AppRoute.CalendarEvents}/:calendarEventId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Mentor]}>
              <EditCalendarEventPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Cohorts}/:cohortId${AppRoute.Sessions}/:sessionId${AppRoute.InteractiveContent}`}
          element={
            <ProtectedRoute>
              <InteractiveContentPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Programs}/:programId${AppRoute.Courses}/:courseId${AppRoute.Cohorts}/:cohortId${AppRoute.Sessions}/:sessionId${AppRoute.InteractiveContent}`}
          element={
            <ProtectedRoute>
              <InteractiveContentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.AdvancedCourse}/:courseId${AppRoute.Cohorts}/:cohortId${AppRoute.Sessions}/:sessionId${AppRoute.InteractiveContent}`}
          element={
            <ProtectedRoute>
              <InteractiveContentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Specializations}/:programId${AppRoute.Courses}/:courseId${AppRoute.Cohorts}/:cohortId${AppRoute.Sessions}/:sessionId${AppRoute.InteractiveContent}`}
          element={
            <ProtectedRoute>
              <InteractiveContentPagePro />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Cohorts}/:cohortId${AppRoute.Gamification}/coins-allocation`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <CoinsAllocationPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Cohorts}/:cohortId${AppRoute.Notifications}/create`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <CohortNotificationsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Cohorts}/:cohortId${AppRoute.Metrics}`}
          element={
            <ProtectedRoute>
              <CohortMetricsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Cohorts}/:cohortId${AppRoute.Invites}`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <InviteUsersPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Cohorts}/:cohortId/generate${AppRoute.CalendarEvents}`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <GenerateCalendarEventsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Programs}${AppRoute.Courses}/:courseId${AppRoute.Modules}/:moduleId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditModulePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Specializations}${AppRoute.Courses}/:courseId${AppRoute.Modules}/:moduleId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditModulePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Modules}/:moduleId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditModulePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.AdvancedCourse}/:courseId${AppRoute.Modules}/:moduleId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditModulePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Cohorts}/:cohortId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditCohortPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.AdvancedCourse}/:courseId${AppRoute.Cohorts}/:cohortId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditCohortPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.AdvancedCourse}/:courseId${AppRoute.Cohorts}/:cohortId${AppRoute.Dashboard}`}
          element={
            <ProtectedRoute>
              <CourseDashboard isPro={true} />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Learn}/:courseId${AppRoute.Cohorts}/:cohortId${AppRoute.Dashboard}`}
          element={
            <ProtectedRoute>
              <CourseDashboard isPro={true} />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Programs}/:programId${AppRoute.Cohorts}/:cohortId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditCohortPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Modules}/:moduleId`}
          element={
            <ProtectedRoute>
              <ModuleDetailPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Mentors}/:mentorId${AppRoute.Sessions}`}
          element={
            <ProtectedRoute>
              <SessionsByeMentorPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Users}/:userId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditUserPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Organizations}/:organizationId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <OrganizationEditPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={AppRoute.Organizations}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <OrganizationsListPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Programs}/:programId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditProgramPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Specializations}/:programId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditSpecializationPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Programs}/:programId`}
          element={
            <ProtectedRoute>
              <ProgramsRedirectPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Programs}/:programId${AppRoute.Cohorts}/:cohortId`}
          element={
            <ProtectedRoute>
              <ProgramDetailPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Specializations}/:programId${AppRoute.Cohorts}/:cohortId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditCohortPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Specializations}${AppRoute.Courses}/:courseId${AppRoute.Cohorts}/:cohortId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditCohortPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={AppRoute.Programs}
          element={
            <ProtectedRoute>
              <ProgramsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={AppRoute.ConnectWithRocketfy}
          element={
            <ProtectedRoute>
              <ConnectWithRocketfyPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={AppRoute.Community}
          element={
            <ProtectedRoute>
              <CommunityAdminPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.CommunityEvents}
          element={
            <ProtectedRoute>
              <CommunityEventPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.MasterClass}
          element={
            <ProtectedRoute>
              <MasterClassPage isWorkshop={false} />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.Onboarding}
          element={
            <ProtectedRoute>
              <OnboardingSchedulePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.Workshop}
          element={
            <ProtectedRoute>
              <MasterClassPage isWorkshop={true} />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.Solutions}
          element={
            <ProtectedRoute>
              <SolutionsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Solutions}/:solutionId`}
          element={
            <ProtectedRoute>
              <SolutionDetailsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={AppRoute.SuscriptionExpider}
          element={
            <ProtectedRoute showUserMenus={false}>
              <SubscriptionExpiredPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={AdminRoute.AdministrationPanel}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Editor]}>
              <AdministrationPanel />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AdminRoute.AdministrationPanel}${AdminRoute.Academy}/:subpage`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Academic]}>
              <AcademyAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AdminRoute.AdministrationPanel}${AdminRoute.HighTicketMentorship}`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Mentor]}>
              <HighTicketAdminprovider>
                <MentorshipModalProvider>
                  <HighTicketAdminPage />
                </MentorshipModalProvider>
              </HighTicketAdminprovider>
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AdminRoute.AdministrationPanel}${AdminRoute.General}`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Editor]}>
              <CategoriesPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AdminRoute.AdministrationPanel}${AdminRoute.Community}`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Editor]}>
              <CommunityAdminPanel />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AdminRoute.AdministrationPanel}${AdminRoute.Solutions}`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Editor]}>
              <SolutionsPanel />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AdminRoute.AdministrationPanel}${AdminRoute.Products}`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Editor]}>
              <ProductsPanel />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AdminRoute.AdministrationPanel}${AdminRoute.Consultants}`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Editor]}>
              <ConsultantsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AdminRoute.AdministrationPanel}${AdminRoute.Marketplace}`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <MarketplacePage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.ServicesMarketplace}`}
          element={<ServicesMarketplacePage></ServicesMarketplacePage>}
        />
        <Route
          path={`${AppRoute.ServicesMarketplace}/:serviceId`}
          element={
            <ServicesMarketplaceDetailPage></ServicesMarketplaceDetailPage>
          }
        />

        <Route
          path={`${AdminRoute.AdministrationPanel}${AdminRoute.HighTicketMentorship}`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Mentor]}>
              <HighTicketAdminPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={AppRoute.ChinaAgent}
          element={
            <ProtectedRoute>
              <ChinaAgentHomePage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.ChinaAgentForm}/:productId?`}
          element={
            <ProtectedRoute
              allowedRoles={[
                UserRole.Pro,
                UserRole.PremiumSubscriber,
                UserRole.Admin,
              ]}
            >
              <ChinaAgentRegisterPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Products}/:locked`}
          element={
            <ProtectedRoute>
              <HotProductsHomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.Products}
          element={
            <ProtectedRoute>
              <HotProductsHomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.Productselection}
          element={
            <ProtectedRoute>
              <HotProductsSelection />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Productselection}/:productSelectionFilter/:startDate`}
          element={
            <ProtectedRoute>
              <ProductsListPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Productselection}/:productId`}
          element={
            <ProtectedRoute
              allowedRoles={[
                UserRole.Pro,
                UserRole.PremiumSubscriber,
                UserRole.Admin,
              ]}
            >
              <ProductDetailPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.BootcampsRelease}
          element={
            <ProtectedRoute>
              <BootcampsBasicPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={AppRoute.BootcampsAdvanced}
          element={
            <ProtectedRoute>
              <BootcampsAdvancedPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.BootcampsRelease}/:parentId/:releaseId${AppRoute.Agenda}`}
          element={
            <ProtectedRoute>
              <BootcampsAgenda />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Bootcamps}/:parentId/:releaseId`}
          element={
            <ProtectedRoute>
              <BootcampsDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.BootcampsRelease}/:parentId/:releaseId${AppRoute.FrequentQuestions}`}
          element={
            <ProtectedRoute>
              <BootcampsFrequentQuestions />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AdminRoute.AdministrationPanel}${AdminRoute.Academy}${AdminRoute.Bootcamps}/:bootcampId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Mentor]}>
              <CreateBootcampsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AdminRoute.AdministrationPanel}${AdminRoute.Academy}${AdminRoute.Bootcamps}/:bootcampId/edit/:releaseId`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Mentor]}>
              <CreateBootcampsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.BootcampsAdvanced}/:parentId/:releaseId${AppRoute.Agenda}`}
          element={
            <ProtectedRoute>
              <BootcampsAgenda />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.BootcampsAdvanced}/:parentId/:releaseId${AppRoute.FrequentQuestions}`}
          element={
            <ProtectedRoute>
              <BootcampsFrequentQuestions />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.BootcampsAdvanced}/:bootcampId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Mentor]}>
              <CreateBootcampsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.BootcampsAdvanced}/:bootcampId/edit/:releaseId`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Mentor]}>
              <CreateBootcampsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Users}/:userId`}
          element={
            <ProtectedRoute>
              <UserProfilePage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Users}/:userId${AppRoute.Teams}/:teamId/edit`}
          element={
            <ProtectedRoute>
              <EditTeamPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={AppRoute.TermsAndConditions}
          element={
            <ProtectedRoute>
              <TermsAndConditionsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.FrequentQuestions}
          element={
            <ProtectedRoute>
              <FrequentQuestions />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.ContactUs}
          element={
            <ProtectedRoute>
              <ContactUs />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.AboutUs}
          element={
            <ProtectedRoute>
              <AboutUs />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoute.PrivacyPolicies}
          element={
            <ProtectedRoute>
              <PrivacyPolicies />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${PublicRoute.ResetPassword}/:token`}
          element={<ResetPasswordPage />}
        />

        <Route
          path={PublicRoute.ForgotPasssword}
          element={<ForgotPasswordPage />}
        />

        <Route
          path={`${PublicRoute.LiveSessionPortalV1}/:liveSessionId/:channelName/:userId/:name`}
          element={<PageStreaming />}
        />

        <Route
          path={AppRoute.Forum}
          element={
            <ProtectedRoute>
              <ForumPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Cohorts}/:cohortId${AppRoute.Teams}/:teamId/edit`}
          element={
            <ProtectedRoute>
              <EditTeamPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Cohorts}/:cohortId${AppRoute.Teams}`}
          element={
            <ProtectedRoute>
              <CohortTeamsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Users}/:userId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <EditUserPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Users}`}
          element={
            <ProtectedRoute>
              <UserListPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId${AppRoute.Tools}/group-generator`}
          element={
            <ProtectedRoute>
              <GroupGeneratorPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Courses}/:courseId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Mentor]}>
              <EditCoursePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Specializations}/:courseId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Mentor]}>
              <EditCoursePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Specializations}${AppRoute.Courses}/:courseId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Mentor]}>
              <EditCoursePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Programs}/:courseId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Mentor]}>
              <EditCoursePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.Programs}${AppRoute.Courses}/:courseId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Mentor]}>
              <EditCoursePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${AppRoute.AdvancedCourse}/:courseId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Mentor]}>
              <EditCoursePage />
            </ProtectedRoute>
          }
        />

        {/* <Route
        path={`${AppRoute.Specializations}/:programId${AppRoute.Courses}/:phaseId${AppRoute.Cohorts}/:cohortId${AppRoute.Dashboard}`}
        element={
          <ProtectedRoute>
            <CourseDashboard />
          </ProtectedRoute>
        }
      /> */}

        <Route
          path={`${AppRoute.Programs}/:programId${AppRoute.Courses}/:phaseId${AppRoute.Cohorts}/:cohortId${AppRoute.Dashboard}`}
          element={
            <LearnProvider>
              <ProtectedRoute>
                <CourseDashboard isPro={false} />
              </ProtectedRoute>
            </LearnProvider>
          }
        />

        <Route
          path={`${AppRoute.AdvancedCourse}/:courseId`}
          element={
            <ProtectedRoute>
              <CourseDashboardRedirect />
            </ProtectedRoute>
          }
        />

        <Route
          path={AppRoute.Badges}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <BadgesPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.Badges}/:badgeId/edit`}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <CreateBadgeForm />
            </ProtectedRoute>
          }
        />

        <Route
          path={AppRoute.Reports}
          element={
            <ProtectedRoute allowedRoles={[UserRole.Admin]}>
              <ReportsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${AppRoute.UpsellAndCross}/:productId?`}
          element={<UpsellAndCrossLanding></UpsellAndCrossLanding>}
        ></Route>

        <Route path={PublicRoute.Login} element={<Home />} />
        <Route path={PublicRoute.SignUp} element={<SignUpPage />} />

        <Route
          path={`${AppRoute.Unauthorized}/*`}
          element={<UnauthorizedPage />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};
