import { Ico } from "@/assets/icons";
import { colors } from "@/components/Theme/colors";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { FC } from "react";

type FreePlanAccordionProps = {
  showFreePlanButton?: boolean;
  onFreePlanClick?: () => void;
  t: (key: string) => string;
};

export const FreePlanAccordion: FC<FreePlanAccordionProps> = ({
  showFreePlanButton = false,
  onFreePlanClick,
  t,
}) => {
  return (
    <Accordion
      border={"solid 1px"}
      borderColor={colors.neutral[100]}
      borderRadius={"6px"}
      allowMultiple
      w={"100%"}
    >
      <AccordionItem border="none">
        {({ isExpanded }) => (
          <>
            <AccordionButton
              _hover={{ WebkitTapHighlightColor: "transparent" }}
              _expanded={{ WebkitTapHighlightColor: "transparent" }}
              _focus={{ WebkitTapHighlightColor: "transparent" }}
              p={4}
              borderRadius="6px"
              alignItems={"flex-start"}
            >
              <Flex flexDirection={"column"}>
                <Box>
                  <Text
                    fontFamily={"Poppins"}
                    fontWeight={600}
                    fontSize={"18px"}
                    textAlign={"left"}
                  >
                    Gratis
                  </Text>
                  <Text
                    fontFamily={"Inter"}
                    fontWeight={400}
                    fontSize={"12px"}
                    color={"secondary.200"}
                    textAlign={"left"}
                    w="100%"
                  >
                    Explora lo esencial de smartBeemo, accede a lecciones
                    introductorias y aprovecha contenido gratuito para dar tus
                    primeros paso en ecommerce.
                  </Text>
                </Box>
                {!isExpanded && (
                  <Button
                    onClick={onFreePlanClick}
                    variant={"secondary"}
                    bg={colors.neutral[50]}
                    color={colors.primary[300]}
                    w={"100%"}
                    minWidth={"100%"}
                    margin="8px auto"
                    mt={4}
                    border={"none"}
                    px={4}
                    _hover={{ backgroundColor: colors.neutral[100] }}
                    _active={"none"}
                    _focus={"none"}
                    fontSize={"14px"}
                    fontFamily={"Inter"}
                  >
                    {t("Continuar Gratis")}
                  </Button>
                )}
              </Flex>
              <Box
                w="24px"
                h="24px"
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {isExpanded ? (
                  <Ico.Minus fontSize="24px" color={colors.primary[300]} />
                ) : (
                  <Ico.Plus fontSize="24px" color={colors.primary[300]} />
                )}
              </Box>
            </AccordionButton>

            <AccordionPanel pt={0} pb={4} px={4}>
              <Flex gap={4} flexDirection={"column"}>
                <Flex gap={1} flexDirection={"column"}>
                  <Text fontFamily={"Inter"} fontWeight={600} fontSize={"14px"}>
                    En Vivo
                  </Text>
                  <Flex gap={1}>
                    <Ico.Check fontSize={"20px"} color={colors.success[300]} />
                    <Text
                      fontFamily={"Inter"}
                      fontWeight={400}
                      fontSize={"12px"}
                      color={"secondary.300"}
                      w={"100%"}
                    >
                      Sesiones en Vivo.
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap={1} flexDirection={"column"}>
                  <Text fontFamily={"Inter"} fontWeight={600} fontSize={"14px"}>
                    Beneficios
                  </Text>
                  <Flex gap={1}>
                    <Ico.Check fontSize={"20px"} color={colors.success[300]} />
                    <Text
                      fontFamily={"Inter"}
                      fontWeight={400}
                      fontSize={"12px"}
                      color={"secondary.300"}
                      w="100%"
                    >
                      Catálogo de herramientas con beneficios y soluciones de
                      tecnología, marketing, diseño, finanzas y mucho más.
                    </Text>
                  </Flex>
                </Flex>
                {showFreePlanButton && (
                  <Button
                    onClick={onFreePlanClick}
                    variant={"secondary"}
                    bg={colors.neutral[50]}
                    color={colors.primary[300]}
                    w={"100%"}
                    minWidth={"100%"}
                    margin="8px auto"
                    border={"none"}
                    px={4}
                    _hover={{ backgroundColor: colors.neutral[100] }}
                    _active={"none"}
                    _focus={"none"}
                    fontSize={"14px"}
                    fontFamily={"Inter"}
                  >
                    {t("Continuar Gratis")}
                  </Button>
                )}
              </Flex>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};
