import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AppRoute } from "@/AppRoute";
import { useUser } from "@/providers/useUser";
import { User } from "@/schemaTypes";

import { GetUserPersonalInfoDocument } from "./graphql";
import { IsProfilingDoneDocument } from "./Profiling/graphql";

const isPersonalInfoComplete = (user: Partial<User>): boolean => {
  const { profile = null } = user;
  if (profile === null) return false;
  return true;
};

const useOnBoarding = () => {
  const { user, isPro: userIsPro } = useUser();
  const { data: { user: userData } = {}, loading: isPersonalInfoLoading } =
    useQuery(GetUserPersonalInfoDocument, {
      fetchPolicy: "no-cache",
      variables: { userId: user?.id ?? "" },
    });
  const { data: { isProfilingDone } = {}, loading: isProfilingDoneLoading } =
    useQuery(IsProfilingDoneDocument, {
      fetchPolicy: "no-cache",
    });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [shouldShowModal, setShouldShowModal] = useState(false);

  useEffect(() => {
    if (!user || !userData || isPersonalInfoLoading || isProfilingDoneLoading) {
      return;
    }

    const hasPersonalInfo = isPersonalInfoComplete(userData);
    const isProfilingComplete = hasPersonalInfo && isProfilingDone;
    if (!isProfilingComplete && userIsPro) {
      if (
        pathname !== AppRoute.Profiling &&
        pathname !== AppRoute.TermsAndConditions
      ) {
        setShouldShowModal(true);
      } else {
        setShouldShowModal(false);
      }
      return;
    }
    const redirectRoute = localStorage.getItem("RedirectRouteSignup") || null;
    localStorage.removeItem("RedirectRouteSignup");
    if (
      [AppRoute.Profiling, AppRoute.Base].some((path) => path === pathname) &&
      redirectRoute
    ) {
      navigate(redirectRoute ?? AppRoute.Base);
      return;
    }

    if (pathname === AppRoute.Profiling && redirectRoute) {
      navigate(redirectRoute ?? AppRoute.Base);
      return;
    }
  }, [
    user,
    isPersonalInfoLoading,
    isProfilingDoneLoading,
    pathname,
    userData,
    isProfilingDone,
    shouldShowModal,
  ]);

  useEffect(() => {
    if (
      pathname === AppRoute.Profiling ||
      pathname === AppRoute.TermsAndConditions
    ) {
      setShouldShowModal(false);
    }
  }, [pathname]);

  const redirectToProfiling = () => {
    navigate(AppRoute.Profiling);
  };

  return { shouldShowModal, setShouldShowModal, redirectToProfiling };
};

export { useOnBoarding };
