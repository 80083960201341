import React, {
  HTMLInputAutoCompleteAttribute,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Input,
  InputProps,
  InputGroup,
  InputLeftElement,
  Divider,
  Spinner,
} from "@chakra-ui/react";
import { CountrySelector, usePhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useField } from "formik";
import { useUser } from "@/providers/useUser";

interface InputPhoneProps {
  errorText?: string;
  disabled?: boolean;
  defaultProps: InputProps;
  useLocation?: boolean;
  autocompleteValue?: HTMLInputAutoCompleteAttribute | undefined;
}

const InputPhone: React.FC<InputPhoneProps> = (props) => {
  const { disabled, useLocation, ...restProps } = props;

  const [field, , helpers] = useField(
    props.defaultProps?.name || "defaultName"
  );

  const { geolocation } = useUser();
  const [defaultCountry, setDefaultCountry] = useState("");
  const [isLocationLoaded, setIsLocationLoaded] = useState(false);

  const phoneInput = usePhoneInput({
    defaultCountry: defaultCountry || "co",
    value: field.value,
    onChange: (data) => {
      if (!disabled) {
        helpers.setValue(data.phone);
      }
    },
  });

  useEffect(() => {
    if (!useLocation) {
      setIsLocationLoaded(true);
      return;
    }

    if (geolocation?.country) {
      const countryCode = geolocation.country.toLowerCase();
      setDefaultCountry(countryCode);
      phoneInput.setCountry(countryCode);
    }

    setIsLocationLoaded(true);
  }, [geolocation?.country, useLocation]);

  /*
    DEV NOTE:Added to move the cursor after the country code when the input is focused
  */

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const input = event.target;
    const { value } = input;
    const countryCodeLength = phoneInput.country.dialCode.length;
    if (
      input.selectionStart === 0 &&
      value.startsWith(`+${phoneInput.country.dialCode}`)
    ) {
      input.setSelectionRange(countryCodeLength + 1, countryCodeLength + 1);
    }
  };

  return (
    <Box>
      <InputGroup zIndex={1}>
        <InputLeftElement display="flex" alignItems="center" h="100%">
          <Box
            display="flex"
            alignItems="center"
            h="100%"
            px={2}
            marginLeft={"20px"}
          >
            {!isLocationLoaded ? (
              <Spinner size="sm" color="gray.500" />
            ) : (
              <CountrySelector
                dropdownStyleProps={{
                  style: {
                    position: "absolute",
                    zIndex: 2,
                    background: "white",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    outline: "none",
                  },
                }}
                disabled={disabled}
                selectedCountry={phoneInput.country.iso2}
                onSelect={
                  disabled
                    ? () => {}
                    : (country) => phoneInput.setCountry(country.iso2)
                }
                renderButtonWrapper={({ children, rootProps }) => (
                  <Box marginLeft={"1px"}>
                    {disabled ? (
                      <Box
                        opacity={"0.4"}
                        cursor={"not-allowed"}
                        border={"none"}
                        px="2px"
                        py="7px"
                        h={"40px"}
                        ml={"4px"}
                      >
                        {children}
                      </Box>
                    ) : (
                      <Button
                        {...rootProps}
                        disabled={disabled}
                        variant="outline"
                        border={"none"}
                        px="4px"
                        py="2px"
                        borderRadius={"none"}
                        display={"block"}
                        borderRight={"none"}
                        _hover={{
                          background: "none",
                          boxShadow: "none",
                        }}
                      >
                        {children}
                      </Button>
                    )}
                  </Box>
                )}
              />
            )}
          </Box>
          <Divider mx={"5px"} h={"60%"} orientation="vertical" />
        </InputLeftElement>
        <Input
          pl="65px"
          pr="16px"
          borderRadius={"8px"}
          _hover={{
            border: "1px solid var(--primary-200, #37B3B4)",
            color: "var(--secondary-100, #69696C)",
            fontSize: "14px",
            boxShadow: "none",
            fontWeight: 400,
          }}
          _focus={{
            border: "2px solid var(--primary-500, #628ADF)",
            boxShadow: "none",
          }}
          _disabled={{
            border: "1px solid var(--neutral-300, #DADADC)",
            background: "var(--neutral-100, #F7F8F9)",
            color: "var(--secondary-50, #8F8F91)",
            fontSize: "14px",
            boxShadow: "none",
            fontWeight: 400,
          }}
          {...field}
          {...restProps.defaultProps}
          border={"1px solid #A29EA4"}
          disabled={disabled}
          value={phoneInput.inputValue}
          onChange={phoneInput.handlePhoneValueChange}
          onFocus={handleFocus}
          ref={phoneInput.inputRef}
          autoComplete={props.autocompleteValue}
        />
      </InputGroup>
    </Box>
  );
};

export default InputPhone;
