/* eslint-disable no-console */
import { useToast } from "@chakra-ui/react";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { config } from "@/config";
import { useUser } from "@/providers/useUser";

interface ErrorOptions {
  component?: string;
  title?: string;
  message: string;
  showInProd?: boolean;
}

class SBErrorEvent extends CustomEvent<ErrorOptions> {
  static NAME = "SB:ErrorEvent";

  constructor(options: ErrorOptions) {
    super(SBErrorEvent.NAME, { detail: options });
  }
}

class SBErrorPubSub {
  static publish = (options: ErrorOptions): void => {
    window.dispatchEvent(new SBErrorEvent(options));
  };

  static subscribe = (listener: (errorEvent: SBErrorEvent) => void) => {
    const subscription = (event: Event) => {
      if (event instanceof SBErrorEvent) {
        listener(event);
      }
    };
    window.addEventListener(SBErrorEvent.NAME, subscription);
    return subscription;
  };

  static unsubscribe = (subscription: (event: Event) => void): void => {
    window.removeEventListener(SBErrorEvent.NAME, subscription);
  };
}

const useSBErrorPubSub = (): void => {
  const toast = useToast();
  const { t } = useTranslation();
  const { user } = useUser();
  useEffect(() => {
    const subscription = SBErrorPubSub.subscribe(({ detail }) => {
      datadogRum.addError(new Error(detail.message), {
        component: detail.component,
        userEmail: user?.email,
      });

      const isProd = config.PORTAL_URL.includes?.("miportal.smartbeemo");

      if (!isProd || detail.showInProd) {
        const at = detail.component ? ` at ${detail.component}` : "";
        console.error(`Error${at}: ${detail.message}`);

        if (
          detail.message === "Socket closed" ||
          detail.message.includes("only host can start screen share")
        ) {
          return;
        }

        toast({
          title: detail.title || "",
          description: t(detail.message),
          duration: 5000,
          isClosable: true,
          status: "error",
          variant: "solid",
        });
      }
    });

    return () => {
      SBErrorPubSub.unsubscribe(subscription);
    };
  }, []);
};

export { SBErrorPubSub, useSBErrorPubSub };
