import {
  Flex,
  Text,
  IconButton,
  useBreakpointValue,
  Image,
  Heading,
} from "@chakra-ui/react";
import { Ico } from "@/assets/icons";
import { useState } from "react";
import { useUser } from "@/providers/useUser";

const heroImage =
  "https://media.smartbeemo.com/compo-hero-freemium-1-izquierda.png";

const hero2Image =
  "https://media.smartbeemo.com/compo-hero-freemium-2-derecha.png";

const HeroBanner = () => {
  const { user } = useUser();
  const [isVisible, setIsVisible] = useState(!user);
  const isMobile = useBreakpointValue({ base: true, lg: false });

  if (!isVisible) return null;

  return (
    <Flex justifyContent={"center"} w="100%" mt={4}>
      <Flex
        position="relative"
        align="center"
        justify="space-between"
        w="100%"
        maxW={"1200px"}
      >
        <IconButton
          aria-label="Close hero"
          icon={<Ico.Close fontSize={isMobile ? "16px" : "24px"} />}
          position="absolute"
          right={2}
          top={0}
          onClick={() => setIsVisible(false)}
          backgroundColor={"transparent"}
          display={"none"}
        />
        {!isMobile && (
          <Flex
            w="25%"
            h="200px"
            justifyContent={"flex-start"}
            paddingX={"10px"}
          >
            <Image
              boxSize={"18px"}
              src={heroImage}
              width={"230px"}
              height={"auto"}
            />
          </Flex>
        )}
        <Flex
          direction={"column"}
          w={isMobile ? "100%" : "50%"}
          justify="center"
          align="center"
          p={isMobile ? "16px" : "24px"}
        >
          <Heading
            as={"h4"}
            variant={"bold"}
            fontSize={{ base: "24px", lg: "40px" }}
            my={"7px"}
            textAlign={"center"}
            lineHeight={{ base: "32px", lg: "50px" }}
          >
            Lanza tu tienda de{" "}
            <Text
              as="h4"
              variant={"bold"}
              fontSize={{ base: "24px", lg: "40px" }}
              lineHeight={{ base: "32px", lg: "50px" }}
              color="primary.500"
              display="inline"
              fontFamily={"Poppins"}
            >
              Ecommerce
            </Text>{" "}
            y hazla crecer
          </Heading>
          <Text textAlign={"center"} fontSize={{ base: "14px", lg: "16px" }}>
            Desde educación y comunidad, a soluciones e ideas de productos
            ganadores, construye tu negocio de ecommerce en un solo lugar.
          </Text>
        </Flex>
        {!isMobile && (
          <Flex w="25%" h="200px" justifyContent={"flex-end"} paddingX={"10px"}>
            <Image
              boxSize={"18px"}
              src={hero2Image}
              width={"230px"}
              height={"auto"}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default HeroBanner;
