/* eslint-disable no-console */

import { AppRoute } from "@/AppRoute";
import { Ico } from "@/assets/icons";
import { colors } from "@/components/Theme/colors";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ProPlanCardProps {
  price: string;
  currency: string;
  showFreePlanButton: boolean;
  isUserRegistered: boolean;
  isMobile: boolean;
  onFreePlanClick: () => void;
}

export const ProPlanCard = ({
  price,
  currency,
  showFreePlanButton,
  isUserRegistered,
  isMobile,
  onFreePlanClick,
}: ProPlanCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getProButton = async () => {
    if (showFreePlanButton) {
      try {
        await onFreePlanClick?.();
        navigate(AppRoute.UpsellAndCross);
      } catch (error) {
        console.error("Error en onFreePlanClick:", error);
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <Flex
      border={"solid 1px"}
      borderColor={colors.primary[200]}
      borderRadius={"6px"}
      padding={"16px 16px 24px 16px"}
    >
      <Flex flexDirection={"column"} gap={6}>
        <Flex flexDirection={"column"} gap={4}>
          <Flex gap={2} flexDirection={"column"} w={"100%"}>
            <Flex alignItems={"center"} gap={"2px"}>
              <Ico.Diamond color={colors.primary[300]} fontSize={"20px"} />
              <Text
                fontFamily="Poppins"
                fontWeight="600"
                fontSize="18px"
                sx={{
                  background:
                    "linear-gradient(90deg, #427AFF 0%, #1F58F3 49.63%, #0B34AE 99.99%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                PRO
              </Text>
            </Flex>

            <Text
              fontFamily={"Inter"}
              fontWeight={400}
              fontSize={"12px"}
              color={"secondary.200"}
              w={showFreePlanButton ? "316px" : "288px"}
            >
              Más allá de las Sesiones en Vivo, aprovecha todo el ecosistema de
              contenido diseñado para guiarte desde el lanzamiento hasta el
              éxito de tu negocio online.
            </Text>
          </Flex>
          <Flex flexDirection={"column"}>
            <Flex gap={"6px"} alignItems={"baseline"}>
              <Text
                fontFamily={"Poppins"}
                fontWeight={600}
                fontSize={"40px"}
                lineHeight={0.9}
              >
                {price}
              </Text>
              <Flex>
                <Text fontFamily={"Poppins"} fontWeight={600} fontSize={"16px"}>
                  {currency}
                </Text>
                <Text color={"secondary.100"}>/mes</Text>
              </Flex>
            </Flex>
            <Text
              fontFamily={"Inter"}
              fontWeight={400}
              fontStyle={"italic"}
              fontSize={"10px"}
              color={"secondary.200"}
            >
              Más matrícula de inscripción
            </Text>
          </Flex>

          <Flex flexDirection={"column"} gap={4}>
            <Flex gap={2} flexDirection={"column"}>
              <Text fontFamily={"Poppins"} fontWeight={600} fontSize={"16px"}>
                ¿Qué incluye?
              </Text>
              <Flex gap={4} flexDirection={"column"}>
                <Flex gap={1} flexDirection={"column"}>
                  <Text fontFamily={"Inter"} fontWeight={600} fontSize={"14px"}>
                    En Vivo
                  </Text>
                  <Flex gap={1}>
                    <Ico.Check fontSize={"20px"} color={colors.success[300]} />
                    <Text
                      fontFamily={"Inter"}
                      fontWeight={400}
                      fontSize={"12px"}
                      color={"secondary.300"}
                      w={showFreePlanButton ? "316px" : "288px"}
                    >
                      Sesiones en Vivo.
                    </Text>
                  </Flex>
                  <Flex gap={1}>
                    <Ico.Check fontSize={"20px"} color={colors.success[300]} />
                    <Text
                      fontFamily={"Inter"}
                      fontWeight={400}
                      fontSize={"12px"}
                      color={"secondary.300"}
                      w={showFreePlanButton ? "316px" : "288px"}
                    >
                      Participación en chat.
                    </Text>
                  </Flex>
                  <Flex gap={1}>
                    <Ico.Check fontSize={"20px"} color={colors.success[300]} />
                    <Text
                      fontFamily={"Inter"}
                      fontWeight={400}
                      fontSize={"12px"}
                      color={"secondary.300"}
                      w={showFreePlanButton ? "316px" : "288px"}
                    >
                      Preguntas y Respuestas.
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap={1} flexDirection={"column"}>
                  <Text fontFamily={"Inter"} fontWeight={600} fontSize={"14px"}>
                    Programas online
                  </Text>
                  <Flex gap={1}>
                    <Ico.Check fontSize={"20px"} color={colors.success[300]} />
                    <Text
                      fontFamily={"Inter"}
                      fontWeight={400}
                      fontSize={"12px"}
                      color={"secondary.300"}
                      w={showFreePlanButton ? "316px" : "288px"}
                    >
                      Diplomados
                    </Text>
                  </Flex>
                  <Flex gap={1}>
                    <Ico.Check fontSize={"20px"} color={colors.success[300]} />
                    <Text
                      fontFamily={"Inter"}
                      fontWeight={400}
                      fontSize={"12px"}
                      color={"secondary.300"}
                      w={showFreePlanButton ? "316px" : "288px"}
                    >
                      Especializaciones
                    </Text>
                  </Flex>
                  <Flex gap={1}>
                    <Ico.Check fontSize={"20px"} color={colors.success[300]} />
                    <Text
                      fontFamily={"Inter"}
                      fontWeight={400}
                      fontSize={"12px"}
                      color={"secondary.300"}
                      w={showFreePlanButton ? "316px" : "288px"}
                    >
                      Cursos
                    </Text>
                  </Flex>
                  <Flex gap={1}>
                    <Ico.Check fontSize={"20px"} color={colors.success[300]} />
                    <Text
                      fontFamily={"Inter"}
                      fontWeight={400}
                      fontSize={"12px"}
                      color={"secondary.300"}
                      w={showFreePlanButton ? "316px" : "288px"}
                    >
                      Chat en vivo con Tutores Académicos
                    </Text>
                  </Flex>
                  <Flex gap={1}>
                    <Ico.Check fontSize={"20px"} color={colors.success[300]} />
                    <Text
                      fontFamily={"Inter"}
                      fontWeight={400}
                      fontSize={"12px"}
                      color={"secondary.300"}
                      w={showFreePlanButton ? "316px" : "288px"}
                    >
                      <Text
                        fontFamily={"Inter"}
                        fontWeight={600}
                        fontSize={"12px"}
                        color={"secondary.300"}
                        as={"span"}
                      >
                        40% OFF
                      </Text>{" "}
                      en Mentorías
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap={1} flexDirection={"column"}>
                  <Text fontFamily={"Inter"} fontWeight={600} fontSize={"14px"}>
                    Comunidad
                  </Text>

                  <Flex gap={1}>
                    <Ico.Check fontSize={"20px"} color={colors.success[300]} />
                    <Text
                      fontFamily={"Inter"}
                      fontWeight={400}
                      fontSize={"12px"}
                      color={"secondary.300"}
                      w={showFreePlanButton ? "316px" : "288px"}
                    >
                      Comunidades de WhatsApp
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap={1} flexDirection={"column"}>
                  <Text fontFamily={"Inter"} fontWeight={600} fontSize={"14px"}>
                    Productos
                  </Text>
                  <Flex gap={1}>
                    <Ico.Check fontSize={"20px"} color={colors.success[300]} />
                    <Text
                      fontFamily={"Inter"}
                      fontWeight={400}
                      fontSize={"12px"}
                      color={"secondary.300"}
                      w={showFreePlanButton ? "316px" : "288px"}
                    >
                      Destape semanal de ideas de productos
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap={1} flexDirection={"column"}>
                  <Text fontFamily={"Inter"} fontWeight={600} fontSize={"14px"}>
                    Beneficios
                  </Text>
                  <Flex gap={1}>
                    <Ico.Check fontSize={"20px"} color={colors.success[300]} />
                    <Text
                      fontFamily={"Inter"}
                      fontWeight={400}
                      fontSize={"12px"}
                      color={"secondary.300"}
                      w={showFreePlanButton ? "316px" : "288px"}
                    >
                      Catálogo de herramientas con beneficios y soluciones de
                      tecnología, marketing, diseño, finanzas y mucho más.
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Button
            onClick={getProButton}
            variant={"primary"}
            bgGradient={
              isUserRegistered
                ? "linear-gradient(90deg, #427AFF 0%, #1F58F3 49.63%, #0B34AE 99.99%)"
                : undefined
            }
            bg={!isUserRegistered ? "white" : undefined}
            w={isMobile || showFreePlanButton ? "100%" : "max-content"}
            minWidth={showFreePlanButton ? "316px" : "288px"}
            margin="0 auto"
            border={"none"}
            paddingRight={4}
            _hover={isUserRegistered ? "inherit" : "none"}
            _active={isUserRegistered ? "inherit" : "none"}
            paddingLeft={4}
            fontSize={"14px"}
            fontFamily={"Inter"}
            color={!isUserRegistered ? `${colors.secondary[400]}` : "white"}
          >
            {isUserRegistered ? t("Suscríbete a") : t("smartBeemo")}
            <Box
              marginLeft={2}
              as="span"
              bgGradient={
                !isUserRegistered
                  ? "linear-gradient(90deg, #427AFF 0%, #1F58F3 49.63%, #0B34AE 99.99%)"
                  : undefined
              }
              bg={isUserRegistered ? "white" : undefined}
              color={isUserRegistered ? "blue.600" : "white"}
              fontWeight="bold"
              px={2}
              borderRadius="md"
              fontSize={"12px"}
            >
              PRO
            </Box>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
