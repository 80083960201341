import { useState, useEffect, useContext } from "react";
import ZoomClientContext from "../context/zoom-context";
import { Text } from "@chakra-ui/react";

const ParticipantsCount = () => {
  const { zmClient } = useContext(ZoomClientContext);
  const [participantCount, setParticipantCount] = useState(
    zmClient ? zmClient.getAllUser().length : 0
  );

  useEffect(() => {
    if (!zmClient) return;

    const updateParticipantCount = () => {
      setParticipantCount(zmClient.getAllUser().length);
    };

    zmClient.on("user-added", updateParticipantCount);
    zmClient.on("user-removed", updateParticipantCount);

    return () => {
      zmClient.off("user-added", updateParticipantCount);
      zmClient.off("user-removed", updateParticipantCount);
    };
  }, [zmClient]);

  return (
    <Text color="neutral.200" fontSize="14px">
      {participantCount} Participantes
    </Text>
  );
};

export default ParticipantsCount;
