import { useTranslation } from "react-i18next";
import { SelectOption } from "@/components/Forms/Select/types";
import { object, string } from "yup";
import { EntityName, UpdateHighTicketMentorshipInput } from "@/schemaTypes";

const useValidation = () => {
  const { t } = useTranslation();

  return object({
    title: string().required(t("El campo es requerido")),
    agency: string().required(t("El campo es requerido")),
    categoryId: string().required(t("El campo es requerido")),
    subcategoryId: string().required(t("El campo es requerido")),
    subtitle: string().required(t("El campo es requerido")),
    description: string().required(t("El campo es requerido")),
    agencyImage: string().required(t("El campo es requerido")),
  });
};

export const categoryTypeOptions: SelectOption[] = [
  { name: "MAIN", value: "MAIN" },
  { name: "SUB", value: "SUB" },
];

export const mentorshipTypeMap: Partial<Record<EntityName, string>> = {
  [EntityName.VipMentorship]: "Mentoría VIP",
  [EntityName.EliteMentorship]: "Mentoría Élite",
};

export const getEntityNameByValue = (value: string): EntityName | undefined => {
  return Object.entries(mentorshipTypeMap).find(
    ([, val]) => val === value
  )?.[0] as EntityName | undefined;
};

export const getInitialValues = (
  selectedMentorship: UpdateHighTicketMentorshipInput | null,
  parentIdForCopyValidation: string
) => {
  return {
    title: selectedMentorship?.title || "",
    description: selectedMentorship?.description || "",
    mentorId: selectedMentorship?.mentor?.userId,
    mentorTitle: selectedMentorship?.mentor?.title,
    mentorDescription: selectedMentorship?.mentor?.description,
    categoryId: selectedMentorship?.categoryId || "",
    mentorPreviousExperience: selectedMentorship?.mentor
      ?.previousExperience || [""],
    linkedInLink:
      selectedMentorship?.mentor?.socialNetworks?.linkedInLink || "",
    instagramLink:
      selectedMentorship?.mentor?.socialNetworks?.instagramLink || "",
    facebookLink:
      selectedMentorship?.mentor?.socialNetworks?.facebookLink || "",
    email: selectedMentorship?.mentor?.contactInfo?.email || "",
    phoneNumber: selectedMentorship?.mentor?.contactInfo?.phoneNumber || "",
    logo: selectedMentorship?.logo || "",
    duration: selectedMentorship?.duration || "",
    qrCodeLink: selectedMentorship?.qrCodeLink || "",
    whatsAppLink: selectedMentorship?.whatsAppLink || "",
    privateGroup: selectedMentorship?.extraBenefits?.privateGroup || "",
    vipGroup: selectedMentorship?.extraBenefits?.vipGroup || "",
    generalClasses: selectedMentorship?.extraBenefits?.generalClasses || "",
    exclusiveSupport: selectedMentorship?.extraBenefits?.exclusiveSupport || "",
    weHopeToTeachYou: selectedMentorship?.extraBenefits?.weHopeToTeachYou || "",
    type: selectedMentorship?.type || "",
    parentId: parentIdForCopyValidation,
    startDate: selectedMentorship?.startDate || null,
    endDate: selectedMentorship?.endDate || null,
    brandIds: selectedMentorship?.brandIds || [""],
    levelIds: selectedMentorship?.levelIds || [""],
    subCategoryIds: selectedMentorship?.subCategoryIds || [""],
    order: selectedMentorship?.order || 0,
    isPublished: selectedMentorship?.isPublished || false,
    landingUrl: selectedMentorship?.landingUrl || "",
    thumbnail: selectedMentorship?.thumbnail || "",
  };
};

export default useValidation;
