import { AppRoute, PublicRoute } from "@/AppRoute";
import { useUser } from "@/providers/useUser";
import { Box, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { colors } from "../Theme/colors";
import { useCustomNavigation } from "@/providers/Navigation/useCustomNavigation";
import { EntityName } from "@/schemaTypes";

export const UpgradeToPro = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, isUserRegistered } = useUser();
  const { configUpsellRedirect } = useCustomNavigation();
  return (
    <Button
      onClick={() => {
        configUpsellRedirect("CTA Navbar" as EntityName, "CTA Navbar", null);
        navigate(!user ? PublicRoute.SignUp : AppRoute.UpsellAndCross);
      }}
      variant={"primary"}
      bgGradient={
        isUserRegistered
          ? "linear-gradient(90deg, #427AFF 0%, #1F58F3 49.63%, #0B34AE 99.99%)"
          : undefined
      }
      bg={!isUserRegistered ? "white" : undefined}
      width={{ base: "100%", md: "162px", lg: "162px" }}
      border={"none"}
      paddingRight={4}
      _hover={isUserRegistered ? "inherit" : "none"}
      _active={isUserRegistered ? "inherit" : "none"}
      paddingLeft={4}
      fontSize={"14px"}
      fontFamily={"Inter"}
      maxH={8}
      color={!isUserRegistered ? `${colors.secondary[400]}` : "white"}
    >
      {isUserRegistered ? t("Upgrade to") : t("smartBeemo")}
      <Box
        marginLeft={2}
        as="span"
        bgGradient={
          !isUserRegistered
            ? "linear-gradient(90deg, #427AFF 0%, #1F58F3 49.63%, #0B34AE 99.99%)"
            : undefined
        }
        bg={isUserRegistered ? "white" : undefined}
        color={isUserRegistered ? "blue.600" : "white"}
        fontWeight="bold"
        px={2}
        borderRadius="md"
        fontSize={"12px"}
      >
        PRO
      </Box>
    </Button>
  );
};
