import { Flex, Heading, Image, Tag, Text } from "@chakra-ui/react";
import { EntityName } from "@/schemaTypes";
import { Ico } from "@/assets/icons";
import { parseLiveSessionsDate } from "../../LiveClasses/utils";
import { useUser } from "@/providers/useUser";
import { useState } from "react";
import { MentorshipCard as MentorshipCardType } from "../providers/types";
import { useCustomNavigation } from "@/providers/Navigation/useCustomNavigation";
import { AppRoute, PublicRoute } from "@/AppRoute";
import { useNavigate } from "react-router-dom";

const MentorshipCard = ({ mentorship }: { mentorship: MentorshipCardType }) => {
  const [isActive, setIsActive] = useState(false);
  const { geolocation } = useUser();
  const navigate = useNavigate();
  const { user } = useUser();
  const timezone = geolocation.location;
  const { configUpsellRedirect } = useCustomNavigation();
  const mentorshipParsedDate = parseLiveSessionsDate(
    mentorship?.startDate ?? new Date(),
    timezone,
    true,
    "short"
  );

  const handleMouseDown = () => setIsActive(true);
  const handleMouseUp = () => setIsActive(false);

  const borderColors = {
    default:
      mentorship?.type === EntityName.VipMentorship ? "primary.200" : "#00A99E",
    hover:
      mentorship?.type === EntityName.VipMentorship ? "primary.100" : "#18C6BA",
    pressed:
      mentorship?.type === EntityName.VipMentorship ? "primary.500" : "#049A90",
  };

  return (
    <Flex
      userSelect={"none"}
      as={"section"}
      flexDir={"column"}
      minW={"218px"}
      maxW={"218px"}
      gap={"8px"}
    >
      <Flex
        h={"320px"}
        bg={"#000"}
        border={"4px solid"}
        borderRadius={"6px"}
        cursor={"pointer"}
        position={"relative"}
        flexDir={"column"}
        transition={"border-color 0.2s"}
        onClick={() => {
          configUpsellRedirect(
            mentorship.type as EntityName,
            mentorship.title,
            AppRoute.Base
          );
          if (user) {
            window.open(mentorship?.landingUrl, "_blank");
          } else {
            navigate(PublicRoute.SignUp);
          }
        }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
        onMouseLeave={() => setIsActive(false)}
        borderColor={isActive ? borderColors.pressed : borderColors.default}
        _hover={{
          borderColor: isActive ? borderColors.pressed : borderColors.hover,
        }}
      >
        <Flex
          h={"100%"}
          w={"100%"}
          bg={
            "linear-gradient(180deg, rgba(30, 30, 35, 0.10) 50%, rgba(30, 30, 35, 0.80) 80.65%, #1E1E23 100%)"
          }
          position={"absolute"}
          top={0}
          borderBottomRadius={"6px"}
        />
        <Heading
          textTransform={"uppercase"}
          color={"#fff"}
          mx={"16px"}
          my={"4px"}
          fontSize={"12px"}
        >
          {mentorship?.type === EntityName.VipMentorship
            ? "Mentoría VIP"
            : "Mentoría Élite"}
        </Heading>
        <Image
          h={"288px"}
          src={mentorship?.thumbnail ?? ""}
          objectFit={"cover"}
          borderBottomRadius={"6px"}
        />
        <Flex
          m={"16px"}
          bottom={0}
          color={"#fff"}
          flexDir={"column"}
          position={"absolute"}
        >
          <Ico.YourMentor fontSize={"24px"} fill="#fff" />
          <Text variant={"caption"} my={"4px"} lineHeight={"1.4"}>
            {mentorship?.title}
          </Text>
          <Text variant={"feedback"} textTransform={"capitalize"}>
            {mentorship?.startDate &&
              mentorship.type === EntityName.EliteMentorship &&
              `Inicio: ${mentorshipParsedDate.format}`}

            {!mentorship?.startDate &&
              mentorship?.type === EntityName.VipMentorship &&
              "Inicio: Permanente"}
          </Text>
          <Text variant={"feedback"}>Mentor: {mentorship?.mentor?.name}</Text>
        </Flex>
      </Flex>
      <Flex
        as={"footer"}
        maxW={"100%"}
        flexDir={"row"}
        flexWrap={"wrap"}
        gap={"8px"}
      >
        <Tag maxW={"max-content"} px={"8px"} py={"2px"}>
          <Text variant={"feedback"} color={"neutral.500"}>
            {mentorship?.brand[0]?.name}
          </Text>
        </Tag>
        <Tag maxW={"max-content"} px={"8px"} py={"2px"}>
          <Text variant={"feedback"} color={"neutral.500"}>
            {mentorship?.subCategory[0]?.name}
          </Text>
        </Tag>
        <Tag maxW={"max-content"} px={"8px"} py={"2px"}>
          <Text variant={"feedback"} color={"neutral.500"}>
            {mentorship?.level[0]?.name}
          </Text>
        </Tag>
      </Flex>
    </Flex>
  );
};

export default MentorshipCard;
