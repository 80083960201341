/* eslint-disable no-underscore-dangle */
import { Flex, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  GetProgramByIdDocument,
  GetProgramByIdQuery,
} from "@/pages/academy/Programs/graphql/getProgramById.generated";
import { useParams } from "react-router";
import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GetCohortByIdDocument } from "@/pages/Cohorts/graphql/getCohortById.generated";
import { ProgramBanner } from "@/components/Programs/ProgramBanner";
import { ProgramResume } from "@/components/Programs/ProgramResume";
import { ProgramContent } from "@/components/Programs/ProgramContent";
import { GetAllDataProgramByIdDocument } from "./graphql/getAllProgramDataById.generated";
import { noCacheHeaders } from "@/utils/headers";
import { Mentor, ProgramMentors } from "@/components/Programs/ProgramMentors";
import { useCustomNavigation } from "@/providers/Navigation/useCustomNavigation";
import { EntityName } from "@/schemaTypes";

export const ProgramLandingPage = () => {
  const { cohortId, programId } = useParams<"programId" | "cohortId">();
  const { t } = useTranslation();
  const { updateProductOfInterest } = useCustomNavigation();
  const [fetchProgram, { data, loading }] = useLazyQuery<GetProgramByIdQuery>(
    GetProgramByIdDocument,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [fetchProgramData, { data: programData, loading: loadingData }] =
    useLazyQuery(GetAllDataProgramByIdDocument, {
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    });

  const [fetchCohort, { data: cohortData, loading: loadingCohort }] =
    useLazyQuery(GetCohortByIdDocument, {
      fetchPolicy: "no-cache",
    });
  useEffect(() => {
    if (programId) {
      fetchProgram({ variables: { programId } });
    }
  }, [programId]);
  useEffect(() => {
    if (cohortId) {
      fetchProgramData({
        variables: {
          getAllDataProgramInput: { cohortId: cohortId },
        },
      });
      fetchCohort({
        variables: {
          cohortId: cohortId ?? "",
          courseId: "",
          includeCourseName: false,
        },
      });
    }
  }, [cohortId]);
  useEffect(() => {
    if (data?.program) {
      updateProductOfInterest(
        data?.program?.programType as EntityName,
        data?.program.name
      );
    } else {
      if (programData?.getAllProgramDataById[0].course.courseType) {
        updateProductOfInterest(
          programData?.getAllProgramDataById[0].course.courseType as EntityName,
          programData?.getAllProgramDataById[0].course.code
        );
      }
    }
  }, [data, programData]);

  if (loading && loadingData && loadingCohort)
    return <Spinner alignSelf={"center"} />;
  return (
    <Flex flexDir={"column"} gap={8}>
      <ProgramBanner
        thumbnailUrl={
          data?.program?.thumbnailUrl ??
          programData?.getAllProgramDataById[0].course.imageUrl
        }
        videoUrl={cohortData?.cohort?.video?.[0]?.link as string}
        quantProgramHours={cohortData?.cohort?.cant_hours_program as string}
        description={cohortData?.cohort?.description_short?.[0]?.value ?? ""}
        programType={
          data?.program?.programType
            ? t(`${data.program.programType}`)
            : t(`${programData?.getAllProgramDataById[0].course.courseType}`)
        }
        sessionId={programData?.getAllProgramDataById[0].sessions[0]._doc._id}
        programName={
          data?.program?.name ??
          programData?.getAllProgramDataById[0].course.code
        }
      />
      <Flex justifyContent={"center"}>
        <ProgramResume
          largeDescription={cohortData?.cohort?.description?.[0]?.value ?? ""}
          youWillLearn={cohortData?.cohort?.you_will_learn as string[]}
          thumbnailUrl={cohortData?.cohort?.video?.[0]?.thumbnail ?? ""}
          programType={
            data?.program?.programType
              ? t(`${data.program.programType}`)
              : t(`${programData?.getAllProgramDataById[0].course.courseType}`)
          }
          programName={
            data?.program?.name ??
            programData?.getAllProgramDataById[0].course.code
          }
        />
      </Flex>
      <Flex justifyContent={"center"}>
        <ProgramContent
          programData={programData}
          cohortData={cohortData}
          programType={
            data?.program?.programType
              ? t(`${data.program.programType}`)
              : t(`${programData?.getAllProgramDataById[0].course.courseType}`)
          }
        />
      </Flex>
      {cohortData?.cohort?.mentors?.length && (
        <Flex justifyContent={"center"} pt={2}>
          <ProgramMentors mentors={cohortData?.cohort?.mentors as Mentor[]} />
        </Flex>
      )}
    </Flex>
  );
};
