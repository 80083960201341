import PricingTable from "@/pages/UpsellLanding/PricingTable";
import { useCustomNavigation } from "@/providers/Navigation/useCustomNavigation";
import { useUser } from "@/providers/useUser";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useCallback, useState } from "react";

export const PriceChartModal: React.FC = () => {
  const { getShowPriceChart, setShowPriceChart } = useCustomNavigation();
  const { user } = useUser();

  const [priceModalOpen, setPriceModalOpen] = useState(false);
  const isMobile = useBreakpointValue({ base: true, lg: false });

  useEffect(() => {
    const shouldShow = user && getShowPriceChart();
    setPriceModalOpen(!!shouldShow);
  }, [getShowPriceChart, user]);

  const closePriceModal = useCallback(() => {
    setPriceModalOpen(false);
    setShowPriceChart(false);
  }, [setShowPriceChart]);

  return (
    <Modal
      isOpen={priceModalOpen}
      onClose={closePriceModal}
      closeOnOverlayClick
      closeOnEsc
      size={{ base: "xs", sm: "sm", lg: "4xl" }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxH={"100vh"} my={isMobile ? 4 : 0} p={isMobile ? 6 : 2}>
        <ModalCloseButton />
        <ModalBody
          p={isMobile ? 0 : 4}
          maxH={isMobile ? "80vh" : "100vh"}
          overflow="scroll"
        >
          <PricingTable
            showFreePlanButton={true}
            onFreePlanClick={closePriceModal}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
