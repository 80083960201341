import { Flex, Text, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { colors } from "@/components/Theme/colors";
import { Ico } from "@/assets/icons";

interface FreePlanCardProps {
  isMobile: boolean;
  showFreePlanButton: boolean;
  onFreePlanClick?: () => void;
}

const FreePlanCard: FC<FreePlanCardProps> = ({
  isMobile,
  showFreePlanButton,
  onFreePlanClick,
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      border={"solid 1px"}
      borderColor={colors.neutral[100]}
      borderRadius={"6px"}
      padding={showFreePlanButton ? "16px" : "16px 16px 24px 16px"}
      maxHeight={isMobile ? "316px" : "unset"}
    >
      <Flex flexDirection={"column"} gap={6}>
        <Flex gap={4} flexDirection={"column"} w={"100%"}>
          <Flex gap={2} flexDirection={"column"}>
            <Text fontFamily={"Poppins"} fontWeight={600} fontSize={"18px"}>
              Gratis
            </Text>
            <Text
              fontFamily={"Inter"}
              fontWeight={400}
              fontSize={"12px"}
              color={"secondary.200"}
              w={showFreePlanButton ? "316px" : "288px"}
            >
              Explora lo esencial de smartBeemo, accede a lecciones
              introductorias y aprovecha contenido gratuito para dar tus
              primeros paso en ecommerce.
            </Text>
          </Flex>
          <Flex gap={1} flexDirection={"column"}>
            <Text fontFamily={"Inter"} fontWeight={600} fontSize={"14px"}>
              En Vivo
            </Text>
            <Flex gap={1}>
              <Ico.Check fontSize={"20px"} color={colors.success[300]} />
              <Text
                fontFamily={"Inter"}
                fontWeight={400}
                fontSize={"12px"}
                color={"secondary.300"}
                w={showFreePlanButton ? "316px" : "288px"}
              >
                Sesiones en Vivo.
              </Text>
            </Flex>
          </Flex>
          <Flex gap={1} flexDirection={"column"}>
            <Text fontFamily={"Inter"} fontWeight={600} fontSize={"14px"}>
              Beneficios
            </Text>
            <Flex gap={1}>
              <Ico.Check fontSize={"20px"} color={colors.success[300]} />
              <Text
                fontFamily={"Inter"}
                fontWeight={400}
                fontSize={"12px"}
                color={"secondary.300"}
                w={showFreePlanButton ? "316px" : "288px"}
              >
                Catálogo de herramientas con beneficios y soluciones de
                tecnología, marketing, diseño, finanzas y mucho más.
              </Text>
            </Flex>
          </Flex>
          {showFreePlanButton && (
            <Button
              onClick={onFreePlanClick}
              variant={"secondary"}
              bg={colors.neutral[50]}
              color={colors.primary[300]}
              w={"100%"}
              minWidth={"316px"}
              margin="8px auto"
              border={"none"}
              paddingRight={4}
              _hover={"none"}
              _active={"none"}
              _focus={"none"}
              paddingLeft={4}
              fontSize={"14px"}
              fontFamily={"Inter"}
            >
              {t("Continuar Gratis")}
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FreePlanCard;
