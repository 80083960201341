function validateComponentVisibility(proUpdatedAt: string, isBanner: boolean) {
  const proSubscription = new Date(proUpdatedAt);
  const startDate = new Date(proSubscription);
  const endDate = new Date(proSubscription);
  const currentDate = new Date();

  if (isBanner) {
    startDate.setHours(startDate.getHours() + 0);
  }

  endDate.setDate(endDate.getDate() + 8);
  return currentDate >= startDate && currentDate <= endDate;
}
export default validateComponentVisibility;
