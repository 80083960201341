import { useContext, useEffect, useState } from "react";
import ZoomClientContext from "../context/zoom-context";
import { useLazyQuery } from "@apollo/client";
import { GetJwtAuthLiveSessionDocument } from "@/components/LiveSessionsZoom/graphql/getJwtAuthLiveSession.generated";
import { useParams } from "react-router-dom";
import { LiveSessionStatus } from "@/schemaTypes";
import { useUser } from "@/providers/useUser";
import { useToast } from "@chakra-ui/react";
import { decodeBase64 } from "@/components/LiveSessions/utils/base64";

export const useConnectedClient = () => {
  const { user } = useUser();
  const toast = useToast();
  const {
    liveSessionId,
    name: userName,
    userId,
  } = useParams<{
    liveSessionId: string;
    name: string;
    userId: string;
  }>();
  const [
    GetJwtAuthLiveSession,
    { data: dataAuthJWT, loading: loadingAuth, refetch },
  ] = useLazyQuery(GetJwtAuthLiveSessionDocument);
  const { clientState, initZoomClient, zmClient, dispatch } =
    useContext(ZoomClientContext);
  const [stars, setStars] = useState<number | null>(null);

  useEffect(() => {
    if (!stars) {
      return;
    }
    toast({
      title: `Has ganado ${stars} estrellas por unirte a la sesión en vivo!`,
      position: "top",
      status: "success",
      variant: "subtle",
      duration: 4000,
      containerStyle: {
        borderRadius: "8px",
        border: "2px solid",
        borderColor: "success.300",
      },
    });
    setStars(0);
  }, [stars]);

  const zmStartAndJoin = async () => {
    if (!liveSessionId) return;

    try {
      const name = decodeBase64(userName as string).replace(/[-_]/g, " ");

      const variablesGraphql = {
        liveSessionsId: liveSessionId,
        liveStream: true,
        isPortal1: !!userName,
        dataPortal1Input: {
          userId: userId || "",
        },
      };

      const JWT_RESPONSE = await GetJwtAuthLiveSession({
        variables: variablesGraphql,
        fetchPolicy: "network-only",
      });

      const { data } = JWT_RESPONSE;

      if (
        !clientState?.isAuth &&
        initZoomClient &&
        data &&
        data.getJwtAuthLiveSession.session.status !== LiveSessionStatus.Ended
      ) {
        const { getJwtAuthLiveSession } = data;
        await initZoomClient({
          topic: getJwtAuthLiveSession.session.channelName,
          token: getJwtAuthLiveSession.jwt,
          username: userName
            ? name
            : (user?.name && `${user?.name} ${user?.lastName}`) ||
              user?.id ||
              "Anonymous",
        });
      }

      dispatch({
        type: "CONNECT_CLIENT",
        payload: {
          ...clientState,
          isAuth: true,
          token: data?.getJwtAuthLiveSession.jwt,
          topic: data?.getJwtAuthLiveSession.session.channelName,
          userName: "",
          zmClient: zmClient,
          error: undefined,
          dataSessionAuth: data?.getJwtAuthLiveSession,
        },
      });

      const responseGamification =
        data?.getJwtAuthLiveSession.responseGamification;
      const eventRecords = responseGamification?.eventRecords ?? [];

      if (eventRecords.length !== 1) {
        return;
      }
      const record = eventRecords[0];
      if (typeof record === "number") {
        return;
      }
      const dataStars = record?.awardedStars ?? 0;
      setStars((value) => (value === null ? dataStars : value));
    } catch (error) {
      dispatch({
        type: "DISCONNECTED_CLIENT",
        payload: {
          ...clientState,
          isAuth: false,
          token: undefined,
          topic: undefined,
          userName: undefined,
          zmClient: undefined,
          error: error,
        },
      });

      throw new Error(
        "An error occurred while trying to connect to the live class 1"
      );
    }
  };

  const disconnectedUser = async () => {
    if (zmClient) {
      await zmClient.leave();
      dispatch({
        type: "DISCONNECT_CLIENT",
        payload: {
          ...clientState,
          isAuth: false,
          token: undefined,
          topic: undefined,
          userName: undefined,
          zmClient: undefined,
          error: undefined,
        },
      });
    }
  };

  useEffect(() => {
    zmStartAndJoin();

    return () => {
      disconnectedUser();
    };
  }, [zmClient]);

  return {
    dataAuthJWT,
    loadingAuth,
    refetch,
  };
};
