import React from "react";
import { Avatar, Flex, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export interface Mentor {
  name_mentor: string;
  link_imagen: string;
  description: string;
  __typename: string;
}
interface ProgramMentorsProps {
  mentors: Mentor[];
}

export const ProgramMentors: React.FC<ProgramMentorsProps> = ({ mentors }) => {
  const { t } = useTranslation();
  return (
    <Flex
      flexDir={"column"}
      px={{ base: 4, lg: 0 }}
      w={"100%"}
      maxW={"944px"}
      pb={8}
      gap={4}
    >
      <Heading textAlign={"start"}>{t("Meet the mentors")}</Heading>
      <Flex
        gap={"24px"}
        flexDir={{ base: "column", md: "row" }}
        alignItems={"start"}
        wrap={"wrap"}
      >
        {mentors.map((mentor) => {
          return (
            <Flex
              key={mentor.name_mentor}
              gap={"24px"}
              w={{ base: "100%", md: "calc(33.33% - 16px)" }}
            >
              <Avatar
                boxSize={{ base: "64px", md: "72px" }}
                name="Oshigaki Kisame"
                src={mentor.link_imagen}
              />
              <Flex flexDir={"column"} gap={1}>
                <Text variant={"caption"}>{mentor.name_mentor}</Text>
                <Text
                  variant={"feedback"}
                  fontWeight={500}
                  lineHeight={"19.2px"}
                >
                  {mentor.description}
                </Text>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
