/* eslint-disable no-console */

import { config } from "@/config";
import { SyncABdataDocument } from "@/graphql/syncABdata.generated";
import { useChat } from "@/hooks/chatScript";
import useGA4Event from "@/hooks/useGA4Event";
import { useUser } from "@/providers/useUser";
import { useMutation } from "@apollo/client";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import React, { useEffect, useLayoutEffect, useState } from "react";

/**
 * Declare the types for the embeddedservice_bootstrap object
 * so that TypeScript is aware of their existence on the window.
 */
declare global {
  interface Window {
    embeddedservice_bootstrap?: {
      settings: {
        language: string;
      };
      prechatAPI: {
        setHiddenPrechatFields: (fields: Record<string, string>) => void;
      };
      init: (
        orgId: string,
        developerName: string,
        siteURL: string,
        options?: Record<string, unknown>
      ) => void;
    };
  }
}

/**
 * Custom hook to dynamically load a script in React.
 */
function useLoadScript(scriptUrl: string) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const scriptElement = document.createElement("script");
    scriptElement.src = scriptUrl;
    scriptElement.type = "text/javascript";

    // On success, mark as loaded
    scriptElement.onload = () => setLoaded(true);
    // On error, just log or handle the error
    scriptElement.onerror = () => {
      setLoaded(false);
    };

    document.body.appendChild(scriptElement);

    // Cleanup the script when component unmounts
    return () => {
      document.body.removeChild(scriptElement);
    };
  }, [scriptUrl]);

  return loaded;
}

const SalesforceChat: React.FC = () => {
  const [syncABdata] = useMutation(SyncABdataDocument);

  const abData = useFeatureIsOn("salesforce-chat");
  const analytics = useGA4Event();
  const { isPro, user } = useUser();

  const { start, stop, isChatInitialized } = useChat();

  useEffect(() => {
    if (!abData && user && isPro) {
      try {
        start();
      } catch (error) {
        console.error("Couldn't start chat v1. ", error);
      }
    } else {
      if (abData) {
        try {
          console.log("trying to hide old chat and only display new");
          stop();
        } catch (error) {
          console.error("Couldn't start chat v1. ", error);
        }
      }
    }
  }, [abData, user, isPro]);

  const scriptToLoad = config.NEW_SALESFORCE_CHAT_BOOTSTRAP;
  const serviceUrlToEmbed = config.NEW_SALESFORCE_CHAT_URI;
  const chatTarget = config.NEW_SALESFORCE_CHAT_TARGET_ENV;
  const scrtUrl = config.NEW_SALESFORCE_CHAT_SCRIPT_URI;
  const orgId = config.NEW_SALESFORCE_CHAT_ORG_ID;

  // Load external script
  const scriptLoaded = useLoadScript(scriptToLoad);

  useLayoutEffect(() => {
    analytics.setUserProperty({
      salesforceChatVersion: abData ? "New Chat" : "Old Chat",
    });

    syncABdata({
      variables: {
        syncABdataInput: { key: "salesforce-chat", value: abData },
      },
    });
  }, [abData]);

  useEffect(() => {
    if (!scriptLoaded || !isPro || !abData) return;
    if (!window.embeddedservice_bootstrap) {
      console.warn("embeddedservice_bootstrap is not available on window.");
      return;
    }

    try {
      // Set language
      window.embeddedservice_bootstrap.settings.language = "es";

      // Listen for the Embedded Messaging Ready event
      const onEmbeddedMessagingReady = () => {
        console.log("Received the onEmbeddedMessagingReady event...");
        window.embeddedservice_bootstrap?.prechatAPI.setHiddenPrechatFields({
          Service_openingDialog: "Dialog2",
          Portal_Id: user ? user.id : "67476dd049375023e5b58a36",
        });
        console.log(user ? user.id : "67476dd049375023e5b58a36");
      };

      window.addEventListener(
        "onEmbeddedMessagingReady",
        onEmbeddedMessagingReady
      );

      // Initialize the embedded service
      window.embeddedservice_bootstrap.init(
        orgId,
        chatTarget,
        serviceUrlToEmbed,
        { scrt2URL: scrtUrl }
      );

      return () => {
        window.removeEventListener(
          "onEmbeddedMessagingReady",
          onEmbeddedMessagingReady
        );
      };
    } catch (err) {
      console.error("Error loading Embedded Messaging: ", err);
    }
  }, [scriptLoaded, isPro, isChatInitialized]);

  return null;
};

export default SalesforceChat;
