import {
  Flex,
  IconButton,
  Text /*, useBreakpointValue */,
} from "@chakra-ui/react";
import { Ico } from "@/assets/icons";
import { InformationMenu } from "./informationMenu";
import { Fragment } from "react/jsx-runtime";
//import { SbPro } from "@/components/Button/SbPro";

interface Props {
  isMenuExpanded: boolean;
}

const socialMediaIcons = [
  {
    name: "facebook",
    icon: <Ico.Facebook />,
    url: "https://www.facebook.com/smartbeemo/",
  },
  {
    name: "instagram",
    icon: <Ico.Instagram />,
    url: "https://www.instagram.com/smartbeemo/",
  },
  {
    name: "linkedIn",
    icon: <Ico.LinkedIn />,
    url: "https://www.linkedin.com/company/smartbeemo/posts/?feedView=all",
  },
  {
    name: "youtube",
    icon: <Ico.YouTube />,
    url: "https://www.youtube.com/channel/UC1PjybXW1ZQ7hCkdiEggtfg",
  },
];

export const Footer = ({ isMenuExpanded }: Props) => {
  // DEV NOTE: This is a temporary solution to hide the SbPro button on mobile devices just until we have the external page done.
  //const isMobile = useBreakpointValue({ base: true, lg: false });
  return (
    <Fragment>
      {isMenuExpanded && (
        <Flex direction={"column"} justify={"center"}>
          {/* {isMobile && <SbPro />} */}
          <InformationMenu isMenuExpanded={isMenuExpanded} />
          <Flex my="16px" gap={"8px"}>
            {socialMediaIcons.map(({ name, icon, url }) => (
              <IconButton
                aria-label={name}
                as={"a"}
                fontSize={"24px"}
                href={url}
                icon={icon}
                key={name}
                target={"_blank"}
                variant={"link"}
                color={"secondary.300"}
                p={"4px"}
                onClick={() => {}}
              />
            ))}
          </Flex>
          <Text color={"secondary.300"} variant={"tiny"}>
            © 2023-2025 smartBeemo, LLC. Todos los derechos reservados.
          </Text>
        </Flex>
      )}
    </Fragment>
  );
};
