import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useField } from "formik";
import React, { useEffect, useRef } from "react";
import { Ico } from "@/assets/icons";
import { GetAllCountriesDocument } from "@/graphql/getAllCountries.generated";
import { useQuery } from "@apollo/client";
import countryTranslations from "../data/countryTranslations.json";
import { useUser } from "@/providers/useUser";

interface PropsType {
  placeholder?: string;
  errorText?: string;
  defaultProps: { name: string };
  disabled?: boolean;
  useLocation?: boolean;
}
const SelectCountry: React.FC<PropsType> = (props) => {
  const { disabled, placeholder, defaultProps, useLocation = false } = props;
  const [field, , helpers] = useField(defaultProps?.name || "defaultName");
  const menuListRef = useRef<HTMLUListElement>(null);

  const { geolocation } = useUser();
  const { data } = useQuery(GetAllCountriesDocument);
  const countries = data?.getAllCountries || [];

  useEffect(() => {
    if (useLocation && field.value === "" && geolocation.nameTranslated) {
      const translatedCountry =
        countryTranslations[
          geolocation.nameTranslated as keyof typeof countryTranslations
        ] || geolocation.nameTranslated;
      helpers.setValue(translatedCountry);
    }
  }, [useLocation, geolocation.nameTranslated, helpers, field.value]);

  useEffect(() => {
    const selectedIndex = countries.findIndex(
      (country) => country.name.toLowerCase() === field.value.toLowerCase()
    );

    if (selectedIndex !== -1 && menuListRef.current) {
      const selectedItem = menuListRef.current.children[
        selectedIndex
      ] as HTMLLIElement;
      if (selectedItem) {
        selectedItem.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [field.value, countries]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (menuListRef.current) {
      const items = Array.from(menuListRef.current.children);
      let currentFocusedIndex = items.findIndex(
        (item) => (item as HTMLElement).tabIndex === 0
      );

      if (event.key === "ArrowDown") {
        const nextIndex =
          currentFocusedIndex === items.length - 1
            ? 0
            : currentFocusedIndex + 1;
        const nextItem = items[nextIndex] as HTMLLIElement;
        nextItem.tabIndex = 0;
        nextItem.focus();
        nextItem.scrollIntoView({ behavior: "smooth", block: "nearest" });
        currentFocusedIndex = nextIndex;
      } else if (event.key === "ArrowUp") {
        const prevIndex =
          currentFocusedIndex === 0
            ? items.length - 1
            : currentFocusedIndex - 1;
        const prevItem = items[prevIndex] as HTMLLIElement;
        prevItem.tabIndex = 0;
        prevItem.focus();
        prevItem.scrollIntoView({ behavior: "smooth", block: "nearest" });
        currentFocusedIndex = prevIndex;
      } else {
        for (let i = 0; i < items.length; i++) {
          const item = items[i] as HTMLLIElement;
          if (
            item.innerText.toLowerCase().startsWith(event.key.toLowerCase())
          ) {
            item.tabIndex = 0;
            item.focus();
            item.scrollIntoView({ behavior: "smooth", block: "start" });
            currentFocusedIndex = i;
            break;
          }
        }
      }

      items.forEach((item, index) => {
        if (index !== currentFocusedIndex) {
          (item as HTMLElement).tabIndex = -1;
        }
      });
    }
  };
  return (
    <Menu isLazy closeOnBlur>
      <MenuButton
        textAlign={"left"}
        h="40px"
        w="full"
        px="16px"
        rightIcon={<Ico.ChevronDown />}
        as={Button}
        bg={"transparent"}
        fontSize={"14px"}
        fontWeight={"400"}
        _expanded={{
          bg: "transparent",
          border: "2px solid var(--primary-500, #628ADF)",
          boxShadow: "none",
        }}
        _hover={{
          bg: "transparent",
          border: "1px solid var(--primary-200, #628ADF)",
          color: "var(--secondary-100, #69696C)",
          fontSize: "14px",
          fontWeight: 400,
          boxShadow: "none",
        }}
        _disabled={{
          border: "1px solid var(--neutral-200, #DADADC)",
          background: "var(--neutral-50, #F7F8F9)",
          color: "var(--secondary-50, #8F8F91)",
          fontSize: "14px",
          boxShadow: "none",
          fontWeight: 400,
          cursor: "not-allowed",
        }}
        _focus={{
          border: "2px solid var(--primary-500, #628ADF)",
          boxShadow: "none",
        }}
        borderRadius={"8px"}
        border={"1px solid var(--neutral-300, #A29EA4)"}
        isDisabled={disabled || countries.length === 0}
      >
        {countries.filter(
          (state) => state.name.toLowerCase() === field.value.toLowerCase()
        )[0]?.name ?? placeholder}
      </MenuButton>
      <Box>
        <MenuList
          as={"ul"}
          zIndex={100}
          maxHeight="30vh"
          overflowY="auto"
          overflowX="hidden"
          ref={menuListRef}
          onKeyDown={handleKeyDown}
        >
          {countries &&
            countries.map((country, cid) => (
              <MenuItem
                as={"li"}
                fontSize={"16px"}
                fontWeight={"400"}
                textTransform="capitalize"
                key={`option-${cid}`}
                onClick={() => {
                  helpers.setValue(country.name);
                }}
              >
                <HStack spacing="10px">
                  <Box>{country.name}</Box>
                </HStack>
              </MenuItem>
            ))}
        </MenuList>
      </Box>
    </Menu>
  );
};

export default SelectCountry;
