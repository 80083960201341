import { AppRoute, PublicRoute } from "@/AppRoute";
import { EntityName } from "@/schemaTypes";
import { createContext } from "react";

type NavigationContextValue = {
  redirectRoute: AppRoute | PublicRoute | null | string;
  updateRedirectRoute: (route: AppRoute | PublicRoute | null | string) => void;
  productOfInterest: string | null;
  productTypeOfInterest: EntityName | string | null;
  updateProductOfInterest: (
    productTypeOfInterest: EntityName | string | null,
    productOfInterest: string | null
  ) => void;
  clearProductOfInterest: () => void;
  getProductOfInterestFromStorage: () => {
    productTypeOfInterest: EntityName | null;
    productOfInterest: string | null;
  };
  configUpsellRedirect: (
    productTypeOfInterest: EntityName | string | null,
    productOfInterest: string | null,
    extraRouteParam: string | null | AppRoute | PublicRoute,
    replaceUrl?: boolean
  ) => void;
  setShowPriceChart: (show: boolean) => void;
  getShowPriceChart: () => boolean;
  setSearchParams: (searchParams: string) => void;
  getSearchParams: () => Record<string, string> | null;
  clearSearchParams: () => void;
};
interface NavigationContextState {
  redirectRoute?: AppRoute | PublicRoute | null | string;
  updateRedirectRoute: (route: AppRoute | PublicRoute | null | string) => void;
  productOfInterest?: string | null;
  productTypeOfInterest?: EntityName | string | null;
  updateProductOfInterest: (
    productTypeOfInterest: EntityName | string | null,
    productOfInterest: string | null
  ) => void;
  clearProductOfInterest: () => void;
  getProductOfInterestFromStorage: () => {
    productTypeOfInterest: EntityName | null | string;
    productOfInterest: string | null;
  };
  configUpsellRedirect: (
    productTypeOfInterest: EntityName | string | null,
    productOfInterest: string | null,
    extraRouteParam: string | null | AppRoute | PublicRoute,
    replaceUrl?: boolean
  ) => void;
  setShowPriceChart: (show: boolean) => void;
  getShowPriceChart: () => boolean;
  setSearchParams: (searchParams: string) => void;
  getSearchParams: () => Record<string, string> | null;
  clearSearchParams: () => void;
}

const NavigationContext = createContext<NavigationContextState | null>(null);

export type { NavigationContextState, NavigationContextValue };
export { NavigationContext };
