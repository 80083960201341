import { Ico } from "@/assets/icons";
import { EmbedVideo } from "@/components/InteractiveContent/EmbedVideo";
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";

const StartGuide: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        cursor={"pointer"}
        border={"1px solid"}
        mt={isMobile ? "16px" : "none"}
        py={"16px"}
        px={"24px"}
        gap={"8px"}
        maxH={"40px"}
        borderColor={"neutral.100"}
        borderRadius={"8px"}
        alignItems={"center"}
        justify={"center"}
        flexDir={"row"}
        color={"primary.400"}
        onClick={onOpen}
      >
        <Text variant={"caption"}>¿No sabes por dónde empezar? </Text>

        <Ico.ArrowUpRight fontSize={"20px"} />
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} size={"full"} isCentered>
        <ModalOverlay bg="rgba(0, 0, 0, 0.5)" backdropFilter="blur(10px)" />
        <ModalContent
          maxW={["90%", "80%"]}
          mx="auto"
          bg="transparent"
          boxShadow="none"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box position="relative" width="100%">
            <ModalCloseButton
              position="absolute"
              top={isMobile ? "-110%" : "-8%"}
              right={isMobile ? "-2%" : "-8%"}
              minW={"30px"}
              minH={"30px"}
              bg="white"
              borderRadius="50%"
            />
            <ModalBody
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={0}
              bg="transparent"
            >
              <Box width={["100%", "80%"]}>
                <EmbedVideo
                  autoPlay
                  videoURL={`https://media.smartbeemo.com/VideoPorDondeComienzo2024Normal.mp4`}
                />
              </Box>
            </ModalBody>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default StartGuide;
