/* eslint-disable @typescript-eslint/no-shadow */
import AcademyCarousel from "@/components/AcademyCarousel";
import { Flex, Heading, Text, useBreakpointValue } from "@chakra-ui/react";
import MentorshipCard from "./MentorshipCard";
import { BigSpinner } from "@/components/BigSpinner";
import { useContext, useEffect, useState } from "react";
import { EntityName } from "@/schemaTypes";
import { learnContext } from "../providers/learnProvider";
import { MentorshipCard as MentorshipCardType } from "../providers/types";

const MentorshipsCarrousel = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { mentorships, mentorshipsLoading } = useContext(learnContext);

  const [filterOptions, setFilterOptions] = useState<string[]>(["Todos"]);

  const [filteredMentorships, setFilteredMentorships] = useState<
    MentorshipCardType[]
  >([]);

  const handleFilterClick = (selectedFilter: string) => {
    const mentorshipTypes = {
      VIP: EntityName.VipMentorship,
      Élite: EntityName.EliteMentorship,
    };

    const filteredMentorships = mentorships.filter((mentorship) => {
      if (selectedFilter === "Todos") return true;

      return (
        mentorship?.type ===
        mentorshipTypes[selectedFilter as keyof typeof mentorshipTypes]
      );
    });

    setFilteredMentorships((filteredMentorships as MentorshipCardType[]) || []);
  };

  useEffect(() => {
    setFilteredMentorships(mentorships as MentorshipCardType[]);
  }, [mentorships]);

  useEffect(() => {
    const VIPMentoshipsExist = mentorships.some(
      (mentorship) => mentorship?.type === EntityName.VipMentorship
    );

    const eliteMentorshipsExist = mentorships.some(
      (mentorship) => mentorship?.type === EntityName.EliteMentorship
    );

    const mentorshipFilters = [];

    if (VIPMentoshipsExist) mentorshipFilters.push("VIP");

    if (eliteMentorshipsExist) mentorshipFilters.push("Élite");

    setFilterOptions(["Todos", ...mentorshipFilters]);
  }, [mentorships]);

  return (
    <Flex flexDir={"column"} my={"24px"}>
      <Heading
        as={"h6"}
        fontSize={"20px"}
        mb={"16px"}
        ml={isMobile ? "16px" : 0}
      >
        Mentorías
      </Heading>

      {mentorships?.length === 0 && !mentorshipsLoading ? (
        <Text key={1} mt={"16px"} ml={"0"} variant={"placeholder"}>
          En este momento no hay Mentorías.
        </Text>
      ) : null}

      {mentorshipsLoading ? (
        <BigSpinner />
      ) : (
        <AcademyCarousel
          filterOptions={filterOptions}
          handleFilterChange={handleFilterClick}
        >
          {filteredMentorships?.map((mentorship) => (
            <MentorshipCard key={mentorship?.id} mentorship={mentorship} />
          )) || []}
        </AcademyCarousel>
      )}
    </Flex>
  );
};

export default MentorshipsCarrousel;
