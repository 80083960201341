import { Box, Flex, IconButton, useBreakpointValue } from "@chakra-ui/react";
import AcademicFilter from "./AcademicFilter";
import { useEffect, useRef, useState } from "react";
import { Ico } from "@/assets/icons";

interface AcademyCarouselProps {
  children: React.ReactNode[];
  filterOptions: string[];
  handleFilterChange: (filter: string) => void;
  isColumn?: boolean;
  visibleFilter?: boolean;
}

const AcademyCarousel: React.FC<AcademyCarouselProps> = ({
  children,
  filterOptions,
  handleFilterChange,
  isColumn = false,
  visibleFilter,
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(false);
  const [showScrollButtons, setShowScrollButtons] = useState<boolean>(false);
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const isDragging = useRef<boolean>(false);
  const startX = useRef<number>(0);
  const initialScrollLeft = useRef<number>(0);
  const visibleFilterDefault = visibleFilter ?? true;

  const handleScroll = () => {
    if (sliderRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scroll = (direction: "left" | "right") => {
    if (sliderRef.current) {
      const scrollAmount = direction === "left" ? -316 : 316;
      sliderRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const resetScroll = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft = 0;
    }
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (!isDragging.current || !sliderRef.current) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX.current) * 2;
    sliderRef.current.scrollLeft = initialScrollLeft.current - walk;
  };

  const onMouseDown = (e: React.MouseEvent) => {
    if (sliderRef.current) {
      isDragging.current = true;
      startX.current = e.pageX - sliderRef.current.offsetLeft;
      initialScrollLeft.current = sliderRef.current.scrollLeft;
    }
  };

  const onMouseLeave = () => {
    isDragging.current = false;
  };

  const onMouseUp = () => {
    isDragging.current = false;
  };

  const handleMouseMove = (e: React.MouseEvent, mouseIn = true) => {
    e.stopPropagation();
    return setShowScrollButtons(mouseIn && !isMobile);
  };

  useEffect(() => {
    handleScroll();
  }, [children]);
  return (
    <Box
      position="relative"
      onMouseEnter={(e) => handleMouseMove(e, true && !isMobile)}
      onMouseLeave={(e) => handleMouseMove(e, false)}
    >
      {visibleFilterDefault && (
        <AcademicFilter
          isColumn={isColumn}
          filterOptions={filterOptions}
          handleFilterChange={(filter: string) => {
            handleFilterChange(filter);
            resetScroll();
          }}
        />
      )}
      <Flex
        maxH={{ "2xl": "66vh" }}
        overflowY={{ "2xl": "auto" }}
        sx={{
          scrollbarWidth: "none",
        }}
      >
        {!isColumn && (
          <Flex
            h={"100%"}
            w={"max-content"}
            display={isMobile ? "none" : "flex"}
            maxW={"30px"}
            alignItems={"center"}
            position="absolute"
            left="-30px"
            cursor={canScrollLeft ? "pointer" : "default"}
            onClick={() => scroll("left")}
            onMouseEnter={(e) => handleMouseMove(e, true && !isMobile)}
            opacity={canScrollLeft && showScrollButtons ? 1 : 0}
          >
            <IconButton
              aria-label="Scroll left"
              icon={<Ico.SliderArrowIzq fontSize={"80px"} />}
              zIndex="1"
              bg={"none"}
              _hover={{ bg: "none" }}
              transform="translateY(-50%)"
            />
          </Flex>
        )}
        <Box
          cursor={"grab"}
          w={"100%"}
          _active={{ cursor: "grabbing" }}
          position="relative"
          ref={sliderRef}
          display="flex"
          flexDir={isColumn ? { base: "row", "2xl": "column" } : "row"}
          gap={4}
          px={{ base: 4, md: isColumn ? "0px" : "16px", lg: 0 }}
          overflowX="auto"
          css={{
            "&::-webkit-scrollbar": {
              width: "0px",
              height: "0px",
            },
          }}
          onScroll={handleScroll}
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
        >
          {children}
        </Box>
        {!isColumn && (
          <Flex
            h={"100%"}
            w={"max-content"}
            display={isMobile ? "none" : "flex"}
            cursor={canScrollRight ? "pointer" : "default"}
            maxW={"30px"}
            alignItems={"center"}
            position="absolute"
            right="-30px"
            opacity={canScrollRight && showScrollButtons ? 1 : 0}
            onClick={() => scroll("right")}
            onMouseEnter={(e) => handleMouseMove(e, true && !isMobile)}
          >
            <IconButton
              aria-label="Scroll left"
              icon={<Ico.SliderArrowDer fontSize={"80px"} />}
              zIndex="1"
              bg={"none"}
              _hover={{ bg: "none" }}
              transform="translateY(-50%)"
            />
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default AcademyCarousel;
