/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from "react";
import { config } from "@/config";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

declare global {
  interface Window {
    embedded_svc?: any; // Fix TypeScript error
  }
}

const cssStyles = `
    .dockableContainer.showDockableContainer {
        z-index: 99999;
    }
    .embeddedServiceHelpButton .helpButton .uiButton,
    .embeddedServiceSidebarMinimizedDefaultUI.newMessage,
    .embeddedServiceSidebarMinimizedDefaultUI.idleTimeout {
        background-color: #ffc800;
        font-family: "Salesforce Sans", sans-serif;
    }
    .embeddedServiceHelpButton .helpButton .uiButton:focus {
        outline: 1px solid #ffc800;
    }
    .embeddedServiceHelpButton .helpButton .message,
    .embeddedServiceSidebarMinimizedDefaultUI .minimizedText .message,
    .embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer:focus,
    .helpButtonEnabled:focus .helpButtonLabel,
    .embeddedServiceHelpButton .embeddedServiceIcon::before {
        color: #000000 !important;
        text-decoration: none !important;
    }
`;

export const useChat = () => {
  const [isChatInitialized, setIsChatInitialized] = useState(false);
  const [styleElement, setStyleElement] = useState<HTMLStyleElement | null>(
    null
  );
  const [chatScript, setChatScript] = useState<HTMLScriptElement | null>(null);
  const abData = useFeatureIsOn("salesforce-chat");

  const initESW = () => {
    if (!window.embedded_svc) return;

    const esw = window.embedded_svc;

    // ✅ Apply settings before initializing
    esw.settings.displayHelpButton = true;
    esw.settings.language = "es";
    esw.settings.enabledFeatures = ["LiveAgent"];
    esw.settings.entryFeature = "LiveAgent";
    esw.settings.defaultMinimizedText = "Hablemos aquí";
    esw.settings.disabledMinimizedText = "Hablemos aquí";
    esw.settings.loadingText = "Cargando...";
    esw.settings.prechatBackgroundImgURL =
      "https://smartbeemo.com/wp-content/uploads/2021/12/sb-logo-320x100-1.svg";
    esw.settings.smallCompanyLogoImgURL =
      "https://smartbeemo.com/wp-content/uploads/2021/12/sb-beemo-36x36-1.svg";
    esw.settings.waitingStateBackgroundImgURL =
      "https://smartbeemo.com/wp-content/uploads/2021/12/sb-logo-250x60-1.svg";
    esw.settings.avatarImgURL =
      "https://smartbeemo.com/wp-content/uploads/2021/12/sb-beemo-40x40-1.svg";
    esw.settings.chatbotAvatarImgURL =
      "https://smartbeemo.com/wp-content/uploads/2021/12/sb-beemo-40x40-1.svg";

    // ✅ Now initialize the chat
    esw.init(
      "https://smartbeemo.my.salesforce.com",
      "https://smartbeemo.my.salesforce-sites.com/chatestudiantes",
      null,
      config.SALESFORCE_CHAT_ORGID,
      "Chat_Estudiantes",
      {
        baseLiveAgentContentURL:
          "https://c.la4-c2-ia4.salesforceliveagent.com/content",
        deploymentId: config.SALESFORCE_CHAT_DEPLOYMENTID,
        buttonId: config.SALESFORCE_CHAT_BUTTONID,
        baseLiveAgentURL: "https://d.la4-c2-ia4.salesforceliveagent.com/chat",
        eswLiveAgentDevName: "Chat_Estudiantes",
        isOfflineSupportEnabled: false,
      }
    );
  };

  const loadChatScript = () => {
    if (!abData) {
      if (window.embedded_svc) {
        initESW(); // If script is already loaded, initialize immediately
        return;
      }

      const script = document.createElement("script");
      script.src =
        "https://smartbeemo.my.salesforce.com/embeddedservice/5.0/esw.min.js";
      script.onload = () => initESW(); // Apply settings only after script loads
      document.body.appendChild(script);
      setChatScript(script);
    }
  };

  const start = () => {
    if (isChatInitialized) return;

    // ✅ Inject CSS
    const style = document.createElement("style");
    style.appendChild(document.createTextNode(cssStyles));
    document.head.appendChild(style);
    setStyleElement(style);

    // ✅ Load and initialize chat script
    loadChatScript();

    setIsChatInitialized(true);
  };

  const stop = () => {
    // ✅ Remove CSS styles
    if (styleElement) {
      document.head.removeChild(styleElement);
      setStyleElement(null);
    }

    // ✅ Remove chat script
    if (chatScript) {
      document.body.removeChild(chatScript);
      setChatScript(null);
    }

    // ✅ Remove embedded chat elements from the DOM
    const chatContainer = document.querySelector(
      ".embeddedServiceHelpButton"
    ) as HTMLElement;
    const chatEmbed = document.querySelector(
      ".embeddedServiceSidebar"
    ) as HTMLElement;
    if (chatContainer) chatContainer.remove();
    if (chatEmbed) chatEmbed.remove();

    setIsChatInitialized(false);
  };

  return { start, stop, isChatInitialized };
};
