import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Flex,
  Text,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import React from "react";
import { Ico } from "@/assets/icons";
import { colors } from "@/components/Theme/colors";

interface ProPlanAccordionProps {
  price: string;
  currency: string;
  isUserRegistered: boolean;
  isMobile: boolean;
  showFreePlanButton: boolean;
  getProButton: () => void;
}

const ProPlanAccordion: React.FC<ProPlanAccordionProps> = ({
  price,
  currency,
  isUserRegistered,
  getProButton,
}) => {
  const { t } = useTranslation();

  return (
    <Accordion
      border={"solid 1px"}
      borderColor={colors.primary[300]}
      borderRadius={"6px"}
      allowMultiple
      w={"100%"}
    >
      <AccordionItem border="none">
        {({ isExpanded }) => (
          <>
            <AccordionButton
              _hover={{ WebkitTapHighlightColor: "transparent" }}
              _expanded={{ WebkitTapHighlightColor: "transparent" }}
              _focus={{ WebkitTapHighlightColor: "transparent" }}
              p={4}
              borderRadius="6px"
              alignItems={"flex-start"}
            >
              <Flex
                gap={2}
                textAlign={"left"}
                flexDirection={"column"}
                w={"100%"}
              >
                <Flex alignItems={"center"} gap={"2px"}>
                  <Ico.Diamond color={colors.primary[300]} fontSize={"20px"} />
                  <Text
                    fontFamily="Poppins"
                    fontWeight="600"
                    fontSize="18px"
                    sx={{
                      background:
                        "linear-gradient(90deg, #427AFF 0%, #1F58F3 49.63%, #0B34AE 99.99%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    PRO
                  </Text>
                </Flex>

                <Text
                  fontFamily={"Inter"}
                  fontWeight={400}
                  fontSize={"12px"}
                  color={"secondary.200"}
                  w={"100%"}
                >
                  Más allá de las Sesiones en Vivo, aprovecha todo el ecosistema
                  de contenido diseñado para guiarte desde el lanzamiento hasta
                  el éxito de tu negocio online.
                </Text>
                <Flex flexDirection={"column"} mb={isExpanded ? "0px" : "24px"}>
                  <Flex mt={"8px"} gap={"6px"} alignItems={"baseline"}>
                    <Text
                      fontFamily={"Poppins"}
                      fontWeight={600}
                      fontSize={"24px"}
                      lineHeight={0.9}
                    >
                      {price}
                    </Text>
                    <Flex>
                      <Text
                        fontFamily={"Poppins"}
                        fontWeight={600}
                        fontSize={"16px"}
                      >
                        {currency}
                      </Text>
                      <Text color={"secondary.100"}>/mes</Text>
                    </Flex>
                  </Flex>
                  <Text
                    fontFamily={"Inter"}
                    fontStyle={"italic"}
                    fontWeight={300}
                    fontSize={"10px"}
                    lineHeight={0.9}
                  >
                    Más matrícula de inscripción
                  </Text>
                </Flex>
                {!isExpanded && (
                  <Button
                    onClick={getProButton}
                    variant={"primary"}
                    bgGradient={
                      isUserRegistered
                        ? "linear-gradient(90deg, #427AFF 0%, #1F58F3 49.63%, #0B34AE 99.99%)"
                        : undefined
                    }
                    bg={!isUserRegistered ? "white" : undefined}
                    w={"100%"}
                    margin="0 auto"
                    border={"none"}
                    paddingRight={4}
                    _hover={isUserRegistered ? "inherit" : "none"}
                    _active={isUserRegistered ? "inherit" : "none"}
                    paddingLeft={4}
                    fontSize={"14px"}
                    fontFamily={"Inter"}
                    color={
                      !isUserRegistered ? `${colors.secondary[400]}` : "white"
                    }
                  >
                    {isUserRegistered ? t("Suscríbete a") : t("smartBeemo")}
                    <Box
                      marginLeft={2}
                      as="span"
                      bgGradient={
                        !isUserRegistered
                          ? "linear-gradient(90deg, #427AFF 0%, #1F58F3 49.63%, #0B34AE 99.99%)"
                          : undefined
                      }
                      bg={isUserRegistered ? "white" : undefined}
                      color={isUserRegistered ? "blue.600" : "white"}
                      fontWeight="bold"
                      px={2}
                      borderRadius="md"
                      fontSize={"12px"}
                    >
                      PRO
                    </Box>
                  </Button>
                )}
              </Flex>
              <Box
                w="24px"
                h="24px"
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {isExpanded ? (
                  <Ico.Minus fontSize="24px" color={colors.primary[300]} />
                ) : (
                  <Ico.Plus fontSize="24px" color={colors.primary[300]} />
                )}
              </Box>
            </AccordionButton>
            <AccordionPanel pt={0} pb={4} px={4}>
              <Flex flexDirection={"column"} gap={4}>
                <Flex gap={2} flexDirection={"column"}>
                  <Text
                    fontFamily={"Poppins"}
                    fontWeight={600}
                    fontSize={"16px"}
                  >
                    ¿Qué incluye?
                  </Text>
                  <Flex gap={4} flexDirection={"column"}>
                    <Flex gap={1} flexDirection={"column"}>
                      <Text
                        fontFamily={"Inter"}
                        fontWeight={600}
                        fontSize={"14px"}
                      >
                        En Vivo
                      </Text>
                      <Flex gap={1}>
                        <Ico.Check
                          fontSize={"20px"}
                          color={colors.success[300]}
                        />
                        <Text
                          fontFamily={"Inter"}
                          fontWeight={400}
                          fontSize={"12px"}
                          color={"secondary.300"}
                          w={"100%"}
                        >
                          Sesiones en Vivo.
                        </Text>
                      </Flex>
                      <Flex gap={1}>
                        <Ico.Check
                          fontSize={"20px"}
                          color={colors.success[300]}
                        />
                        <Text
                          fontFamily={"Inter"}
                          fontWeight={400}
                          fontSize={"12px"}
                          color={"secondary.300"}
                          w={"100%"}
                        >
                          Participación en chat.
                        </Text>
                      </Flex>
                      <Flex gap={1}>
                        <Ico.Check
                          fontSize={"20px"}
                          color={colors.success[300]}
                        />
                        <Text
                          fontFamily={"Inter"}
                          fontWeight={400}
                          fontSize={"12px"}
                          color={"secondary.300"}
                          w={"100%"}
                        >
                          Preguntas y Respuestas.
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex gap={1} flexDirection={"column"}>
                      <Text
                        fontFamily={"Inter"}
                        fontWeight={600}
                        fontSize={"14px"}
                      >
                        Programas online
                      </Text>
                      <Flex gap={1}>
                        <Ico.Check
                          fontSize={"20px"}
                          color={colors.success[300]}
                        />
                        <Text
                          fontFamily={"Inter"}
                          fontWeight={400}
                          fontSize={"12px"}
                          color={"secondary.300"}
                          w={"100%"}
                        >
                          Diplomados
                        </Text>
                      </Flex>
                      <Flex gap={1}>
                        <Ico.Check
                          fontSize={"20px"}
                          color={colors.success[300]}
                        />
                        <Text
                          fontFamily={"Inter"}
                          fontWeight={400}
                          fontSize={"12px"}
                          color={"secondary.300"}
                          w={"100%"}
                        >
                          Especializaciones
                        </Text>
                      </Flex>
                      <Flex gap={1}>
                        <Ico.Check
                          fontSize={"20px"}
                          color={colors.success[300]}
                        />
                        <Text
                          fontFamily={"Inter"}
                          fontWeight={400}
                          fontSize={"12px"}
                          color={"secondary.300"}
                          w={"100%"}
                        >
                          Cursos
                        </Text>
                      </Flex>
                      <Flex gap={1}>
                        <Ico.Check
                          fontSize={"20px"}
                          color={colors.success[300]}
                        />
                        <Text
                          fontFamily={"Inter"}
                          fontWeight={400}
                          fontSize={"12px"}
                          color={"secondary.300"}
                          w={"100%"}
                        >
                          Chat en vivo con Tutores Académicos
                        </Text>
                      </Flex>
                      <Flex gap={1}>
                        <Ico.Check
                          fontSize={"20px"}
                          color={colors.success[300]}
                        />
                        <Text
                          fontFamily={"Inter"}
                          fontWeight={400}
                          fontSize={"12px"}
                          color={"secondary.300"}
                          w={"100%"}
                        >
                          <Text
                            fontFamily={"Inter"}
                            fontWeight={600}
                            fontSize={"12px"}
                            color={"secondary.300"}
                            as={"span"}
                          >
                            40% OFF
                          </Text>{" "}
                          en Mentorías
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex gap={1} flexDirection={"column"}>
                      <Text
                        fontFamily={"Inter"}
                        fontWeight={600}
                        fontSize={"14px"}
                      >
                        Comunidad
                      </Text>
                      <Flex gap={1}>
                        <Ico.Check
                          fontSize={"20px"}
                          color={colors.success[300]}
                        />
                        <Text
                          fontFamily={"Inter"}
                          fontWeight={400}
                          fontSize={"12px"}
                          color={"secondary.300"}
                          w={"100%"}
                        >
                          Foro
                        </Text>
                      </Flex>
                      <Flex gap={1}>
                        <Ico.Check
                          fontSize={"20px"}
                          color={colors.success[300]}
                        />
                        <Text
                          fontFamily={"Inter"}
                          fontWeight={400}
                          fontSize={"12px"}
                          color={"secondary.300"}
                          w={"100%"}
                        >
                          Comunidades de WhatsApp
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex gap={1} flexDirection={"column"}>
                      <Text
                        fontFamily={"Inter"}
                        fontWeight={600}
                        fontSize={"14px"}
                      >
                        Productos
                      </Text>
                      <Flex gap={1}>
                        <Ico.Check
                          fontSize={"20px"}
                          color={colors.success[300]}
                        />
                        <Text
                          fontFamily={"Inter"}
                          fontWeight={400}
                          fontSize={"12px"}
                          color={"secondary.300"}
                          w={"100%"}
                        >
                          Destape semanal de ideas de productos
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex gap={1} flexDirection={"column"}>
                      <Text
                        fontFamily={"Inter"}
                        fontWeight={600}
                        fontSize={"14px"}
                      >
                        Beneficios
                      </Text>
                      <Flex gap={1}>
                        <Ico.Check
                          fontSize={"20px"}
                          color={colors.success[300]}
                        />
                        <Text
                          fontFamily={"Inter"}
                          fontWeight={400}
                          fontSize={"12px"}
                          color={"secondary.300"}
                          w={"100%"}
                        >
                          Catálogo de herramientas con beneficios y soluciones
                          de tecnología, marketing, diseño, finanzas y mucho
                          más.
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Button
                onClick={getProButton}
                variant={"primary"}
                bgGradient={
                  isUserRegistered
                    ? "linear-gradient(90deg, #427AFF 0%, #1F58F3 49.63%, #0B34AE 99.99%)"
                    : undefined
                }
                bg={!isUserRegistered ? "white" : undefined}
                w={"100%"}
                margin="0 auto"
                mt={4}
                border={"none"}
                paddingRight={4}
                _hover={isUserRegistered ? "inherit" : "none"}
                _active={isUserRegistered ? "inherit" : "none"}
                paddingLeft={4}
                fontSize={"14px"}
                fontFamily={"Inter"}
                color={!isUserRegistered ? `${colors.secondary[400]}` : "white"}
              >
                {isUserRegistered ? t("Suscríbete a") : t("smartBeemo")}
                <Box
                  marginLeft={2}
                  as="span"
                  bgGradient={
                    !isUserRegistered
                      ? "linear-gradient(90deg, #427AFF 0%, #1F58F3 49.63%, #0B34AE 99.99%)"
                      : undefined
                  }
                  bg={isUserRegistered ? "white" : undefined}
                  color={isUserRegistered ? "blue.600" : "white"}
                  fontWeight="bold"
                  px={2}
                  borderRadius="md"
                  fontSize={"12px"}
                >
                  PRO
                </Box>
              </Button>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default ProPlanAccordion;
