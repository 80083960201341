import { useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import { parseLiveSessionsDate } from "../utils";
import { Mentor } from "@/schemaTypes";
import { UserState } from "@/providers/UserProvider";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GetLiveSessionByIdDocument } from "../graphql/getLiveSessionById.generated";
import { noCacheHeaders } from "@/utils/headers";

export const MAX_ATTENDEES = 1000;

export const validateTimezone = (tz: string | null): string => {
  return tz && moment.tz.names().includes(tz) ? tz : moment.tz.guess();
};

const useLiveSession = (user: UserState | null, timezone: string) => {
  const [isLive, setIsLive] = useState(false);
  const { liveSessionId } = useParams<{ liveSessionId: string }>();

  const [
    getLiveSession,
    { data: liveSessionData, loading, refetch: refetchLiveSession },
  ] = useLazyQuery(GetLiveSessionByIdDocument);

  useEffect(() => {
    if (liveSessionId) {
      getLiveSession({
        variables: { liveSessionId },
        fetchPolicy: "no-cache",
        context: { headers: noCacheHeaders },
      });
    }
  }, [liveSessionId, getLiveSession]);

  const liveSession = useMemo(() => {
    return liveSessionData?.getLiveSessionById;
  }, [liveSessionData]);

  useEffect(() => {
    if (!liveSession) {
      setIsLive(false);
      return;
    }

    const updateIsLive = () => {
      const now = moment().tz(validateTimezone(timezone));
      const localStartDate = moment(liveSession.startDate).tz(
        validateTimezone(timezone)
      );
      const localEndDate = moment(liveSession.endDate).tz(
        validateTimezone(timezone)
      );

      const currentlyLive =
        now.isSameOrAfter(localStartDate) &&
        now.isBefore(localEndDate) &&
        !liveSession.isCancelled;

      setIsLive(currentlyLive);
    };
    // Check immediately on mount
    updateIsLive();

    const now = moment().tz(validateTimezone(timezone));
    const startDiff = moment(liveSession.startDate).diff(now, "milliseconds");
    const endDiff = moment(liveSession.endDate).diff(now, "milliseconds");

    if (startDiff > 0) {
      const startTimeout = setTimeout(updateIsLive, startDiff);
      return () => clearTimeout(startTimeout);
    }

    if (endDiff > 0) {
      const endTimeout = setTimeout(updateIsLive, endDiff);
      return () => clearTimeout(endTimeout);
    }
  }, [liveSession, timezone]);

  const isScheduled = useMemo(() => {
    if (!liveSession) return false;

    return liveSession.status === "SCHEDULED";
  }, [liveSession, timezone]);

  const isPast = useMemo(() => {
    if (!liveSession) return false;

    const now = moment().tz(validateTimezone(timezone));
    const localEndDate = moment(liveSession.endDate).tz(
      validateTimezone(timezone)
    );

    return now.isAfter(localEndDate);
  }, [liveSession, timezone]);

  const isFree = useMemo(() => {
    if (!liveSession) return false;

    return liveSession.free ?? true;
  }, [liveSession]);

  const mainMentor = useMemo(() => {
    if (!liveSession?.mainMentors || liveSession.mainMentors.length === 0)
      return null;
    return liveSession.mainMentors[0] as Mentor;
  }, [liveSession]);

  const availableSeats = useMemo(() => {
    if (!liveSession?.attendees) return null;
    return Math.max(0, MAX_ATTENDEES - liveSession.attendees.length);
  }, [liveSession]);

  const isUserMainMentor = useMemo(() => {
    return (
      user &&
      user.id &&
      liveSession?.mainMentors?.some((mentor) => mentor?.id === user.id)
    );
  }, [user, liveSession]);

  const isUserSecondaryMentor = useMemo(() => {
    return (
      user &&
      user.id &&
      liveSession?.secondaryMentors?.some((mentor) => mentor?.id === user.id)
    );
  }, [user, liveSession]);

  const isMentorOrTutor = isUserMainMentor || isUserSecondaryMentor;

  const isUserAttendee = useMemo(() => {
    if (!user || !liveSession) return false;
    return liveSession.attendees?.some(
      (attendee) => attendee.userId === user.id
    );
  }, [liveSession, user]);

  const getFormattedTimes = useMemo(() => {
    if (!liveSession) return { date: "", hour: "" };
    const validTimezone = validateTimezone(timezone);

    const { format: date, time: hour } = parseLiveSessionsDate(
      liveSession.startDate,
      validTimezone,
      false
    );

    return { date, hour };
  }, [liveSession, timezone]);

  return {
    liveSession,
    isLive,
    isPast,
    isFree,
    isScheduled,
    mainMentor,
    getFormattedTimes,
    availableSeats,
    isUserMainMentor,
    isUserSecondaryMentor,
    isMentorOrTutor,
    isUserAttendee,
    loading,
    refetchLiveSession,
  };
};

export default useLiveSession;
