import { useLazyQuery } from "@apollo/client";
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  Tooltip,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { Suspense, lazy, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AdminRoute, AppRoute } from "@/AppRoute";
import { Ico } from "@/assets/icons";
import AvatarSB from "@/components/AvatarSB";
import { LanguageChooser } from "@/components/LanguageChooser";
import { GetUserDocument } from "@/graphql/getUser.generated";
import { useUser, useUserAuth } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";
import { signOut } from "@/services/authService";
import { TestIds } from "@/utils/testIds";
import { HeaderMenuButton } from "./Button";
import { colors } from "@/components/Theme/colors";
import validateComponentVisibility from "@/pages/Learn/validateOnboarding";

const PlatformFeedbackForm = lazy(
  () => import("@/components/Feedback/PlatformFeedbackForm")
);

const ICON_SIZE = "20px";

interface HeaderMenuProps {
  onToggleBlur: (isBlurred: boolean) => void;
}

const HeaderMenu = ({ onToggleBlur }: HeaderMenuProps): JSX.Element | null => {
  const { updateLoadingUser } = useUserAuth();
  const [isOpen, setIsOpen] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const [, { client }] = useLazyQuery(GetUserDocument);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    hasRoles,
    isEditModeEnabled,
    isProModeEnabled,
    isMenuVisible,
    setIsEditModeEnabled,
    setIsProModeEnabled,
    setUser,
    user,
    isPro,
  } = useUser();
  const proUpdatedAt = user?.proUpdatedAt?.toString() ?? "";
  const isComponentVisible = isPro
    ? validateComponentVisibility(proUpdatedAt, false)
    : false;
  const canEdit = hasRoles([UserRole.Admin, UserRole.Editor]);
  const isMentor = hasRoles([UserRole.Mentor]);
  const isAdmin = hasRoles([UserRole.Admin]);
  const [isFeedbackFormVisible, setIsFeedbackFormVisible] = useState(false);

  const isFreeUser = !hasRoles([
    UserRole.Pro,
    UserRole.Admin,
    UserRole.PremiumSubscriber,
    UserRole.BasicSubscriber,
  ]);

  const showFeedbackForm = useCallback(() => {
    setIsFeedbackFormVisible(true);
  }, []);

  const hideFeedbackForm = useCallback(() => {
    setIsFeedbackFormVisible(false);
  }, []);

  useEffect(() => {
    const handleFeedbackClick = () => showFeedbackForm();
    window.addEventListener("Ada:Feedback:click", handleFeedbackClick);
    return () => {
      window.removeEventListener("Ada:Feedback:click", handleFeedbackClick);
    };
  }, [showFeedbackForm]);

  const hoverColor = useColorModeValue("secondary.400", "white");

  const navigateTo = useCallback(
    (path: string) => () => {
      navigate(path);
      setIsOpen(false);
    },
    [navigate]
  );

  const handleProfileClick = useCallback(() => {
    if (user) {
      navigate(`${AppRoute.Users}/${user.id}`);
      setIsOpen(false);
    }
  }, [navigate, user]);

  const handleToggleMode = useCallback(
    (setter: (value: boolean) => void, value: boolean) => {
      setter(!value);
      setIsOpen(false);
    },
    []
  );

  const handleSignOut = useCallback(async () => {
    updateLoadingUser(true);
    await signOut();
    setUser(null);
    if (client) {
      client.clearStore();
      client.stop();
    }
    navigate(AppRoute.Base, { replace: true });
  }, [client, navigate, setUser]);

  const handleMenuToggle = useCallback(() => {
    setIsOpen((prev) => {
      const newState = !prev;
      onToggleBlur(newState);
      return newState;
    });
  }, [onToggleBlur]);

  useEffect(() => {
    onToggleBlur(isOpen);
  }, [isOpen, onToggleBlur]);

  if (!user) return null;

  return (
    <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
      {isFeedbackFormVisible && (
        <Suspense fallback={null}>
          <PlatformFeedbackForm onClose={hideFeedbackForm} />
        </Suspense>
      )}
      <Box position="relative" display="inline-block">
        <MenuButton
          data-cy={TestIds.HeaderMenuButton}
          onClick={handleMenuToggle}
        >
          <AvatarSB />
        </MenuButton>
        {isOpen && (
          <Box
            position="absolute"
            bottom={-2.5}
            left="50%"
            transform="translateX(-50%)"
            w="100%"
            h="4px"
            bg={colors.primary[400]}
            borderRadius="6px"
            transition="all 0.3s ease"
          />
        )}
      </Box>

      <MenuList
        mt="2px"
        p="24px 16px"
        rounded="8px"
        w="320px"
        overflowY={"auto"}
        maxHeight={"556px"}
      >
        <Flex>
          <AvatarSB />
          <Box ml="8px">
            <Text variant="bold">{user.name}</Text>
            <Tooltip label={user.email}>
              <Text
                isTruncated
                maxW="232px"
                my="4px"
                opacity={0.6}
                fontSize={"14px"}
                color={"secondary.200"}
              >
                {user.email}
              </Text>
            </Tooltip>
            {/* <Hide above="xl">
              <GamificationStats />
            </Hide> */}
          </Box>
        </Flex>
        {isComponentVisible && (
          <>
            <MenuDivider my="16px" border="1px" borderColor="neutral.200" />
            <HeaderMenuButton
              label={t("Welcome sessions")}
              leftIcon={
                <Ico.Flag fontSize={ICON_SIZE} color={colors.secondary[200]} />
              }
              onClick={navigateTo(AppRoute.Onboarding)}
              fontWeight="600"
            />
          </>
        )}
        <MenuDivider my="16px" border="1px" borderColor="neutral.200" />
        {!isFreeUser && (
          <HeaderMenuButton
            label={"My Subscription"}
            leftIcon={
              <Ico.CreditCard
                fontSize={ICON_SIZE}
                color={colors.secondary[200]}
              />
            }
            onClick={navigateTo(AppRoute.MyOrdersProgress)}
            data-cy={TestIds.myOrders}
            fontWeight="600"
          />
        )}
        <HeaderMenuButton
          label={"My Profile"}
          leftIcon={
            <Ico.User fontSize={ICON_SIZE} color={colors.secondary[200]} />
          }
          data-cy={TestIds.myProfile}
          onClick={handleProfileClick}
          fontWeight="600"
        />
        <HeaderMenuButton
          label="My Progress"
          leftIcon={
            <Ico.TrendUp fontSize={ICON_SIZE} color={colors.secondary[200]} />
          }
          onClick={navigateTo(AppRoute.MyProgress)}
          data-cy={TestIds.myProgress}
          fontWeight="600"
        />
        <HeaderMenuButton
          label="My Certificates"
          leftIcon={
            <Ico.Certificate
              fontSize={ICON_SIZE}
              color={colors.secondary[200]}
            />
          }
          onClick={navigateTo(AppRoute.Certificates)}
          data-cy={TestIds.myCertificates}
          fontWeight="600"
        />
        {/* <HeaderMenuButton
          label="Club Scale"
          leftIcon={
            <Ico.Badge fontSize={ICON_SIZE} color={colors.secondary[200]} />
          }
          onClick={navigateTo(AppRoute.MyProfileGamification)}
          data-cy={TestIds.clubScale}
          fontWeight="600"
        /> */}
        <MenuDivider border="1px" borderColor="neutral.200" />
        {canEdit && (
          <>
            {canEdit && isAdmin && (
              <MenuOptionGroup
                title="Admin"
                type="checkbox"
                value={isEditModeEnabled ? "enabled" : ""}
              >
                <MenuItemOption
                  data-cy={TestIds.EditMode}
                  onClick={() =>
                    handleToggleMode(setIsEditModeEnabled, isEditModeEnabled)
                  }
                  value="enabled"
                >
                  {t("Edit Mode")}
                </MenuItemOption>
              </MenuOptionGroup>
            )}
            {canEdit && isAdmin && (
              <MenuOptionGroup
                type="checkbox"
                value={isProModeEnabled ? "enabled" : ""}
              >
                <MenuItemOption
                  data-cy={TestIds.ProMode}
                  onClick={() =>
                    handleToggleMode(setIsProModeEnabled, isProModeEnabled)
                  }
                  value="enabled"
                >
                  {t("Pro Mode")}
                </MenuItemOption>
              </MenuOptionGroup>
            )}
            <MenuItemOption
              data-cy={TestIds.AdminPanel}
              onClick={navigateTo(
                isMentor
                  ? `${AdminRoute.AdministrationPanel}${AppRoute.HighTicketMentorship}`
                  : `${AdminRoute.AdministrationPanel}/general`
              )}
              value="enabled"
            >
              {t("Admin panel")}
            </MenuItemOption>
            <MenuDivider />
          </>
        )}
        {/* <HeaderMenuButton
          data-cy={TestIds.TermsAndConditions}
          label="Terms and Conditions"
          leftIcon={
            <Ico.FileCheck fontSize={ICON_SIZE} color={colors.secondary[200]} />
          }
          onClick={navigateTo(AppRoute.TermsAndConditions)}
          fontWeight="600"
        /> */}
        <HeaderMenuButton
          data-cy={TestIds.LogoutButton}
          label="Logout"
          leftIcon={
            <Ico.RightFromBracket
              fontSize={ICON_SIZE}
              color={colors.secondary[200]}
            />
          }
          onClick={handleSignOut}
          fontWeight="600"
        />
        <MenuDivider display="none" />
        <Flex display="none">
          <IconButton
            _hover={{ color: hoverColor }}
            aria-label="color-mode"
            bgColor="unset"
            display="none"
            icon={
              colorMode === "dark" ? (
                <Ico.Sun fontSize={ICON_SIZE} />
              ) : (
                <Ico.Moon fontSize={ICON_SIZE} />
              )
            }
            onClick={toggleColorMode}
          />
          <LanguageChooser />
          {isMenuVisible && (
            <IconButton
              _hover={{ color: hoverColor }}
              aria-label="Feedback"
              bgColor="unset"
              icon={
                <Ico.MessageAlert
                  fontSize={ICON_SIZE}
                  color={colors.secondary[200]}
                />
              }
              onClick={showFeedbackForm}
            />
          )}
        </Flex>
      </MenuList>
    </Menu>
  );
};

export { HeaderMenu };
