/* eslint-disable no-underscore-dangle */
import React, { Fragment } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Text,
} from "@chakra-ui/react";
import { Ico } from "@/assets/icons";
import { colors } from "@/components/Theme/colors";
import { useTranslatedField } from "@/hooks/useTranslatedField";
import { useTranslation } from "react-i18next";
import { InteractiveContent, Session } from "../ProgressDashboard/types";
interface ProgramResumeProps {
  index: number;
  sessionData?: Session;
  programType: string;
}

export const ProgramAccordion: React.FC<ProgramResumeProps> = ({
  index,
  sessionData,
  programType,
}) => {
  const translatedField = useTranslatedField();
  const { t } = useTranslation();
  if (!sessionData) return null;
  return (
    <Flex borderRadius={"6px"} flexDir={"column"}>
      <Accordion
        allowMultiple
        defaultIndex={programType !== t("ADVANCED_COURSE") ? undefined : [0]}
        pt={0}
      >
        <AccordionItem border={"none"}>
          {({ isExpanded }) => (
            <Fragment>
              <AccordionButton
                _hover={{ backgroundColor: "white" }}
                justifyContent={"space-between"}
                alignItems={"center"}
                px={0}
                py={0}
                borderRadius={"6px"}
                display={programType !== t("ADVANCED_COURSE") ? "flex" : "none"}
              >
                <Text fontSize={"14px"} textAlign={"start"} variant={"bold"}>
                  {`${index}. ${sessionData._doc.name[0].value}`}
                </Text>
                <Box
                  w={"24px"}
                  h={"24px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {isExpanded ? (
                    <Ico.Minus fontSize={"24px"} color={colors.primary[300]} />
                  ) : (
                    <Ico.Plus fontSize={"24px"} color={colors.primary[300]} />
                  )}
                </Box>
              </AccordionButton>
              <AccordionPanel
                pt={programType !== t("ADVANCED_COURSE") ? "8px" : 4}
                pb={4}
                px={0}
              >
                <Flex gap={2} pb={2}>
                  <Flex gap={1} alignItems={"center"}>
                    <Ico.BookOpen
                      fontSize={"16px"}
                      color={colors.neutral[300]}
                    />
                    <Text fontSize={"12px"} fontWeight={"bold"}>
                      {`${sessionData.interactiveContent.length} lecciones`}
                    </Text>
                  </Flex>
                  <Flex gap={1} alignItems={"center"}>
                    <Ico.Clock2 fontSize={"16px"} color={colors.neutral[300]} />
                    <Text fontSize={"12px"} fontWeight={"bold"}>
                      {`${sessionData._doc.cant_hours_course}`}
                    </Text>
                  </Flex>
                </Flex>
                {sessionData.interactiveContent.map(
                  (content: InteractiveContent, i: number) => {
                    return (
                      <Fragment key={content._id}>
                        {content.subTitle && content?.subTitle?.length > 0 && (
                          <Text fontWeight={"bold"}>
                            {translatedField(content.subTitle)}
                          </Text>
                        )}
                        <Text
                          variant={"placeholder"}
                          color={colors.secondary[200]}
                        >
                          {`${i + 1}.  ${content?.title?.[0].value}`}
                        </Text>
                      </Fragment>
                    );
                  }
                )}
              </AccordionPanel>
              <Divider
                w="100%"
                color={"neutral.200"}
                pb={isExpanded ? "8px" : "24px"}
              />
            </Fragment>
          )}
        </AccordionItem>
      </Accordion>
    </Flex>
  );
};
