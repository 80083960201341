import { BootcampBannerEntry, EntityName } from "@/schemaTypes";
import { Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useUser } from "@/providers/useUser";
import { Ico } from "@/assets/icons";

import bootcampbeemo from "@/assets/imgs/bootcamp-beemo.svg";

export interface DetailBannerProps {
  bootcamp: BootcampBannerEntry;
  startDateFormatted: string;
  isRegistered?: boolean;
  onClickEnroll?: () => void;
}

export const DetailBanner = ({
  bootcamp,
  startDateFormatted,
  isRegistered,
  onClickEnroll,
}: DetailBannerProps) => {
  const { t } = useTranslation();
  const logoMastermind = "https://media.smartbeemo.com/mastermind.svg";
  const { isUserRegistered, isUserSubscribed, isPro } = useUser();

  return (
    <Flex flexDir={"column"} maxW={{ base: "full", lg: "65%" }} gap={4}>
      <Flex flexDir={"column"} gap={2}>
        <Image
          src={
            bootcamp.parent?.bootcampType !== EntityName.MastermindBootcamp
              ? bootcampbeemo
              : logoMastermind
          }
          w={"104px"}
        />
        <Heading as={"h5"}>{bootcamp?.release?.title}</Heading>
        <Text fontSize={"14px"}>
          {bootcamp?.release?.description}
          <Text as={"span"} fontSize={"14px"} variant={"bold"}>
            {t(" Iniciamos el")} {startDateFormatted}.
          </Text>
        </Text>
      </Flex>
      {!isRegistered && (
        <Button
          onClick={onClickEnroll}
          variant={"primary"}
          fontSize={"14px"}
          px={4}
          width={{ base: "full", md: "fit-content", lg: "fit-content" }}
          h={"36px"}
          leftIcon={
            isUserRegistered ? (
              <Ico.Diamond fontSize="18px" color="neutral.white" />
            ) : undefined
          }
        >
          {isUserSubscribed
            ? isPro
              ? t("¡Regístrate ahora!")
              : t("Compra este entrenamiento")
            : "Regístrate para comprar este entrenamiento"}
        </Button>
      )}
    </Flex>
  );
};
