import { Button } from "@/components/Button";
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { t } from "i18next";
import { Ico } from "@/assets/icons";
import { useMentor } from "@/pages/academy/LiveSessionZoom/providers/useMentor";

interface ModalActiveAudioProps {
  isOpen: boolean;
  onClose: () => void;
  url?: string;
}

export const SwitchSessionModal = ({
  isOpen,
  onClose,
  url,
}: ModalActiveAudioProps) => {
  const isMentorOrAdmin = useMentor();

  const navigateExternalSession = () => {
    window.open(url, "_blank");
  };

  const isCentered = useBreakpointValue({ base: false, lg: true });

  return (
    <Modal
      size={"xs"}
      closeOnOverlayClick={
        isMentorOrAdmin.isMainMentor || isMentorOrAdmin.isMentorOrAdmin
      }
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered}
    >
      <ModalOverlay />
      <ModalContent
        style={{ zIndex: 1100 }}
        backgroundColor={"shades.white"}
        borderRadius={"8px"}
        height="auto"
        display={"flex"}
        justifyContent="center"
        alignItems="center"
        position="relative"
        left={{ base: "0", lg: "-10%" }}
        top={{ base: "-2%", lg: "0" }}
        transform="translateX(0)"
      >
        <ModalBody p={{ base: 4, lg: 6 }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={4}
            h={"100%"}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              w={"48px"}
              h={"48px"}
              borderRadius={"50%"}
              backgroundColor={"tertiary.300"}
            >
              <Ico.LinkExternal fontSize={"30px"} />
            </Box>
            <Text
              fontSize={"16px"}
              textAlign={"center"}
              fontFamily={"Inter"}
              fontWeight={600}
            >
              Esta sesión ahora se está transmitiendo por Zoom.
            </Text>
            <Button
              title={t("Entrar a la sesión aquí")}
              onClick={navigateExternalSession}
              variant="primary"
              borderRadius={"6px"}
              fontSize={"14px"}
              w={"100%"}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
