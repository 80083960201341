import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  Box,
  Card,
  Flex,
  IconButton,
  ResponsiveValue,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import LiveChat from "./LiveChat/LiveChat";
import Questions from "./Questions/Questions";
import ChatContext from "../context/chat-context";
import { ChatMessage } from "@zoom/videosdk";
import { Ico } from "@/assets/icons";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import ZoomClientContext from "../context/zoom-context";
import { Participants } from "./Participants/Participants";
import NetworkStats from "@/pages/academy/LiveSessionZoom/Streaming/NetworkStats";
import { ClassMigration } from "./ClassMigration/ClassMigration";
import { GetJwtAuthLiveSessionQuery } from "../graphql/getJwtAuthLiveSession.generated";
import { useMentor } from "@/pages/academy/LiveSessionZoom/providers/useMentor";
import ParticipantsCount from "./ParticipantsCount";

interface ChatProps {
  width?: ResponsiveValue<number | string>;
  session?: GetJwtAuthLiveSessionQuery;
}

const Chat: React.FC<ChatProps> = ({ session }) => {
  const [sessionData, setSessionData] = useState(session);
  const isMentorOrAdmin = useMentor();
  const [, setTabIndex] = useState<number>(0);
  const chatClient = useContext(ChatContext);
  const { clientState, zmClient } = useContext(ZoomClientContext);
  const { liveSessionId } = useParams<{ liveSessionId: string }>();
  const [generalChatMessages, setGeneralChatMessages] = useState<ChatMessage[]>(
    []
  );
  const [questionChatMessages, setQuestionChatMessages] = useState<
    ChatMessage[]
  >([]);
  const [isHostOrCoHost, setIsHostOrCoHost] = useState(isMentorOrAdmin);
  const [showClassMigration, setShowClassMigration] = useState(false);

  const addUniqueMessage = useCallback(
    (messages: ChatMessage[], newMessage: ChatMessage) => {
      if (!messages.some((msg) => msg.id === newMessage.id)) {
        return [...messages, newMessage];
      }
      return messages;
    },
    []
  );
  useEffect(() => {
    if (session) {
      setSessionData(session);
    }
  }, [session]);
  useEffect(() => {
    const onChatMessage = (payload: ChatMessage) => {
      const { sender, message, timestamp, receiver, file, id } = payload;
      const chatMessage: ChatMessage = {
        sender,
        message,
        timestamp,
        receiver,
        file,
        id,
      };

      if (!payload.message?.startsWith("Question:")) {
        setGeneralChatMessages((prevMessages) => {
          const updatedMessages = addUniqueMessage(prevMessages, chatMessage);
          sessionStorage.setItem(
            "generalChatMessages" + "-" + liveSessionId,
            JSON.stringify(updatedMessages)
          );
          return updatedMessages;
        });
      } else {
        setQuestionChatMessages((prevMessages) => {
          const updatedMessages = addUniqueMessage(prevMessages, chatMessage);
          sessionStorage.setItem(
            "questionChatMessages" + "-" + liveSessionId,
            JSON.stringify(updatedMessages)
          );
          return updatedMessages;
        });
      }
    };

    const onChatPrivilegeChange = (_: { chatPrivilege: number }) => {
      if (zmClient && isMentorOrAdmin && chatClient) {
        setIsHostOrCoHost(
          isMentorOrAdmin || zmClient.isHost() || zmClient.isManager()
        );
      }
    };

    if (zmClient) {
      zmClient.on("chat-on-message", onChatMessage);
      zmClient.on("chat-privilege-change", onChatPrivilegeChange);
    }

    return () => {
      if (zmClient) {
        zmClient.off("chat-on-message", onChatMessage);
        zmClient.off("chat-privilege-change", onChatPrivilegeChange);
      }
    };
  }, [zmClient, chatClient, addUniqueMessage, liveSessionId, isMentorOrAdmin]);

  useEffect(() => {
    const fetchInitialMessages = () => {
      const savedGeneralMessages = JSON.parse(
        sessionStorage.getItem("generalChatMessages" + "-" + liveSessionId) ||
          "[]"
      );
      setGeneralChatMessages(savedGeneralMessages);

      const savedQuestionMessages = JSON.parse(
        sessionStorage.getItem("questionChatMessages" + "-" + liveSessionId) ||
          "[]"
      );
      setQuestionChatMessages(savedQuestionMessages);
    };

    fetchInitialMessages();
  }, [liveSessionId]);

  const sendMessage = useCallback(
    (isPublic: boolean, message: string) => {
      if (message.trim() !== "" && zmClient && chatClient) {
        chatClient.sendToAll(message);
      }
    },
    [zmClient, chatClient]
  );

  if (!clientState?.isAuth || !zmClient || !chatClient) return null;

  return (
    <Card
      bg={"#27272E"}
      height="100%"
      display={"flex"}
      flexDir={"column"}
      id="chatCard"
      border={"1px solid #32323A"}
    >
      <Flex flexDir={"column"} height="100%" id={"chatContainer"}>
        <Box
          hidden={true}
          backgroundColor={"secondary.300"}
          py={"6px"}
          borderRadius={"8px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          color={"neutral.200"}
          gap={"8px"}
          mt={"24px"}
          mx={"24px"}
        >
          <NetworkStats zmClient={zmClient} />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap="8px"
          mt={{ base: "12px", xl: "16px" }}
          mx={{ base: "12px", xl: "16px" }}
        >
          <Box
            backgroundColor="secondary.300"
            py="6px"
            px="12px"
            borderRadius="8px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="neutral.200"
            gap="8px"
            w={"full"}
          >
            <Ico.Users color="neutral.200" fontSize="20px" />
            <Text color="neutral.200" fontSize="14px">
              <ParticipantsCount />
            </Text>
          </Box>

          {(isHostOrCoHost.isMainMentor || isHostOrCoHost.isMentorOrAdmin) && (
            <Tooltip label="Redireccionamiento" aria-label="Redireccionamiento">
              <IconButton
                aria-label="Toggle ClassMigration"
                icon={<Ico.LinkExternal fontSize={"20px"} />}
                onClick={() => setShowClassMigration(!showClassMigration)}
                backgroundColor={
                  sessionData?.getJwtAuthLiveSession.session
                    .isExternalLinkActive
                    ? "#38A169"
                    : "secondary.300"
                }
                color="neutral.200"
                borderRadius="8px"
                p="4px"
                _hover={{ backgroundColor: "secondary.400" }}
              />
            </Tooltip>
          )}
        </Box>

        {(isHostOrCoHost.isMainMentor || isHostOrCoHost.isMentorOrAdmin) &&
          showClassMigration && (
            <ClassMigration
              session={sessionData as GetJwtAuthLiveSessionQuery}
            />
          )}
        <Tabs
          isLazy
          display={"flex"}
          flex={1}
          flexDir={"column"}
          isFitted
          onChange={(index: number) => {
            setTabIndex(index);
          }}
          overflow={"auto"}
          h={{ base: "auto", lg: "calc(100% - 120px)" }}
          maxH={{ base: "none", lg: "100%" }}
          mt={{ base: "12px", xl: "16px" }}
          mx={{ base: "12px", xl: "16px" }}
          id="Tabs-chat"
        >
          <TabList>
            <Tab
              fontSize={"14px"}
              _selected={{
                color: "tertiary.400",
                borderBottom: "3px solid #E3BF00",
              }}
              color={"secondary.50"}
            >
              Chat
            </Tab>
            <Tab
              fontSize={"14px"}
              _selected={{
                color: "tertiary.400",
                borderBottom: "3px solid #E3BF00",
              }}
              color={"secondary.50"}
            >
              {isMentorOrAdmin ? "Preguntas" : `¿${t("You have questions")}?`}
            </Tab>
            {(isHostOrCoHost.isMainMentor ||
              isHostOrCoHost.isMentorOrAdmin) && (
              <Tab
                fontSize={"14px"}
                _selected={{
                  color: "tertiary.400",
                  borderBottom: "3px solid #E3BF00",
                }}
                color={"secondary.50"}
              >
                {t("Participantes")}
              </Tab>
            )}
          </TabList>
          <TabPanels
            id="chatpanel"
            // bg={"#303036"}
            borderBottomRadius={"8px"}
            overflowY={"auto"}
            height={{
              base: "90%",
              sm: "80%",
              md: "85%",
              lg: "85%",
              xl: "85%",
              "2xl": "95%",
            }}
          >
            <LiveChat
              generalChatMessages={generalChatMessages}
              sendMessage={sendMessage}
            />

            <Questions
              isHostOrCoHost={
                isHostOrCoHost.isMainMentor || isHostOrCoHost.isMentorOrAdmin
              }
              questionChatMessages={questionChatMessages}
              sendMessage={sendMessage}
            />

            {(isHostOrCoHost.isMainMentor ||
              isHostOrCoHost.isMentorOrAdmin) && <Participants />}
          </TabPanels>
        </Tabs>
      </Flex>
      {/* {!validateRole && (
        <>
          <Box
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            height={{ base: "125px", lg: "140px" }}
            backgroundColor={"secondary.300"}
            opacity={0.7}
            pointerEvents="none"
            zIndex={2}
          ></Box>
          <Box
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            height={{ base: "125px", lg: "140px" }}
            zIndex={3}
          >
            <ChatFreemiumSheet zIndex={3}></ChatFreemiumSheet>
          </Box>
        </>
      )} */}
    </Card>
  );
};

export default Chat;
