interface VideoPlayerInterface {
  url: string;
}

export const VideoPlayer = ({ url }: VideoPlayerInterface) => {
  return (
    <video
      width="100%"
      height="100%"
      controls
      style={{
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        objectFit: "cover",
        objectPosition: "center",
      }}
    >
      <source src={url} type="video/mp4" />
      Tu navegador no soporta la etiqueta de video.
    </video>
  );
};
