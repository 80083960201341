/* eslint-disable no-underscore-dangle */
import { AppRoute } from "@/AppRoute";
import { Ico } from "@/assets/icons";
import { useCustomNavigation } from "@/providers/Navigation/useCustomNavigation";
import {
  Box,
  Checkbox,
  Flex,
  FormLabel,
  Heading,
  Image,
  InputGroup,
  InputRightElement,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "../Button";
import { InputStyled } from "../Forms/InputStyled";
import InputPhone from "../OnBoarding/Form/InputPhone";
import SelectCountry from "../OnBoarding/Form/SelectCountry";
import { BackButton } from "../PageContainer/BackButton";
import { IconError } from "./IconError";
import { PasswordIcon } from "./PasswordIcon";
import {
  SignUpCardProps,
  signUpCardValidationSchema,
  SignUpData,
  useSignUpCard,
} from "./useSignUpCard";

export const SignUpCard: React.FC<SignUpCardProps> = ({ onSignUp }) => {
  const [searchParams] = useSearchParams();
  const { getSearchParams } = useCustomNavigation();
  const headingRef = useRef<HTMLDivElement>(null);
  const [headingWidth, setHeadingWidth] = useState("auto");
  const {
    t,
    handleSubmit,
    handleShowPassword,
    handleShowConfirmPassword,
    onSignInClick,
    showConfirmPassword,
    showPassword,
    loading,
  } = useSignUpCard(onSignUp);
  const { getProductOfInterestFromStorage } = useCustomNavigation();
  getProductOfInterestFromStorage();

  useEffect(() => {
    const updateWidth = () => {
      if (headingRef.current) {
        setHeadingWidth(`${headingRef.current.offsetWidth}px`);
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const storableSearchParams = getSearchParams();

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        acceptedTerms: false,
        countryOfResidence: "",
        referralId: "",
        utmSource:
          searchParams.get("utm_source") ||
          storableSearchParams?.utm_source ||
          "",
        utmMedium:
          searchParams.get("utm_medium") ||
          storableSearchParams?.utm_medium ||
          "",
        utmCampaign:
          searchParams.get("utm_campaign") ||
          storableSearchParams?.utm_campaign ||
          "",
        tipoCampana:
          searchParams.get("tipo_campana") ||
          storableSearchParams?.tipo_campana ||
          "",
        fbc: searchParams.get("_fbc") || storableSearchParams?._fbc || "",
        fbp: searchParams.get("_fbp") || storableSearchParams?._fbp || "",
      }}
      validationSchema={signUpCardValidationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isValid,
        submitForm,
        setFieldValue,
      }) => (
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          w={"100%"}
          py={10}
          overflow={"auto"}
          overflowY={"hidden"}
          gap={8}
        >
          <Flex w={headingWidth}>
            <BackButton to={-1} text="Back" />
          </Flex>

          <Flex direction="column" px={{ base: "16px", lg: "40px" }}>
            <Image
              src="/img/logo_smartbeemo.svg"
              alt="Logo smartBeemo"
              mb={"32px"}
              h="30px"
              w="152px"
              htmlHeight="70"
              htmlWidth="132"
              objectFit="contain"
              alignSelf="flex-start"
            />
            <Flex justify="flex-start" ref={headingRef}>
              <Heading
                color={"secondary.300"}
                as={"h6"}
                mb={{ base: "24px", lg: "32px" }}
              >
                {t("SignUpForm.createAccount")}
              </Heading>
            </Flex>
            <Flex direction="column" gap="16px">
              <Flex direction="row" w="100%" gap="4" wrap="wrap">
                <Box flex="1" minW="200px">
                  <FormLabel color={"secondary.300"}>
                    {t("SignUpForm.firstName")}
                  </FormLabel>
                  <InputStyled
                    name="firstName"
                    value={String(values.firstName)}
                    autoComplete="given-name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched["firstName" as keyof SignUpData] &&
                      !!errors["firstName" as keyof SignUpData]
                    }
                  />
                  {touched["firstName" as keyof SignUpData] &&
                    errors["firstName" as keyof SignUpData] && (
                      <Text color="red.500" fontSize="xs">
                        {t(`${errors["firstName" as keyof SignUpData]}`)}
                      </Text>
                    )}
                </Box>
                <Box flex="1" minW="200px">
                  <FormLabel color={"secondary.300"}>
                    {t("SignUpForm.lastName")}
                  </FormLabel>
                  <InputStyled
                    name="lastName"
                    value={String(values.lastName)}
                    autoComplete="family-name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched["lastName" as keyof SignUpData] &&
                      !!errors["lastName" as keyof SignUpData]
                    }
                  />
                  {touched["lastName" as keyof SignUpData] &&
                    errors["lastName" as keyof SignUpData] && (
                      <Text color="red.500" fontSize="xs">
                        {t(`${errors["lastName" as keyof SignUpData]}`)}
                      </Text>
                    )}
                </Box>
              </Flex>
              <Box w="100%">
                <FormLabel color={"secondary.300"}>{t("Email")}</FormLabel>
                <InputStyled
                  name="email"
                  value={String(values.email)}
                  onChange={handleChange}
                  autoComplete="email"
                  onBlur={handleBlur}
                  isInvalid={
                    touched["email" as keyof SignUpData] &&
                    !!errors["email" as keyof SignUpData]
                  }
                />
                {touched["email" as keyof SignUpData] &&
                  errors["email" as keyof SignUpData] && (
                    <Text color="red.500" fontSize="xs">
                      {t(`${errors["email" as keyof SignUpData]}`)}
                    </Text>
                  )}
              </Box>
              <Box w="100%">
                <FormLabel>{t("SignUpForm.password")}</FormLabel>
                <InputGroup>
                  <InputStyled
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type={showPassword ? "text" : "password"}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <InputRightElement>
                    <PasswordIcon handleClick={handleShowPassword} />
                  </InputRightElement>
                </InputGroup>
                {touched.password && errors.password && (
                  <Text color="red.500" fontSize="xs">
                    {t(errors.password)}
                  </Text>
                )}
              </Box>
              <Box w="100%">
                <FormLabel>{t("SignUpForm.confirmPassword")}</FormLabel>
                <InputGroup>
                  <InputStyled
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type={showConfirmPassword ? "text" : "password"}
                    isInvalid={
                      touched.confirmPassword && !!errors.confirmPassword
                    }
                  />
                  <InputRightElement>
                    <PasswordIcon handleClick={handleShowConfirmPassword} />
                  </InputRightElement>
                </InputGroup>
                {touched.confirmPassword && errors.confirmPassword && (
                  <IconError
                    display="flex"
                    gap="5px"
                    alignItems="center"
                    error={t(errors.confirmPassword)}
                    mt={"4px"}
                  />
                )}
              </Box>
              <Box w="100%">
                <FormLabel color={"secondary.300"}>
                  {t("SignUpForm.phoneNumber")}
                </FormLabel>
                <InputPhone
                  defaultProps={{
                    name: "phoneNumber",
                    type: "tel",
                    isRequired: true,
                  }}
                  autocompleteValue="tel"
                  useLocation
                />
                {touched["phoneNumber" as keyof SignUpData] &&
                  errors["phoneNumber" as keyof SignUpData] && (
                    <Text color="red.500" fontSize="xs">
                      {t(`${errors["phoneNumber" as keyof SignUpData]}`)}
                    </Text>
                  )}
              </Box>
              <Box w="100%">
                <FormLabel color={"secondary.300"}>
                  {t("SignUpForm.country")}
                </FormLabel>
                {
                  <SelectCountry
                    defaultProps={{
                      name: "countryOfResidence",
                    }}
                    useLocation
                  />
                }
              </Box>
            </Flex>
            <Flex flexDir={"row"} mt="40px" gap={2}>
              <Checkbox
                w={"max-content"}
                variant={"primary"}
                icon={values.acceptedTerms ? <Ico.Check /> : undefined}
                isChecked={values.acceptedTerms}
                onChange={(ev) => {
                  setFieldValue("acceptedTerms", ev.target.checked);
                }}
              ></Checkbox>
              <Text fontSize={"12px"}>
                He leído y acepto los{" "}
                <Link
                  fontSize={"12px"}
                  fontWeight={"400"}
                  variant={"primary"}
                  textColor={"primary.300"}
                  target="_blank"
                  href={AppRoute.TermsAndConditions}
                >
                  términos y condiciones
                </Link>{" "}
              </Text>
            </Flex>
            <Button
              onClick={submitForm}
              my={"24px"}
              isDisabled={!isValid || loading}
            >
              {loading ? <Spinner></Spinner> : t("Create account")}
            </Button>
            <Text
              maxW={"600px"}
              display={"block"}
              fontSize={"11px"}
              fontWeight={"400"}
              lineHeight={"19.2px"}
              mb={{
                base: "24px",
                lg: "32px",
              }}
            >
              Al hacer clic, nos autoriza a contactarlo/la mediante un sistema
              automatizado de llamadas al teléfono indicado arriba con el fin de
              recibir información relevante sobre smartBeemo™, y acepta nuestros
              Términos y Condiciones y Política de Privacidad. Su consentimiento
              no constituye una condición de compra.
            </Text>

            <Box>
              <Flex
                w={"100%"}
                flexDir={"row"}
                flexWrap={"wrap"}
                justifyContent={"center"}
                alignItems={"center"}
                marginX={"auto"}
                borderRadius={"8px"}
              >
                <Text variant={"placeholder"}>
                  {t("SignUpForm.existingAccount")}
                </Text>
                <Button onClick={onSignInClick} variant={"link"}>
                  <Text variant={"caption"} ml={"4px"}>
                    {t("Login")}
                  </Text>
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};
