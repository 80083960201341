import { useOnBoarding } from "@/pages/OnBoarding/useOnboarding";
import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import Confetti from "react-confetti";

const OnBoardingConffetiModal = () => {
  const { shouldShowModal, redirectToProfiling } = useOnBoarding();
  return (
    <Modal
      isOpen={shouldShowModal}
      onClose={redirectToProfiling}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <Confetti
        width={window.innerWidth}
        height={1000}
        colors={["#FFD700", "#628ADF", "#8CDBD9"]}
        initialVelocityX={15}
        initialVelocityY={500}
        numberOfPieces={400}
      />
      <ModalOverlay background="secondary.300" opacity={"0.8 !important"} />
      <ModalContent
        pb={0}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        marginX={2}
        paddingX={6}
        paddingY={12}
      >
        <Flex flexDirection={"column"} justifyContent={"center"}>
          <Flex flexDirection={"column"} alignItems={"center"}>
            <Flex
              flexDirection={"column"}
              alignItems={"center"}
              gap={2}
              justifyContent={"center"}
              w="100%"
            >
              <Text
                fontSize={{ base: "16px", lg: "24px" }}
                fontWeight={600}
                lineHeight={{ base: "25.6px", lg: "36px" }}
                color={"secondary.300"}
                textAlign={"center"}
              >
                ¡Ahora eres Pro! 🎉
              </Text>
              <Text
                fontSize={{ base: "14px", lg: "14px" }}
                fontWeight={400}
                lineHeight={{ base: "22.4px", lg: "25.6px" }}
                color={"secondary.300"}
                textAlign={"center"}
              >
                Acabas de desbloquear una nueva experiencia llena de beneficios.
                Ahora tienes acceso a todo un ecosistema de herramientas y
                contenido diseñado para tu crecimiento en el mundo del
                ecommerce.
                <br />
                <br />
                Aprovecha al máximo la plataforma y comienza a explorar todo lo
                que hemos preparado para ti.
              </Text>
            </Flex>

            <Flex
              flexDirection="column"
              alignItems="center"
              w="100%"
              marginTop={"32px"}
            >
              <Button
                w={{ base: "100%", lg: "280px" }}
                variant="primary"
                onClick={redirectToProfiling}
                fontSize={"14px"}
              >
                Continuar
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
export default OnBoardingConffetiModal;
