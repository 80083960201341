import { useCallback, useState } from "react";
import { NavigationContext } from "./Context";
import { AppRoute, PublicRoute } from "@/AppRoute";
import { EntityName } from "@/schemaTypes";

interface CustomNavigationProviderProps {
  children: React.ReactNode;
}
const PRODUCT_OF_INTEREST_KEY = "ProductName";
const PRODUCT_TYPE_KEY = "ProductType";
const REDIRECT_ROUTE_SIGNUP = "RedirectRouteSignup";
const AUTH_SEARCH_PARAMS = "SearchParams";

const CustomNavigationProvider = ({
  children,
}: CustomNavigationProviderProps): JSX.Element => {
  const [redirectRoute, setRedirectRoute] = useState<
    AppRoute | PublicRoute | null | string
  >();
  const [productOfInterest, setProductOfInterest] = useState<string | null>(
    null
  );
  const [productTypeOfInterest, setProductTypeOfInterest] = useState<
    EntityName | string | null
  >(null);

  const updateRedirectRoute = useCallback(
    (route: AppRoute | PublicRoute | null | string) => {
      if (route) {
        setRedirectRoute(route);
        localStorage.setItem(REDIRECT_ROUTE_SIGNUP, route);
      }
    },
    []
  );

  const clearProductOfInterest = useCallback(() => {
    localStorage.removeItem(PRODUCT_OF_INTEREST_KEY);
    localStorage.removeItem(PRODUCT_TYPE_KEY);
  }, []);

  const updateProductOfInterest = useCallback(
    (
      productParamTypeOfInterest: EntityName | string | null,
      productParamOfInterest: string | null
    ) => {
      clearProductOfInterest();
      if (productParamOfInterest) {
        setProductOfInterest(productParamOfInterest);
        localStorage.setItem(PRODUCT_OF_INTEREST_KEY, productParamOfInterest);
      }
      if (productParamTypeOfInterest) {
        setProductTypeOfInterest(productParamTypeOfInterest);
        localStorage.setItem(PRODUCT_TYPE_KEY, productParamTypeOfInterest);
      }
    },
    []
  );

  const getProductOfInterestFromStorage = (): {
    productTypeOfInterest: EntityName | null | string;
    productOfInterest: string | null;
  } => {
    const interest = localStorage.getItem(PRODUCT_OF_INTEREST_KEY);
    const typeOfInterest = localStorage.getItem(PRODUCT_TYPE_KEY) as EntityName;

    if (!interest || !typeOfInterest)
      return { productOfInterest: null, productTypeOfInterest: null };

    setProductOfInterest(interest);
    setProductTypeOfInterest(typeOfInterest);

    return {
      productOfInterest: productOfInterest,
      productTypeOfInterest: productTypeOfInterest,
    };
  };

  const configUpsellRedirect = useCallback(
    (
      productParamTypeOfInterest: EntityName | string | null,
      productParamOfInterest: string | null,
      extraRouteParam: string | null | AppRoute | PublicRoute,
      replaceUrl = false
    ) => {
      updateProductOfInterest(
        productParamTypeOfInterest,
        productParamOfInterest
      );
      let newRoute: string | AppRoute | PublicRoute | null =
        AppRoute.UpsellAndCross;
      if (extraRouteParam) {
        if (
          Object.values(AppRoute).includes(extraRouteParam as AppRoute) ||
          Object.values(PublicRoute).includes(extraRouteParam as PublicRoute) ||
          replaceUrl
        ) {
          newRoute = extraRouteParam;
        } else {
          newRoute = `${AppRoute.UpsellAndCross}/${extraRouteParam}`;
        }
      }
      updateRedirectRoute(newRoute);
    },
    []
  );

  const setShowPriceChart = useCallback(
    (show: boolean) =>
      localStorage.setItem("DisplayPriceChart", show.toString()),
    []
  );

  const getShowPriceChart = useCallback(
    (): boolean => localStorage.getItem("DisplayPriceChart") === "true",
    []
  );

  const setSearchParams = useCallback((params: string) => {
    const cleanParams = params.startsWith("?") ? params.slice(1) : params;
    return localStorage.setItem(AUTH_SEARCH_PARAMS, cleanParams);
  }, []);

  const getSearchParams = useCallback((): Record<string, string> | null => {
    const params = localStorage.getItem(AUTH_SEARCH_PARAMS);
    if (params) {
      return Object.fromEntries(
        params.split("&").map((param) => {
          const [key, value] = param.split("=");
          return [key, value];
        })
      );
    }
    return null;
  }, []);

  const clearSearchParams = useCallback(() => {
    localStorage.removeItem(AUTH_SEARCH_PARAMS);
  }, []);

  return (
    <NavigationContext.Provider
      value={{
        redirectRoute,
        productOfInterest,
        productTypeOfInterest,
        updateRedirectRoute,
        updateProductOfInterest,
        clearProductOfInterest,
        getProductOfInterestFromStorage,
        configUpsellRedirect,
        setShowPriceChart,
        getShowPriceChart,
        setSearchParams,
        getSearchParams,
        clearSearchParams,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export { CustomNavigationProvider };
