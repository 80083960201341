import React, { useState } from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { brandCardProps } from "./types";
import { Ico } from "@/assets/icons";

const FilterItem: React.FC<brandCardProps> = ({ brand, handleClick }) => {
  const [isActive, setIsActive] = useState(false);

  const handleMouseDown = () => setIsActive(true);
  const handleMouseUp = () => setIsActive(false);

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"space-between"}
      transition={"all 0.3s"}
      userSelect={"none"}
      border={"1px solid"}
      borderColor={"neutral.100"}
      w={{ base: "100%", md: "calc(50% - 9px)", xl: "25%" }}
      px={"16px"}
      maxH={"71px"}
      borderRadius={"8px"}
      cursor={"pointer"}
      py={"12px"}
      bg={isActive ? "primary.400" : "white"}
      color={isActive ? "white" : "auto"}
      _hover={{
        borderColor: "primary.400",
      }}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onMouseLeave={() => setIsActive(false)}
    >
      <Flex gap={3} alignItems={"center"} w={"100%"}>
        {isActive ? brand.cleanIcon : brand.icon}
        <Flex justifyContent={"space-between"} w={"100%"} alignItems={"center"}>
          <Box>
            <Heading
              as="h6"
              fontSize={"16px"}
              fontWeight={"600"}
              lineHeight={"22px"}
            >
              Academia <br />
              {brand.name}
            </Heading>
          </Box>
          <Ico.ChevronRight fontSize={"24px"} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FilterItem;
