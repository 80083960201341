import { useEffect, useState } from "react";
import * as Yup from "yup";
import { AppRoute, PublicRoute } from "@/AppRoute";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  signInWithCredentials,
  signUpWithCredentials,
} from "@/services/authService";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { useToast } from "@chakra-ui/react";
import { useLazyQuery } from "@apollo/client";
import { GetUserDocument } from "@/graphql/getUser.generated";
import { noCacheHeaders } from "@/utils/headers";
import { useUser } from "@/providers/useUser";
import { useReferral } from "@/hooks/useReferral";
import { useCustomNavigation } from "@/providers/Navigation/useCustomNavigation";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export interface SignUpData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  countryOfResidence: string;
  referralId?: string;
  acceptedTerms?: boolean;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  tipoCampana?: string;
  fbc?: string;
  fbp?: string;
}

export interface SignUpCardProps {
  onSignUp?: (data: SignUpData) => void;
  hasContainer?: boolean;
}

export const signUpCardValidationSchema = Yup.object({
  firstName: Yup.string().required("SignUpForm.formErrors.requiredField"),
  lastName: Yup.string().required("SignUpForm.formErrors.requiredField"),
  email: Yup.string()
    .required("SignUpForm.formErrors.requiredEmail")
    .email("SignUpForm.formErrors.invalidEmail"),
  password: Yup.string()
    .required("SignUpForm.formErrors.requiredPassword")
    .min(6, "SignUpForm.formErrors.passwordMinLength"),
  confirmPassword: Yup.string()
    .required("Confirmar contraseña es requerido")
    .oneOf([Yup.ref("password")], "SignUpForm.formErrors.matchPassword"),
  phoneNumber: Yup.string()
    .required("SignUpForm.formErrors.requiredField")
    .matches(/^\+?[0-9]+$/, "SignUpForm.formErrors.invalidPhoneNumber")
    .min(8, "SignUpForm.formErrors.phoneNumberLength"),
  acceptedTerms: Yup.boolean()
    .oneOf([true], "Debes aceptar los términos y condiciones")
    .required("Debes aceptar los términos y condiciones"),
});

export const useSignUpCard = (onSignUp?: (data: SignUpData) => void) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const { setUser, user } = useUser();
  const { referralId, clearReferralId } = useReferral();
  const {
    productOfInterest,
    productTypeOfInterest,
    redirectRoute,
    setShowPriceChart,
    clearSearchParams,
  } = useCustomNavigation();
  const [executeGetUser, { data: userData }] = useLazyQuery(GetUserDocument, {
    fetchPolicy: "network-only",
    context: { headers: noCacheHeaders },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // reCAPTCHA v3
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (user) {
      navigate(AppRoute.Base);
    }
  }, [user]);

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const onSignInClick = () => {
    navigate(PublicRoute.Login);
  };

  const handleSubmit = async (data: SignUpData) => {
    setLoading(true);
    const {
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
      countryOfResidence,
      utmSource,
      utmMedium,
      utmCampaign,
      tipoCampana,
      fbc,
      fbp,
    } = data;
    try {
      const recaptchaToken = await executeRecaptcha?.("signup");
      if (!recaptchaToken) throw new Error("Error reCAPTCHA");

      await signUpWithCredentials({
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        countryOfResidence,
        clientToken: recaptchaToken,
        referralId: referralId || undefined,
        productOfInterest,
        productTypeOfInterest,
        utmSource,
        utmMedium,
        utmCampaign,
        tipoCampana,
        fbc,
        fbp,
      });
      await signInWithCredentials(email, password);
      executeGetUser();
      clearReferralId();
      setShowPriceChart(true);
      clearSearchParams();
      if (onSignUp) onSignUp(data);
      toast({
        title: "Usuario creado exitosamente.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      navigate(redirectRoute ?? AppRoute.Base, {});
    } catch (err: unknown) {
      const errorMessage = err instanceof Error ? err.message : "unknown error";

      SBErrorPubSub.publish({
        component: "SignUpCard.tsx",
        message: t(errorMessage),
        showInProd: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData) {
      if (userData?.me) {
        setUser(userData.me);
      }
    }
  }, [userData]);

  return {
    t,
    handleSubmit,
    handleShowPassword,
    handleShowConfirmPassword,
    onSignInClick,
    showConfirmPassword,
    showPassword,
    loading,
  };
};
