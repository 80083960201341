/* eslint-disable no-console */
import { useCallback, useEffect, useState, useRef } from "react";
import { ConnectionState, VideoClient } from "@zoom/videosdk";
import { ConnectionStatus, ReconnectionState } from "./connection";

interface UseZoomReconnectionProps {
  zmClient: typeof VideoClient | undefined;
  sessionTopic: string;
  sessionToken: string;
  username: string;
  onReconnected?: () => void;
}

export const useZoomReconnection = ({
  zmClient,
  sessionTopic,
  sessionToken,
  username,
  onReconnected,
}: UseZoomReconnectionProps) => {
  const [state, setState] = useState<ReconnectionState>({
    status:
      !zmClient || !zmClient.getSessionInfo()?.isInMeeting
        ? ConnectionStatus.RECONNECTING
        : ConnectionStatus.CONNECTED,
    lastError: null,
    attemptCount: 0,
    isReconnectionInProgress: false,
  });

  const reconnectionAttemptRef = useRef<NodeJS.Timeout>();
  const lastErrorRef = useRef<string | null>(null);
  const isReconnectingRef = useRef(false);

  const logConnectionEvent = useCallback((event: string, details?: unknown) => {
    const timestamp = new Date().toISOString();
    try {
      console.log(
        `[${timestamp}] [Zoom Connection] ${event}`,
        details ? details : ""
      );
    } catch (error) {
      console.error("Error al registrar evento:", error);
    }
  }, []);

  const logError = useCallback(
    (error: unknown, context: string) => {
      const timestamp = new Date().toISOString();
      const errorMessage =
        error instanceof Error ? error.message : String(error);
      lastErrorRef.current = errorMessage;
      console.error(`[${timestamp}] [Zoom Error] ${context}:`, {
        error,
        stack: error instanceof Error ? error.stack : undefined,
        context,
        state: {
          sessionTopic,
          username,
          connectionStatus: state.status,
          attemptCount: state.attemptCount,
        },
      });
    },
    [sessionTopic, username, state.status, state.attemptCount]
  );

  const clearReconnectionAttempt = useCallback(() => {
    if (reconnectionAttemptRef.current) {
      clearTimeout(reconnectionAttemptRef.current);
      reconnectionAttemptRef.current = undefined;
      logConnectionEvent("Intento de reconexión cancelado");
    }
  }, [logConnectionEvent]);

  const handleReconnect = useCallback(async (): Promise<void> => {
    if (!zmClient || !sessionTopic || !sessionToken) {
      const error = new Error("Falta información necesaria para la conexión");
      logError(error, "Reconexión fallida");
      setState((prev) => ({
        ...prev,
        status: ConnectionStatus.ZOOM_ERROR,
        lastError: "Falta información de conexión",
        isReconnectionInProgress: false,
      }));
      return;
    }

    if (isReconnectingRef.current || state.isReconnectionInProgress) {
      logConnectionEvent("Ya existe un intento de reconexión en curso");
      return;
    }

    isReconnectingRef.current = true;
    logConnectionEvent("Iniciando proceso de reconexión", {
      attempt: state.attemptCount + 1,
      sessionTopic,
      username,
    });

    setState((prev) => ({
      ...prev,
      status: ConnectionStatus.RECONNECTING,
      lastError: null,
      attemptCount: prev.attemptCount + 1,
      isReconnectionInProgress: true,
    }));

    try {
      if (!navigator.onLine) {
        throw new Error("Sin conexión a internet");
      }

      // Asegurarse de limpiar cualquier sesión anterior
      if (zmClient.getSessionInfo()?.isInMeeting) {
        logConnectionEvent("Limpiando sesión previa");
        try {
          await zmClient.leave();
          // Esperar a que la sesión se limpie completamente
          await new Promise((resolve) => setTimeout(resolve, 2000));
        } catch (error) {
          logError(error, "Error al limpiar sesión previa");
          // Continuar incluso si hay error al limpiar
        }
      }

      logConnectionEvent("Estamos intentando conectarte a la sesión");
      await zmClient.join(sessionTopic, sessionToken, username);

      logConnectionEvent("Reconexión exitosa");
      setState({
        status: ConnectionStatus.CONNECTED,
        lastError: null,
        attemptCount: 0,
        isReconnectionInProgress: false,
      });

      onReconnected?.();
      clearReconnectionAttempt();
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "Error desconocido";
      logError(error, "Error durante la reconexión");

      setState((prev) => ({
        status: ConnectionStatus.ZOOM_ERROR,
        lastError: errorMessage,
        attemptCount: prev.attemptCount,
        isReconnectionInProgress: false,
      }));

      if (navigator.onLine && state.attemptCount < 3) {
        logConnectionEvent("Programando nuevo intento de reconexión");
        clearReconnectionAttempt();
        reconnectionAttemptRef.current = setTimeout(handleReconnect, 5000);
      } else {
        logConnectionEvent("No se programarán más intentos de reconexión", {
          isOnline: navigator.onLine,
          attemptCount: state.attemptCount,
        });
      }
    } finally {
      isReconnectingRef.current = false;
    }
  }, [
    zmClient,
    sessionTopic,
    sessionToken,
    username,
    state.attemptCount,
    state.isReconnectionInProgress,
    onReconnected,
    logConnectionEvent,
    logError,
    clearReconnectionAttempt,
  ]);

  useEffect(() => {
    if (!zmClient || !sessionTopic || !sessionToken) {
      setState((prev) => ({
        ...prev,
        status: ConnectionStatus.ZOOM_ERROR,
        lastError: "Falta información de conexión",
        isReconnectionInProgress: false,
      }));
      return;
    }

    if (!zmClient.getSessionInfo()?.isInMeeting && !isReconnectingRef.current) {
      handleReconnect().catch((error) => {
        logError(error, "Error en conexión inicial");
      });
    }
  }, [zmClient, sessionTopic, sessionToken, handleReconnect, logError]);

  useEffect(() => {
    const handleOnline = () => {
      logConnectionEvent("Conexión a internet restaurada - Evento Online");
      if (!isReconnectingRef.current) {
        setTimeout(() => {
          handleReconnect().catch((error) => {
            logError(error, "Error en reconexión después de online");
          });
        }, 1000);
      }
    };

    const handleOffline = () => {
      logConnectionEvent("Conexión a internet perdida - Evento Offline");
      clearReconnectionAttempt();
      setState((prev) => ({
        ...prev,
        status: ConnectionStatus.DISCONNECTED,
        lastError: "Sin conexión a Internet",
        isReconnectionInProgress: false,
      }));
    };

    const checkConnection = () => {
      if (
        navigator.onLine &&
        state.status !== ConnectionStatus.CONNECTED &&
        !isReconnectingRef.current
      ) {
        logConnectionEvent("Conexión detectada - Check periódico", {
          currentStatus: state.status,
        });
        handleReconnect().catch((error) => {
          logError(error, "Error en reconexión después de check periódico");
        });
      }
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    const connectionCheckInterval = setInterval(checkConnection, 5000);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      clearInterval(connectionCheckInterval);
      clearReconnectionAttempt();
    };
  }, [
    handleReconnect,
    logConnectionEvent,
    logError,
    clearReconnectionAttempt,
    state.status,
  ]);

  useEffect(() => {
    if (!zmClient) return;

    const handleZoomConnectionChange = (payload: {
      state: ConnectionState;
      reason?: string;
      errorCode?: number;
    }) => {
      logConnectionEvent("Cambio de estado en la sesión", payload);

      switch (payload.state) {
        case "Fail":
          if (navigator.onLine && !isReconnectingRef.current) {
            logConnectionEvent(
              "Fallo detectado en la sesión, iniciando reconexión"
            );
            handleReconnect().catch((error) => {
              logError(
                error,
                "Error en reconexión después de fallo de la sesión"
              );
            });
          }
          break;
        case "Connected":
          logConnectionEvent("Conexión con la sesión establecida");
          setState({
            status: ConnectionStatus.CONNECTED,
            lastError: null,
            attemptCount: 0,
            isReconnectionInProgress: false,
          });
          break;
        case "Reconnecting":
          logConnectionEvent("La sesión intentando reconectar internamente");
          if (!isReconnectingRef.current) {
            setState((prev) => ({
              ...prev,
              status: ConnectionStatus.RECONNECTING,
              isReconnectionInProgress: true,
            }));
          }
          break;
      }
    };

    zmClient.on("connection-change", handleZoomConnectionChange);

    return () => {
      zmClient.off("connection-change", handleZoomConnectionChange);
    };
  }, [zmClient, handleReconnect, logConnectionEvent, logError]);

  return state;
};
