import { useCallback, useContext, useEffect, useState } from "react";
import { IconControlsMicrophone, TypeIcon } from "../IconsControls";
import ZoomMediaContext from "@/components/LiveSessionsZoom/context/media-context";
import ZoomClientContext from "@/components/LiveSessionsZoom/context/zoom-context";
import { AudioDeviceSelector } from "../AudioDeviceSelector";
import { MicrophoneProps } from "../types";
import { useMentor } from "@/pages/academy/LiveSessionZoom/providers/useMentor";

export const Microphone: React.FC<MicrophoneProps> = ({
  microphones,
  speakers,
  selectedMicId,
  selectedSpeakerId,
  onMicSelect,
  onSpeakerSelect,
}) => {
  const { isMentorOrAdmin } = useMentor();

  const [isStartedAudio, setIsStartedAudio] = useState(false);
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const { zmClient } = useContext(ZoomClientContext);
  const zoomMediaContext = useContext(ZoomMediaContext);

  const getMediaStream = () => zoomMediaContext?.mediaContext?.mediaStream;

  const getSelectedDevices = () => {
    try {
      const selectedDevices = localStorage.getItem("selectedDevices");
      return selectedDevices ? JSON.parse(selectedDevices) : null;
    } catch {
      return null;
    }
  };

  // Función para verificar si la conexión está completamente establecida
  const isFullyConnected = useCallback(() => {
    if (!zmClient) return false;
    try {
      return (
        zmClient.getSessionInfo()?.isInMeeting &&
        zoomMediaContext?.statusZoom === "connected" &&
        getMediaStream()
      );
    } catch {
      return false;
    }
  }, [zmClient, zoomMediaContext]);

  useEffect(() => {
    const handleUserUpdate = () => {
      if (!isFullyConnected()) return;
      const user = zmClient
        ?.getAllUser()
        ?.find((u) => u.userId === zmClient?.getCurrentUserInfo()?.userId);
      // TODO - COLOCAR LOGS PARA VER EL STATUS MUTED DE UN USUARIO.
      // PROBAR PARA HOSTS/COHOSTS Y USUARIOS ESTUDIANTES
      // eslint-disable-next-line no-console
      console.log("User muted status ", user?.muted);
      if (user) setIsStartedAudio(!user.muted);
    };

    zmClient?.on("user-updated", handleUserUpdate);
    return () => zmClient?.off("user-updated", handleUserUpdate);
  }, [zmClient, isFullyConnected]);

  const initializeAudio = useCallback(async () => {
    // Verificar que estamos realmente conectados antes de inicializar
    if (!isFullyConnected() || isAudioInitialized) return;

    try {
      const mediaStream = getMediaStream();
      if (!mediaStream) return;
      // Intentar inicializar el audio
      await mediaStream.startAudio({
        speakerId: getSelectedDevices().speaker,
        mute: true, // Empezar siempre muteado
      });
      await mediaStream.muteAudio();

      setIsAudioInitialized(true);
      setIsStartedAudio(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("[Audio] Error initializing audio:", error);
    }
  }, [isFullyConnected, isAudioInitialized]);

  const onMicrophoneHandle = useCallback(async () => {
    if (!isMentorOrAdmin || !isFullyConnected()) return;

    try {
      const mediaStream = getMediaStream();
      if (!mediaStream) return;

      if (!isAudioInitialized) {
        await initializeAudio();
        return;
      }

      if (isStartedAudio) {
        await mediaStream.muteAudio();
      } else {
        await mediaStream.unmuteAudio();
      }
      setIsStartedAudio(!isStartedAudio);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("[Audio] Error toggling microphone:", error);
    }
  }, [isStartedAudio, isAudioInitialized, isMentorOrAdmin, isFullyConnected]);

  useEffect(() => {
    if (!isAudioInitialized && isFullyConnected()) {
      initializeAudio();
    }

    return () => {
      try {
        const user = zmClient
          ?.getAllUser()
          ?.find((u) => u.userId === zmClient?.getCurrentUserInfo()?.userId);
        // eslint-disable-next-line no-console
        console.log("This user is a host, cohost or admin ", isMentorOrAdmin);
        if (user && !isMentorOrAdmin) {
          getMediaStream()?.muteAudio();
          setIsStartedAudio(false);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn("[Audio] Error in cleanup:", error);
      }
    };
  }, [isFullyConnected, isAudioInitialized, initializeAudio]);

  return (
    <AudioDeviceSelector
      microphones={microphones}
      speakers={speakers}
      selectedMicId={selectedMicId}
      selectedSpeakerId={selectedSpeakerId}
      onMicSelect={onMicSelect}
      onSpeakerSelect={onSpeakerSelect}
      isDisabled={!isMentorOrAdmin}
      isActive={isStartedAudio}
      onToggle={onMicrophoneHandle}
    >
      <IconControlsMicrophone
        type={isStartedAudio ? TypeIcon.MICROFONE_ON : TypeIcon.MICROFONE_OFF}
      />
    </AudioDeviceSelector>
  );
};
